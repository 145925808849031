
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, Roles } from '@core';

/** Redirect to the appropriate home page base on user role */
export const homeGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router: Router = inject(Router);
    
  const user = await authService.getLoggedInUser();
  if (user) {
    
    // const roles = user?.roles || [];
    if (user.hasRole(Roles.AccountAdmin)) {
      return router.createUrlTree(['/account/accounts', user?.accountId, 'manage']);
    }
    else if (user.hasRole(Roles.AccountUser)) {
      return router.createUrlTree(['/transaction/accounts', user?.accountId]);
    }
    else if (user.hasRole(Roles.SupplierAdmin)) {
      return router.createUrlTree(['/supplier/suppliers', user?.supplierId, 'manage']);
    }
    else if (user.hasRole(Roles.SuperUser)) {
      return router.createUrlTree(['/account/accounts']);
    }
    // shouldn't get here - every logged-in user should have one of the above roles
    return false;
  }    
  return true;
}
 