import { ColDef, IRichCellEditorParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';



@Component({
  selector: 'prox-test',
  templateUrl: './test.component.html',
})
export class TestComponent {
  public columnDefs: ColDef[] = [
    {
      headerName: "Rich Select Editor",
      field: "language",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: languages,
      } as IRichCellEditorParams,
    },
  ];
  public defaultColDef: ColDef = {
    width: 200,
    editable: true,
  };
  public rowData: any[] | null = new Array(100)
    .fill(null)
    .map(() => ({ language: languages[getRandomNumber(0, 4)] }));
  public themeClass: string =
    "ag-theme-alpine";
}

const languages = ["English", "Spanish", "French", "Portuguese", "(other)"];
function getRandomNumber(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
