// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { Feature } from './feature';
import { Manufacturer } from './manufacturer';
import { ProductType } from './product-type';
import { ProductTypeTag } from './product-type-tag';
import { ProgramSupplier } from './program-supplier';
import { ReturnPolicy } from './return-policy';
import { ReturnReason } from './return-reason';
import { SupplierAccount } from './supplier-account';
import { SupplierAdmin } from './supplier-admin';
import { SupplierCategoryMap } from './supplier-category-map';
import { SupplierManifestMap } from './supplier-manifest-map';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Supplier extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [null];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(200) */
  apiLink?: string;
  /** String(60) */
  connectedAppName?: string;
  /** Boolean */
  isApiEnabled!: boolean;
  /** String(60) */
  name!: string;
  /** Guid FK primarySupplierAdmin */
  primarySupplierAdminId?: string;
  /** Binary */
  rowVersion!: any;
  /** Decimal */
  taxRate!: number;
  /** String(2) */
  taxState!: string;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK supplierId */
  addons!: Addon[];
  /** Inv FK supplierId */
  features!: Feature[];
  /** Inv FK supplierId */
  manufacturers!: Manufacturer[];
  /** FK primarySupplierAdminId */
  primarySupplierAdmin!: SupplierAdmin;
  /** Inv FK supplierId */
  productTypeTags!: ProductTypeTag[];
  /** Inv FK supplierId */
  productTypes!: ProductType[];
  /** Inv FK supplierId */
  programSuppliers!: ProgramSupplier[];
  /** Inv FK supplierId */
  returnPolicies!: ReturnPolicy[];
  /** Inv FK supplierId */
  returnReasons!: ReturnReason[];
  /** Inv FK supplierId */
  supplierAccounts!: SupplierAccount[];
  /** Inv FK supplierId */
  supplierAdmins!: SupplierAdmin[];
  /** Inv FK supplierId */
  supplierCategoryMaps!: SupplierCategoryMap[];
  /** Inv FK supplierId */
  supplierManifestMaps!: SupplierManifestMap[];
}

