import { ColDef, GridReadyEvent, IRowNode, IServerSideDatasource, RowSelectedEvent } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';
import { EntityFns } from '@data';
import { PricedProductType, ProgramProductTypeTag, ProgramProductTypeTagMap } from '@models';
import { UtilFns } from '@utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountDbQueryService } from '../services/account-db-query.service';

export interface ProgramProductTypeTagPickerParams extends EntityFinderParams {
  tags: ProgramProductTypeTag[];
  parents: PricedProductType[];
}

@Component({
  selector: 'prox-program-product-type-picker.dialog',
  template: EntityFinderDialog.HTML,
})
export class ProgramProductTypePickerDialogComponent extends EntityFinderDialog<ProgramProductTypeTag, ProgramProductTypeTagPickerParams> {
  params: ProgramProductTypeTagPickerParams;

  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<ProgramProductTypeTagPickerParams>,
    public dbQueryService: AccountDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.tags, 'tags');
  }

  override async initialize() {
    return {
      header: `Select Program Product Tags`,
      width: 'calc(40vw)',
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
    } as const;
  }

  override async onGridReady(event: GridReadyEvent): Promise<void> {
    super.onGridReady(event);
    const parents = this.params.parents;
    if (parents.length === 1) {
      const tagIds = parents[0].programProductTypeTagMaps.map(x => x.programProductTypeTagId);
      this.gridOptions.onFirstDataRendered = (params) => {
        const nodesToSelect: IRowNode[] = [];
        params.api.forEachNode((node) => {
            if (node.data && tagIds.includes(node.data.id)) {
                nodesToSelect.push(node);
            }
        });
        params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
      };
  
    }
  }

  override async initDatasource(): Promise<IServerSideDatasource | ProgramProductTypeTag[]> {
    return this.params.tags;
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      {  headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' },
    ] as ColDef[]
    const sortModel = [
      { colId: 'name', sort: 'asc' },
      
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }

  override onRowSelected(event: RowSelectedEvent) {
    // only assign selectedEntity if source was a real event, not our api call
    if (event.event) {
      this.selectedEntity = event.data;
    }
  }

  override onOk(): void {
    for (const parent of this.params.parents) {
      this.updateEntities(this.datasource as any, parent);
    }
    super.onOk();
  }

  /** Create/Delete entities based on selected nodes in the grid.
   * @param tags - list of all tags in the datasource
   * @param parent - parent entity to which the tag maps are attached */
  private updateEntities(tags: ProgramProductTypeTag[], parent: PricedProductType) {
    const selectedNodes = this.gridOptions.api?.getSelectedNodes();
    const oldMaps = parent.programProductTypeTagMaps;

    for (const tag of tags) {
      const entity = oldMaps.find(x => x.programProductTypeTagId == tag.id);
      const isSelected = !!selectedNodes?.find(x => x.data.id == tag.id);

      if (entity) {
        if (isSelected) {
          if (EntityFns.isDeletedOrDetached(entity.entityAspect)) {
            EntityFns.unDelete(entity, this.dbQueryService.uow.manager);
          }
        } else {
          EntityFns.deleteOrDetach(entity.entityAspect);
        }
      } else {
        if (isSelected) {
          this.dbQueryService.uow.createEntity(ProgramProductTypeTagMap, {
            pricedProductTypeId: parent.id,
            programProductTypeTagId: tag.id
          });
        }
      }
    }
  }

}
