/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';

import { AccountUser } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { AccountDbQueryService } from '../services/account-db-query.service';

export interface AccountUserFinderParams extends EntityFinderParams {
  accountUsers: AccountUser[];
}

@Component({
  selector: 'prox-account-user-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AccountUserFinderDialog extends EntityFinderDialog<AccountUser, AccountUserFinderParams> {

  params: AccountUserFinderParams;
  accountUsers: AccountUser[] = [];
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<AccountUserFinderParams>,
    public dbQueryService: AccountDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.accountUsers, 'accountUsers');
  }

  override async initialize() {
    
    return {
      header: `Select an Account User`,
      width: 'calc(40vw)',
      rowModelType: 'clientSide'
    } as const;
  }

  override async initDatasource() {
    return this.params.accountUsers;
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      { headerName: 'Last Name', field: 'proximityUser.lastName', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' },
      { headerName: 'First Name', field: 'proximityUser.firstName', filter: 'agTextColumnFilter',  },
      { headerName: 'Middle Name', field: 'proximityUser.middleName', filter: 'agTextColumnFilter',  },
      { headerName: 'E-mail', field: 'proximityUser.email', filter: 'agTextColumnFilter',  }
    ] as ColDef[]
    const sortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }


}