import { ProximityRightEnum } from "@models";
import { MenuItem } from "primeng/api";
import { AuthUser, Roles } from "../services/auth.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Route, UrlSegment, UrlSegmentGroup, UrlTree } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash-es";

/** map of route path to rights that may access the path, e.g. '/suppliers/:supplierId/users' -> [ProximityRightEnum.UserUpdate] */
export declare type RightsMap = { [path: string]: ProximityRightEnum[] };

export class RouteFns {

  /** Determine if user has access to the route.  Show a toast if not. */
  static checkRoutePermissions(route: ActivatedRouteSnapshot, roles: Roles[], user: AuthUser, toastr: ToastrService): boolean {
    if (user?.hasRole(Roles.SuperUser)) {
      return true;
    }
    if (!user?.hasRole(...roles)) {
      toastr.error(`${roles} role is required for this route`, 'Permission');
      return false;
    }

    // If route has rights defined, check that user has the rights
    const routeRights = route.data?.['rights'] as ProximityRightEnum[];
    if (!routeRights) {
      return true;
    }

    if (user.hasRight(...routeRights)) {
      return true;
    }
    const perms = routeRights.map(r => _.startCase(ProximityRightEnum[r])).join(' or ');
    toastr.error(`${perms} permission is required for this route`, 'Permission');
    return false;

  }

  /** Disable menu items based on rightsMap, and replace parameters with values.  Recursive.
   * @param items MenuItem tree, with placeholders for parameters
   * @param paramMap name-value pairs for parameter replacement, e.g. ':supplierId' -> 'a1b2c3'
   * @param rightsMap map of route paths to rights
   * @param user current user, for evaluating rights
  */
  static processMenuItems(items: MenuItem[], paramMap: { [param: string]: string }, rightsMap: RightsMap, user?: AuthUser): void {
    for (const item of items) {
      const rl = item.routerLink as string[];
      if (rl) {

        // disable the link if the route data rights are not satisfied
        const path = rl.join('/');
        const routeRights = rightsMap[path];
        if (routeRights && !user?.hasRight(...routeRights)) {
          // item.disabled = true;
          item.styleClass = 'prox-fade';
          item.routerLink = null;
          item.title = "You do not have access to this."
        }

        for (let i = 0; i < rl.length; i++) {
          // replace parameter with value, e.g. ':supplierId' -> 'a1b2c3'
          const val = paramMap[rl[i]];
          if (val) {
            rl[i] = val;
          }
        }
      }
      if (item.items) {
        this.processMenuItems(item.items, paramMap, rightsMap, user);
      }
    }
  }

  /** Populate the rightsMap from the route tree data.  
   * Recursively converts routes into flat paths, then adds them to rightsMap. 
   * @param parent path under which the routes begin, e.g. '/supplier' 
   * @param routes route tree 
   * @param rightsMap map to be populated with flat path -> array of rights */
  static populateRightsMap(parent: string, routes: Route[], rightsMap: RightsMap): void {
    for (const rt of routes) {
      const path = parent + '/' + rt.path;
      const rights = rt.data?.['rights'];
      if (rights) {
        rightsMap[path] = rights;
      }
      if (rt.children) {
        this.populateRightsMap(path, rt.children, rightsMap);
      }
    }
  }

  /** Get the combined path of the route configs.  
   * This activated route URL, but with the param names instead of values.
   * Example: '//supplier/suppliers/:supplierId/manage/product-types/:productTypeId' */
  static getRouteConfigPath(route: ActivatedRoute | ActivatedRouteSnapshot) {
    return route.pathFromRoot.map(x => x.routeConfig?.path).join('/');
  }

}