// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { FeatureChoice } from './feature-choice';
import { FeatureDataType } from './feature-data-type';
import { ProductTypeFeature } from './product-type-feature';
import { Supplier } from './supplier';
import { ActiveStatusEnum } from './enums';
import { FeatureDataTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { Validator } from 'breeze-client';
import { StringFns } from '../../utils/string-fns';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Feature extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  _tmpIsChecked: boolean = false;
  getStatusParents() {
    return [this.supplier];
  }

  getStatusId() {
    return this.activeStatusId;
  }

  getDisplayName() {
    if (StringFns.isNullEmptyOrWhiteSpace(<string>this.docName)) {
      return this.name
    } else {
      return this.docName
    }
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String */
  apiLink?: string;
  /** String(250) */
  description!: string;
  /** String(60) */
  docName?: string;
  /** Enum FK featureDataType */
  featureDataTypeId!: FeatureDataTypeEnum;
  /** Boolean */
  isRequired!: boolean;
  /** String(60) */
  name!: string;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK featureId */
  featureChoices!: FeatureChoice[];
  /** FK featureDataTypeId */
  featureDataType!: FeatureDataType;
  /** Inv FK featureId */
  productTypeFeatures!: ProductTypeFeature[];
  /** FK supplierId */
  supplier!: Supplier;
}

