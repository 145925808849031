<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="h-full flex flex-column ">
    <div class="flex-grow-1">
      <prox-budget-items [accountId]="accountId" [budgets]="accountBudgets" [joBudgetLogs]="joBudgetLogs" addLabel="Add Account Budget items" vb  
        [createEntityFn]="createBudget.bind(this)" [isReadOnly]="isReadOnly()"></prox-budget-items>
      <span  style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </div>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>