
import { GetRowIdParams, GridOptions } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProxAgFns, UtilDialogService } from '@core';
import { Program, ProgramView, ProgramViewProductTypeConfig } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { ProductTypeConfigFinderDialog } from './product-type-config.finder.dialog';
import { Entity } from 'breeze-client';


@Component({
  selector: 'prox-program-views-sub',
  templateUrl: './program-views.sub.component.html',
})
export class ProgramViewsSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;
      
  pvGridOptions: GridOptions = {};
  programViews: ProgramView[] = [];
  
  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService) {
    //
  }

  get program() {
    return this.parent.program;
  }

  async ngOnInit() {

    this.pvGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPvGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        getRowId: (params) => (params.data as ProgramView).id
      },
      { detailProperty: 'programViewProductTypeConfigs' }
    );

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programViews");
    if (isLoaded && !forceRefresh) {
      this.programViews = this.program.programViews;
    } else {
      this.programViews = await this.dbQueryService.getProgramViews(this.program.id);
      this.program.entityAspect.markNavigationPropertyAsLoaded('programViews');
      AgFns.refreshGrid(this.pvGridOptions, this.programViews);
    }
    
    AgFns.sizeColumnsToFit(this.pvGridOptions);
    AgFns.selectDefaultRow(this.pvGridOptions);
  }

  async onPvGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        editable: !this.parent.isReadOnly(),
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Description',
        field: 'description',
        editable: !this.parent.isReadOnly(),
        filter: 'agTextColumnFilter',
      },
      this.parent.ifEditable(
        { ...AgFns.createButtonProps('', this.onProgamViewAttach.bind(this), { label: 'Attach Products', }), minWidth: 150,  maxWidth: 150,} 
      ),
      this.parent.ifEditable(ProxAgFns.getEntityDeleteColDef(this.onPvDelete.bind(this))),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    this.updatePvMasterDetail(this.pvGridOptions);
    // await this.ensureProgramProductTypeConfigs();
    AgFns.initGrid(this.pvGridOptions, colDefs, sortModel);
  }

  updatePvMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.getRowClass = (params) => {
      const x = params.data as ProgramViewProductTypeConfig;
      return x.productTypeConfigId;
    }
    const prodTypePath = 'productTypeConfig.pricedProductType.productType';
    detailGridOptions.columnDefs = [
      {
        headerName: 'Supplier',
        // field: prodTypePath + '.supplier.name',
        valueGetter: (params) => {
          const x = params.data as ProgramViewProductTypeConfig;
          return x.productTypeConfig?.pricedProductType?.productType.supplier.name
        },
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Manufacturer',
        field: prodTypePath + '.manufacturer.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SKU',
        field: prodTypePath + '.productKey',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Name',
        field: prodTypePath + '.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Description',
        field: prodTypePath + '.description',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Product Group',
        field: 'productTypeConfig.name',
        filter: 'agTextColumnFilter',
      },
      this.parent.ifEditable({
        ...AgFns.createButtonProps('', this.onProgramViewDetach.bind(this), {
          label: 'Detach Product',
        }),
        minWidth: 150,
        maxWidth: 150,
      }),
    ];
    detailGridOptions.getRowId = (rowIdParams: GetRowIdParams) => {
      const x = rowIdParams.data as ProgramViewProductTypeConfig;
      return x.productTypeConfigId;
    };
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',

      getDetailRowData: (params) => {
        const pv = params.data as ProgramView;
        params.successCallback(pv.programViewProductTypeConfigs);
      },
    };
  }

  

  async onPvAdd() {
    if (this.programViews == null) return;

    const newView = this.dbSaveService.createEntity(ProgramView, {
      programId: this.program.id,
    });
    const x = newView.programViewProductTypeConfigs;
    this.programViews.push(newView);
    AgFns.refreshGrid(this.pvGridOptions, this.programViews);
  }

  async onPvDelete(pv: ProgramView) {
    if (!this.programViews) return;
    pv.programViewProductTypeConfigs
      .slice()
      .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(pv.entityAspect);
    _.remove(this.programViews, pv);
    AgFns.refreshGrid(this.pvGridOptions, this.programViews);
  }

  async onProgamViewAttach(row: ProgramView) {
    const existingPtcIds = new Set(
      row.programViewProductTypeConfigs.map((x) => x.productTypeConfigId)
    );
    const finderPtcs = this.program.programProductTypeConfigs
      ?.map((x) => x.productTypeConfig)
      .filter((x) => !existingPtcIds.has(x.id));
    const newPtcs = await this.dialogService.createFinder(
      ProductTypeConfigFinderDialog,
      {
        productTypeConfigs: finderPtcs,
        rowSelection: 'multiple',
      }
    );
    newPtcs.forEach((ptc) => {
      if (
        !this.dbSaveService.uow.undoIfDeleted(ProgramViewProductTypeConfig, [
          row.id,
          ptc.id,
        ])
      ) {
        this.dbSaveService.createEntity(ProgramViewProductTypeConfig, {
          programViewId: row.id,
          productTypeConfigId: ptc.id,
        });
      }
    });
    // HACK - AgGrid issue - complete hack - needed to show expando on grid  if row doesn't already have it.
    AgFns.refreshGrid(this.pvGridOptions, []);
    AgFns.refreshGrid(this.pvGridOptions, this.programViews);

    const rowNode = this.pvGridOptions.api?.getRowNode(row.id);
    if (rowNode) {
      rowNode?.setExpanded(true);
      this.pvGridOptions.api?.redrawRows({ rowNodes: [rowNode] });
    }
    AgFns.refreshDetailRowHeights(this.pvGridOptions);
  }

  async onProgramViewDetach(row: ProgramViewProductTypeConfig) {
    if (this.programViews == null) return;
    const parentRowNode = this.pvGridOptions.api?.getRowNode(row.productTypeConfigId);
    EntityFns.deleteOrDetach(row.entityAspect);

    AgFns.refreshGrid(this.pvGridOptions, this.programViews);
    parentRowNode?.setExpanded(true);
    AgFns.refreshDetailRowHeights(this.pvGridOptions);
  }
 
  async markError(errEnt: Entity, propName?: string) {
    const ent = errEnt as ProgramView;
    AgFns.selectGridRowByKey(
      this.pvGridOptions,
      (e: ProgramView) => e.id,
      ent.id,
      propName
    );
  }
  
  
}
