/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';

import { ProgramProductTypeTag } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { AccountDbQueryService } from '../services/account-db-query.service';

export interface ProgramProductTypeTagFinderParams extends EntityFinderParams {
  pptts: ProgramProductTypeTag[];
}

@Component({
  selector: 'prox-program-product-type-tag-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProgramProductTypeTagFinderDialog extends EntityFinderDialog<ProgramProductTypeTag, ProgramProductTypeTagFinderParams> {

  params: ProgramProductTypeTagFinderParams;
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<ProgramProductTypeTagFinderParams>,
    public dbQueryService: AccountDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.pptts, 'pptts');
  }

  override async initialize() {
    return {
      header: `Select Category Tags`,
      width: 'calc(40vw)',
      rowModelType: 'clientSide',
      rowSelection: 'multiple'
    } as const;
  }

  override async initDatasource() {
    return this.params.pptts;
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      {  headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' },
    ] as ColDef[]
    const sortModel = [
      { colId: 'name', sort: 'asc' },
      
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }
}