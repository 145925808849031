<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Compliance</h5>
  </div>
  <div class="h-full flex flex-column ">
    <div class="mb-3">
      <button [disabled]="parent.isReadOnly()" (click)="onPvAdd()" pButton type="button" label="Add Compliance" icon="pi pi-plus"
        class="p-button-sm"></button>
    </div>
    <div class="flex-grow-1">
      <prox-ag-grid [gridOptions]="pvGridOptions" [rowData]="programViews"></prox-ag-grid>
    </div>
  </div>
</div>
