/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, AgFns, SaveAndQueryComponent, StatusService } from '@core';
import { Account, AccountBudget, JobOrderBudgetLog } from '@models';
import { DateFns, UtilFns } from '@utils';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { BudgetItemsListComponent } from './budget-items-list.component';
import { EntityFns } from '@data';


@Component({
  selector: 'prox-account-budgets',
  templateUrl: './account-budgets.component.html',
})
export class AccountBudgetsComponent extends SaveAndQueryComponent {
  @ViewChild(BudgetItemsListComponent) budgetListItemsComponent!: BudgetItemsListComponent;
  accountId!: string;
  account!: Account;
  accountBudgets!: AccountBudget[];
  joBudgetLogs!: JobOrderBudgetLog[];
  
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);    
    UtilFns.assertNonNull(this.account, 'Account');

    this.accountBudgets = await this.dbQueryService.getAccountBudgets(this.accountId);
    this.joBudgetLogs = await this.dbQueryService.getJobOrderBudgetLogsForAccount(this.accountId, this.account.getCurrentYearFiscalStartDate());
    this.setTitle('Account Budgets from ' + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  get accountBudgetGridOptions() {
    return this.budgetListItemsComponent?.budgetGridOptions;
  }

  createBudget() {
    const budget = this.dbSaveService.createEntity(AccountBudget, {
      accountId: this.accountId,
      budgetProductTypeTagId: null as any,
      fiscalStartDate: this.account.getCurrentYearFiscalStartDate()
    });
    
    return budget;
  }

  // ---- Misc ----------------------------


  override async addCrossValidationErrors() {
    
    EntityFns.checkForDupErrors(
      this.account.accountBudgets,
      (x) => (x.budgetProductTypeTag?.name ?? '- Any -') + ' - ' + DateFns.fmtDateShort(x.fiscalStartDate),
      (e, dupName) =>
        this.createValidationError(
          e,
          'name',
          `Duplicate Budget Product Tag Name within the same fiscal year: '${dupName}'`
        )
    );
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    // Note - slice is needed in next line otherwise the budgetListComponent doesn't realize budgets have changed.
    this.accountBudgets = this.account.accountBudgets.slice();
    // this.accountBudgets = await this.dbQueryService.getAccountBudgets(this.accountId);
  }


}
