<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="flex flex-column h-full">
    <p-splitter class="flex-grow-1 w-full" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
      <ng-template pTemplate>
        <div class="flex-column pt-2 pb-2 w-full h-full">
          <prox-ag-grid [gridOptions]="pptGridOptions"></prox-ag-grid>
        </div>
      </ng-template>
      <ng-template pTemplate>
        <div class="flex flex-column w-full">
          <div class="flex ml-2 mt-2 mb-2">
            <label class="mt-2 ml-4">Select Product Configuration: </label>
            <p-dropdown [disabled]="!selectedPpt" class="ml-2 flex-grow-1" [options]="ptcs" optionLabel="name"
              dataKey="id" [(ngModel)]="selectedPtc" autoWidth="false" [style]="{'minWidth':'100%'}"
              (onChange)="onSelectedPtcChange()">
            </p-dropdown>
          </div>
          <div class="flex-column pt-2 pb-2 w-full h-full">
            <prox-ag-grid [gridOptions]="ptcpGridOptions" [rowData]="ptcps">
            </prox-ag-grid>
          </div>
          <div class="flex-column pt-2 pb-2 w-full h-full">
            <prox-ag-grid [gridOptions]="ptcaGridOptions" [rowData]="ptcas">
            </prox-ag-grid>
          </div>
        </div>
      </ng-template>
    </p-splitter>
  </div>
</ng-template>