/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy } from '@angular/core';
import { ProductType } from '@models';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { Subscription } from 'rxjs';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { ActivatedRoute } from '@angular/router';
import { BaseService, SaveAndQueryComponent, StatusService } from '@core';
import { AccountDbSaveService } from '@account';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-dashboard-frm',
  templateUrl: './dashboard-frm.component.html',
})
export class DashboardFrmComponent extends SaveAndQueryComponent implements OnDestroy  {
  products: ProductType[] = [];
  productsThisWeek: ProductType[] = [];
  productsLastWeek: ProductType[] = [];
  productsBestSellers: ProductType[] = [];
  subscription!: Subscription;
  items: MenuItem[] = [];
  ordersChart: any;
  ordersChartOptions: any;
  revenueChart: any;
  revenueChartOptions: any;
  cols: any[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
/*     this.subscription = this.layoutService.configUpdate$
    .pipe(debounceTime(25))
    .subscribe((config) => {
        this.initCharts();
    }); */
  }

  //config: AppConfig = this.layoutService.config();

  orderWeek: any[] = [];

  metrics: any[] = [];

  teamMembers: any[] = [];

  selectedOrderWeek!: any;

  async updateFromParams(): Promise<void> {
/*       this.productService.getProducts().then((data) => {
          this.products = data;
          this.productsThisWeek = data;
          this.productsBestSellers = data.slice(0, 7);
      });

      this.productService
          .getProductsMixed()
          .then((data) => (this.productsLastWeek = data)); */

      this.cols = [
          { field: 'vin', header: 'Vin' },
          { field: 'year', header: 'Year' },
          { field: 'brand', header: 'Brand' },
          { field: 'color', header: 'Color' },
      ];

      this.items = [
          {
              label: 'Shipments',
              items: [
                  { label: 'Tracker', icon: 'pi pi-compass' },
                  { label: 'Map', icon: 'pi pi-map-marker' },
                  { label: 'Manage', icon: 'pi pi-pencil' },
              ],
          },
      ];

      this.orderWeek = [
          { name: 'This Week', code: '0' },
          { name: 'Last Week', code: '1' },
      ];

      this.metrics = [
          {
              title: 'Orders',
              icon: 'pi pi-shopping-cart',
              color_light: '#64B5F6',
              color_dark: '#1976D2',
              textContent: [
                  { amount: '640', text: 'Pending' },
                  { amount: '1420', text: 'Completed' },
              ],
          },
          {
              title: 'Revenue',
              icon: 'pi pi-dollar',
              color_light: '#7986CB',
              color_dark: '#303F9F',
              textContent: [
                  { amount: '$2,100', text: 'Expenses' },
                  { amount: '$9,640', text: 'Income' },
              ],
          },
          {
              title: 'Customers',
              icon: 'pi pi-users',
              color_light: '#4DB6AC',
              color_dark: '#00796B',
              textContent: [
                  { amount: 8272, text: 'Active' },
                  { amount: 25402, text: 'Registered' },
              ],
          },
          {
              title: 'Comments',
              icon: 'pi pi-users',
              color_light: '#4DD0E1',
              color_dark: '#0097A7',
              textContent: [
                  { amount: 12, text: 'New' },
                  { amount: 85, text: 'Responded' },
              ],
          },
      ];

      this.teamMembers = [
          {
              name: 'Amy Elsner',
              desc: 'Accounting',
              image: 'amyelsner',
          },
          {
              name: 'Anna Fali',
              desc: 'Procurement',
              image: 'annafali',
          },
          {
              name: 'Bernardo Dominic',
              desc: 'Finance',
              image: 'bernardodominic',
          },
          {
              name: 'Ivan Magalhaes',
              desc: 'Sales',
              image: 'ivanmagalhaes',
          },
          {
              name: 'Xuxue Feng',
              desc: 'Management',
              image: 'xuxuefeng',
          },
      ];

      this.initCharts();
  }

  initCharts() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue(
          '--text-color-secondary'
      );
      const surfaceBorder =
          documentStyle.getPropertyValue('--surface-border');
      this.ordersChart = {
          labels: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
          ],
          datasets: [
              {
                  label: 'New',
                  data: [2, 7, 20, 9, 16, 9, 5],
                  backgroundColor: ['rgba(100, 181, 246, 0.2)'],
                  borderColor: ['#64B5F6'],
                  borderWidth: 3,
                  fill: true,
                  tension: 0.4,
              },
          ],
      };

      this.ordersChartOptions = {
          plugins: {
              legend: {
                  display: true,
                  labels: {
                      color: textColor,
                  },
              },
          },
          hover: {
              mode: 'index',
          },
          scales: {
              x: {
                  ticks: {
                      color: textColorSecondary,
                  },
                  grid: {
                      color: [surfaceBorder],
                      drawBorder: false,
                  },
              },
              y: {
                  ticks: {
                      color: textColorSecondary,
                      min: 0,
                      max: 20,
                  },
                  grid: {
                      color: [surfaceBorder],
                      drawBorder: false,
                  },
              },
          },
      };

      this.revenueChart = {
          labels: ['Direct', 'Promoted', 'Affiliate'],
          datasets: [
              {
                  data: [40, 35, 25],
                  backgroundColor: ['#64B5F6', '#7986CB', '#4DB6AC'],
                  borderColor: [surfaceBorder],
              },
          ],
      };

      this.revenueChartOptions = {
          plugins: {
              legend: {
                  display: true,
                  labels: {
                      color: textColor,
                  },
              },
          },
      };
  }

  onGlobalFilter(table: Table, event: Event) {
      table.filterGlobal(
          (event.target as HTMLInputElement).value,
          'contains'
      );
  }

  changeDataset(event: any) {
      const dataSet = [
          [2, 7, 20, 9, 16, 9, 5],
          [2, 4, 9, 20, 16, 12, 20],
          [2, 17, 7, 15, 4, 20, 8],
          [2, 2, 20, 4, 17, 16, 20],
      ];

      this.ordersChart.datasets[0].data =
          dataSet[parseInt(event.currentTarget.getAttribute('data-index'))];
      this.ordersChart.datasets[0].label =
          event.currentTarget.getAttribute('data-label');
      this.ordersChart.datasets[0].borderColor =
          event.currentTarget.getAttribute('data-stroke');
      this.ordersChart.datasets[0].backgroundColor =
          event.currentTarget.getAttribute('data-fill');
  }

  recentSales(event: any) {
      if ((event.target as HTMLInputElement).value === '0') {
          this.products = this.productsThisWeek;
      } else {
          this.products = this.productsLastWeek;
      }
  }

  updateChartOptions() {
/*       if (this.config.colorScheme === 'dark') this.applyDarkTheme();
      else this.applyLightTheme(); */
  }

  applyDarkTheme() {
      this.ordersChartOptions = {
          plugins: {
              legend: {
                  labels: {
                      color: '#ebedef',
                  },
              },
          },
          scales: {
              x: {
                  ticks: {
                      color: '#ebedef',
                  },
                  grid: {
                      color: 'rgba(160, 167, 181, .3)',
                  },
              },
              y: {
                  ticks: {
                      color: '#ebedef',
                  },
                  grid: {
                      color: 'rgba(160, 167, 181, .3)',
                  },
              },
          },
      };
  }

  applyLightTheme() {
      this.ordersChartOptions = {
          plugins: {
              legend: {
                  labels: {
                      color: '#495057',
                  },
              },
          },
          scales: {
              x: {
                  ticks: {
                      color: '#495057',
                  },
                  grid: {
                      color: '#ebedef',
                  },
              },
              y: {
                  ticks: {
                      color: '#495057',
                  },
                  grid: {
                      color: '#ebedef',
                  },
              },
          },
      };
  }

  override ngOnDestroy() {
      this.subscription.unsubscribe();
  }
}
