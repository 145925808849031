/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActivatedRoute, Router } from '@angular/router';
import { DbSaveService, EntityFns } from '@data';
import { DataProperty, DataType, Entity, EntityError, SaveResult, ValidationError } from 'breeze-client';
import { ToastrService } from 'ngx-toastr';
import { UtilDialogService } from '../../services/dialog.service';
import { ISuspendable } from '../component-interfaces';

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export type GConstructor<T = {}> = abstract new (...args: any[]) => T;

// properties that saveComponentMixin needs
type SaveModel = {
  route: ActivatedRoute,
  dbSaveService: DbSaveService
  dialogService: UtilDialogService;
  router: Router;
  toastr: ToastrService;
  entityId?: string;

  suspendables: Set<ISuspendable>;

  canDeactivate(): Promise<boolean>;
  canUnload(): boolean;
  deactivate(): void;
  navigateBack(): void;
}

export function saveComponentMixin<TBase extends GConstructor<SaveModel>>(BaseClass: TBase) {

  abstract class SaveComponentMixin extends BaseClass {

    isCancelling = false;
    errIndex = 0;
    extraValidationSet = new Set<Entity>();
    

    // override this method if the component has changes that have not yet been reflected in the dbSaveService.
    hasChanges(): boolean { 
      return this.dbSaveService.hasChanges();
    }

    canSave(): boolean {
      return (!this.dbSaveService.isBusy()) && this.hasChanges();
    }

    canUndo(): boolean {
      return (!this.dbSaveService.isBusy()) && this.hasChanges();
    }

    canShowLog(): boolean {
      return !!this.entity || !!this.entityId;
    }

    showChangeLog() {
      return this.dialogService.changeLogDialog(this.dbSaveService.uow, null, this.entity, this.entityId);
    }

    get entity(): Entity | undefined { return undefined; }

    // DO not override this - override beforeSave and afterSave
    async onSave() {

      // normal use case is that this calls ag-grid stop editing - when the suspendable is the Ag-grid GridOptions object.
      this.suspendables.forEach(s => s.suspend && s.suspend());

      if (!this.hasChanges()) {
        this.toastr.warning('No changes detected', 'Not saved');
        return;
      }
      this._trimBeforeSave();

      const ok = await this.beforeSave();
      if (!ok) {
        return;
      }

      const errEntities = await this.validateEntities();
      if (errEntities.length > 0) {
        await this.handleBreezeValidationErrors(errEntities);
        return;
      }

      try {
        const result = await this.saveChanges();
        // 
        this.clearEntitiesToValidate();
        await this.afterSave(result);
        this.afterSaveToast(result);
      } catch (e: any) {
        const errMessage = EntityFns.formatErrorMessage(e);
/*         console.log(e);
        console.log(errMessage); */
        this.handleSaveError(e, errMessage);

      }
    }

    // overwrite if you need to actually control the save order - THIS SHOULD BE RARE. 
    async saveChanges() {
      const result = await this.dbSaveService.saveChanges();
      return result;
    }

    // override in order to do work before saving
    async beforeSave() {
      return true;
    }

    // override in order to do work before saving
    async afterSave(saveResult: SaveResult) {
      // do nothing
    }

    // override in order to change the toast after a save
    afterSaveToast(saveResult: SaveResult) {
      this.toastr.success('Saved', 'Database Action');
    }

    // --------------------------------------------------------------------------------------------------
    
    // Do not override this - use beforeUndo and afterUndo instead
    async onUndo() {
      // normal use case is that this calls ag-grid stop editing - when the suspendable is the Ag-grid GridOptions object.
      this.suspendables.forEach(s => s.suspend && s.suspend());
      this.beforeUndo();
      if (!this.hasChanges()) {
        return;
      } else {
        const ok = await this.dialogService.confirmUndo();
        if (ok) {
          this.dbSaveService.rejectChanges();
          this.clearEntitiesToValidate();
          await this.afterUndo();
          this.afterUndoToast();
        }
      }
    }

    // override to do work before an undo
    beforeUndo() {
      // do nothing;
    }

    // override to do work after an undo
    async afterUndo() {
      // do nothing
    }

    // override in order to change the toast after a save
    afterUndoToast() {
      this.toastr.success('All changes were reversed', 'Undo');
    }

    //----------------------------------------------------------------------------------------------------------
    /** Undo changes and navigate back to parent page */
    async onCancel() {
      if (!await this.confirmCancelIfNeeded()) {
        return;
      }
      this.isCancelling = true;
      if (this.canDeactivate) {
        if (await this.canDeactivate()) {
          await this.navigateBack();
        }
      }
      this.isCancelling = false;
    }

    public override async canDeactivate() {
      if (this.isCancelling) {
        return true;
      }
      return await this.confirmCancelIfNeeded();
    }

    public override canUnload()  {
      if (this.hasChanges()) {
        return window.confirm('Save your changes first?');
      }
      return true;
    }
  

    async confirmCancelIfNeeded() {
      if (this.hasChanges()) {
        const ok = await this.dialogService.confirmCancel();
        if (ok) {
          this.dbSaveService.rejectChanges();
        }
        return ok;
      }
      return true;
    }

    // -------------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------
        
    // insure that all string fields are trimmed before saving
    _trimBeforeSave() {
      const ents = this.dbSaveService.getChanges();
      ents.forEach(e => {
        // later on use - e.entityAspect.originalValues
        // TODO: this may need modification to work with complex properties i.e. like person.address.city
        e.entityType.getProperties().filter(p => p.isDataProperty).forEach(dp => {
          if ((dp as DataProperty).dataType == DataType.String) {
            const value = e[dp.name];
            if (value != null && value.trim().length != value.length) {
              e[dp.name] = value.trim();
            }
          }
        });
        
      });
    }

    addEntitiesToValidate(...entities: Entity[]) {
      entities.forEach(x => x && this.extraValidationSet.add(x));
    }

    clearEntitiesToValidate() {
      this.extraValidationSet.clear();
    }
    
    // do not override this - use addCrossValidation errors
    async validateEntities() {
      this.removeCrossValidationErrors();
      await this.addCrossValidationErrors();
      const changedEntities = this.getEntitiesToValidate();
      const errEntities = this.dbSaveService.validateEntities(changedEntities)
      return errEntities;
    }

    getEntitiesToValidate() {
      const changedEntities = this.dbSaveService.getAddedModifiedEntities();
      // The only extraEntities we should get are the unchanged ones because the added or modified will be returned above
      const extraEntities = Array.from(this.extraValidationSet).filter(x => x.entityAspect.entityState.isUnchanged());
      changedEntities.push(...extraEntities);
      return changedEntities;
    }

    getEntitiesToValidateOfType<T extends Entity>(type: { new(): T; } ): T[] {
      return this.getEntitiesToValidate().filter(x => x instanceof type) as T[];
    }

    // override to add new validation errors with the createValidationError fn below.
    async addCrossValidationErrors(  ) {
      //
    }

    removeCrossValidationErrors() {
      // may include deletes - this is ok. 
      const ents = this.dbSaveService.getChanges();
      ents.push(... Array.from(this.extraValidationSet));
      ents.forEach(ent => {
        const errs = ent.entityAspect.getValidationErrors();
        errs.forEach(err => {
          if (err.context['isSaveMixinError']) {
            ent.entityAspect.removeValidationError(err);
          }
        });
      });
    }

    createValidationError(entity: Entity, propertyName: string | null, errorMessage: string) {
      const key = 'saveMixinError-' + this.errIndex++;
      const context = propertyName ? { propertyName: propertyName } : {};
      context['isSaveMixinError'] = true;
      const valErr = new ValidationError(null, context , errorMessage, key)
      entity.entityAspect.addValidationError(valErr );
      // we want to make sure that if we add an error to an unmodified entity
      // that it still triggers validation issues. 
      if (entity.entityAspect.entityState.isUnchanged()) {
        this.addEntitiesToValidate(entity);
      }
    }

    // override if you want to present Breeze validation errors differently
    async handleBreezeValidationErrors(errEntities: Entity[]) {
      await this.dialogService.validationDialog(this, errEntities, this.navigateToValidationError.bind(this), true);      
    }

    // override to handle navigating to validation error
    navigateToValidationError(ee: EntityError, event: MouseEvent) {
      this.toastr.warning('Navigation to error not implemented', 'Not yet implemented');
    }

    // override if you want to present Save error differently
    handleSaveError(err: Error, errMessage: string) {
      this.dialogService.errorDialog(err);
    }

    showHelp(title?: string, field?: string): void {
      this.dialogService.helpDialog(title || '', this, field, this.navigateToValidationError);
    }  
      
    /** Discard unsaved changes and return to previous page */
    public override deactivate() {
      super.deactivate();
      this.dbSaveService.rejectChanges();
    }

  }

  return SaveComponentMixin;
}
