/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';

import { AccountAddress, AccountUser } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { AccountDbQueryService } from '../services/account-db-query.service';

export interface AccountAddressFinderParams extends EntityFinderParams {
  accountId: string;
  accountUserId?: string;
  addressType: 'B' | 'S';
  canShipHome?: boolean;
}

@Component({
  selector: 'prox-account-address-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AccountAddressFinderDialog extends EntityFinderDialog<AccountAddress, AccountAddressFinderParams> {

  params: AccountAddressFinderParams;
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<AccountAddressFinderParams>,
    public dbQueryService: AccountDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.accountId, 'accountId');
  }

  override async initialize() {
    const name = this.params.addressType == 'B' ? 'Billing Address' : 'Shipping Address';
    return {
      header: `Select a ${name}`,
      width: 'calc(70vw)',
      rowModelType: 'clientSide'
    } as const;
  }

  override async initDatasource() {
    let addrs: AccountAddress[];
    if (this.params.addressType == 'B') {
      addrs = await this.dbQueryService.getBillingAccountAddresses(this.params.accountId);
    } else {
      addrs = await this.dbQueryService.getShippingAccountAddresses(this.params.accountId, this.params.accountUserId, this.params.canShipHome);
    }
    return addrs;
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      { headerName: 'Description', field: 'description',  filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter' },
      { headerName: 'Line 1', field: 'line1', filter: 'agTextColumnFilter' },
      { headerName: 'Line 2', field: 'line2', filter: 'agTextColumnFilter' },
      { headerName: 'Line 3', field: 'line3', filter: 'agTextColumnFilter' },
      { headerName: 'City', field: 'city', filter: 'agTextColumnFilter' },
      { headerName: 'State', field: 'state', filter: 'agTextColumnFilter' },
      { headerName: 'Zip', field: 'zipcode', filter: 'agTextColumnFilter' },
    ] as ColDef[]
    const sortModel = [
      { colId: 'description', sort: 'asc' },
      { colId: 'name', sort: 'asc' },
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }


}