<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="mb-2">
    <button (click)="onAtAdd()" pButton type="button" label="Add Proximity Account Organization Tree" icon="pi pi-plus"
      class="p-button-sm" [disabled]="isReadOnly()"></button>
    <span
      style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
  </div>
  <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[dims[0], dims[1]]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="atGridOptions" [rowData]="approvalTrees"></prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[dims[2], dims[3]]">
        <ng-template pTemplate>
          <div *ngIf="treeData" class="flex flex-column w-full h-full">
            <div class="flex ml-2 my-2">
              <p-button [disabled]="!selectedApprovalTree || isReadOnly()" (click)="onTreeAttachToAdminGroup()"
                styleClass="p-button p-button-sm"
                label={{addAdminGroupsLabel()}} icon="pi pi-plus"
                class="p-button-sm"></p-button>
              <div class="ml-2"><p-button *ngIf="selectedTreeItem" [disabled]="!selectedApprovalTree || isReadOnly()" (click)="onShowUsers()"
                styleClass="p-button p-button-sm"
                label={{showUsersLabel()}} icon="pi pi-plus"
                class="p-button-sm"></p-button></div>
            </div>
            <div class="flex-grow-1 py-2 w-full h-full">
              <prox-ag-grid [gridOptions]="treeGridOptions" [rowData]="treeData">
              </prox-ag-grid>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate>
        <div *ngIf="usersOrAdmins" class="flex flex-column w-full h-full">
          <div *ngIf = "showUsers" class="flex-grow-1 py-2 w-full h-full">
            <prox-ag-grid [gridOptions]="userGridOptions" [rowData]="usersOrAdmins">
            </prox-ag-grid>
          </div>
         </div>
        </ng-template>
      </p-splitter>
    </ng-template>
  </p-splitter>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>