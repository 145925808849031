import * as _ from 'lodash';
import { Entity, Validator } from 'breeze-client';
import { IAddress } from '@models';

interface JQuery {
  html: any;
}

interface NumericIdType {
  id: number;
}

export interface IHasIdAndName {
  id: string;
  name: string;
}

// type GenericOf<T> = T extends BaseClass<infer X> ? X : never;
// type SecondGenericOf<T> = T extends BaseClass2<infer X,infer XArgs> ? XArgs : never;


export class UtilFns {

  static EmptyGuid = "00000000-0000-0000-0000-000000000000";
  
  static AssertString = 'AssertionError: ';
  static emailValFn = Validator.emailAddress(null).valFn;

  static errorCount = 0;

  static assert(condition: unknown, msg = '' ) : asserts condition {
    if (condition === false) throw new Error(this.AssertString + msg)
  }

  static assertNonNull<TValue>(value: TValue, name = 'value') : asserts value is NonNullable<TValue> {
    if (value === null || value === undefined) {
      throw Error(this.AssertString + `'${name}' cannot be null`);
    }
  }

  static assertNonEmptyString(value: unknown, name = 'value'): asserts value is NonNullable<string> {
    
  // static assertNonEmptyString(value: unknown, name: string): asserts value is string {
    if (typeof value !== 'string') throw Error(this.AssertString + `'${name}' is not a string`);
    if (value == null || value.length == 0) throw Error(this.AssertString + `'${name}' cannot be null or empty`);
  }

  static assertArrayOfOne(value: unknown[], msg: string = 'exactly one item was expected') {
    this.assert(value.length == 1, this.AssertString + msg);
  }

  static assertArrayOZeroOrOne(value: unknown[], msg: string = 'either 0 or 1 item was expected') {
    this.assert(value.length <= 1, this.AssertString + msg);
  }

  static assertHasIdAndName(object: any): asserts object is IHasIdAndName {
    if ('id 'in object &&  'name' in object) return; 
    throw Error(this.AssertString + 'Object does not implement "id" or "name" property');
  }

  // type guards

  static hasIdAndName(object: object): object is IHasIdAndName {
    return 'id 'in object &&  'name' in object
  }

  static isNotNull<T>(argument: T | undefined | null): argument is T {
    return argument != null
}

  static wait = (ms: number) => new Promise(res => setTimeout(res, ms));

  /** Return the root url of the application including base path, e.g "https://myhost.com/test/" 
   * If no base element is defined, then return origin, e.g. "https://myhost.com/" */
  static getBaseUrl() {
    // get base element e.g. <base href="/test/">
    const base = document.getElementsByTagName('base')[0];
    return base ? base.href : (window.location.origin + '/');
  }

  /** Return the base path e.g. "/test/", or "/" if no base defined */
  static getBasePrefix() {
    const base = this.getBaseUrl();
    return base.substring(window.location.origin.length);
  }

  /** Remove the base url from the given path, returning the relative url within the Angular app: 
   * - "https://myhost.com/test/doc/7" => "doc/7"
   * - "/test/doc/7" => "doc/7"  */
  static trimBasePrefixFromPath(path: string) {
    let base = this.getBaseUrl();
    if (path.startsWith(base)) {
      return path.substring(base.length);
    } 
    base = this.getBasePrefix();
    if (path.startsWith(base)) {
      return path.substring(base.length);
    } 
    return path;    
  }

  static setDifference<T>(a: Set<T>, b: Set<T>) {
    return Array.from(a).filter(item => !b.has(item));
  }

  static objectArrayToObject(arr: object[], keyName: string, valueName?: string) {
    const result = arr.reduce((obj, item) => {
      const value = valueName != null ? item[valueName] : item;
      obj[item[keyName]] = value;
      return obj;
    }, {});
    return result;
  }

  static objectArrayToMap(arr: object[], keyName: string, valueName?: string) {
    const map = new Map();
    arr.forEach(item => {
      const value = valueName != null ? item[valueName] : item;
      map.set(item[keyName], value);
    })
    return map;
  }

  /** Convert encoded string into byte array for File or Blob */
  static stringToByteArray(s: string) {
    const byteCharacters = atob(s);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
  }
  

  // converts 'stringLikeThis into 'String Like This'
  static splitCamelCaseToString(s: string) {
    return s.split(/(?=[A-Z])/).map(function(p) {
        return p.charAt(0).toUpperCase() + p.slice(1);
    }).join(' ');
  }

  static generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line: no-bitwise
      const r = Math.random() * 16 | 0,
        // tslint:disable-next-line: no-bitwise triple-equals
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static generateStringId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  static addNullChoice<T>(items: T[], keyField = 'id', keyFieldValue: any = undefined, displayField = 'name', displayFieldValue = "- None -" ) {
    const nullItem = {  } 
    nullItem[keyField] = keyFieldValue
    nullItem[displayField] = displayFieldValue;
    items.unshift( nullItem as T );
  }

  /** Set the focus on the field for the given entity property 
   * @param component enclosing component element, e.g. 'prox-user-frm'
   * @param entityName entity.toString() to match bz-model attribute
   * @param property name of property, to match name attribute */
  static focusInputByEntity(component: string, entity: Entity, property: string): boolean {
    const entityName = entity.toString().substring(0, 30);
    return this.focusInputByProperty(component, entityName, property);
  }

  /** Set the focus on the field for the given entity property 
   * @param component enclosing component element, e.g. 'prox-user-frm'
   * @param bzName entity key to match bz-model attribute
   * @param property name of property, to match name attribute */
  private static focusInputByProperty(component: string, bzName: string, property: string): boolean {
    // select by input bound to entity
    let selector = `${component} :is(input, select, textarea)[ng-reflect-bz-model='${bzName}'][ng-reflect-name='${property}']`;
    let el = document.querySelector(selector);
    if (el instanceof HTMLElement) {
      el.focus();
      return true;
    }
    // select by input that is child of bound component (e.g. p-inputNumber)
    selector = `${component} [ng-reflect-bz-model='${bzName}'][ng-reflect-name='${property}'] input`;
    el = document.querySelector(selector);
    if (el instanceof HTMLElement) {
      el.focus();
      return true;
    }
    return false;
  }

  static forceLoseFocus() {
    const activeElement = document.activeElement;
    if (activeElement) {
      (activeElement as any).blur();
    }
  }

  static addClass(element: HTMLElement | JQuery, className: string) {
    const el = element as any;
    if (el.addClass) {
      el.addClass(className);
    } else if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  }

  static removeClass(element: HTMLElement | JQuery, className: string) {
    const el = element as any;
    if (el.removeClass) {
      el.removeClass(className);
    } else if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  }

  static setHtml(element: HTMLElement | JQuery, html: string) {
    const el = element as any;
    if (el.html) {
      (el as JQuery).html(html);
    } else {
      (el as HTMLElement).innerHTML = html;
    }
  }


  static fmtInt(amt: number) {
    if (!(typeof amt === 'number')) {
      return '';
    }
    return amt.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  static fmtIntZ(amt: number) {
    if (amt == null || amt === 0) {
      return '';
    }
    return this.fmtInt(amt);
  }

  static fmtCurrency(amt?: number, decimalDigits = 2) {
    if (!(typeof amt === 'number')) {
      return '';
    }
    return '$' + amt.toFixed(decimalDigits).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  // A here stands for 'A'counting format i.e. negatives with ().
  static fmtCurrencyA(amt: number, decimalDigits = 2) {
    const val = amt < 0 ? amt * -1 : amt;
    const tmp = this.fmtCurrency(val, decimalDigits);
    // last char in line below is actually a nbsp char - i.e. Alt + 255 NOT a blank
    // The is needed to allow currency formatting of negative numbers to align with positives.
    // return amt < 0 ? '(' + tmp + ')' : ' ' + tmp + ' ';
    // ABOVE REMOVED in favor of style that reduces padding-right
    return amt < 0 ? '(' + tmp + ')' : ' ' + tmp;
  }

  // M here stands for Minus format i.e. negatives with - before the $.
  static fmtCurrencyM(amt: number, decimalDigits = 2) {
    const val = amt < 0 ? amt * -1 : amt;
    const tmp = this.fmtCurrency(val, decimalDigits);
    // last char in line below is actually a nbsp char - i.e. Alt + 255 NOT a blank
    // The is needed to allow currency formatting of negative numbers to align with positives.
    // return amt < 0 ? '- ' + tmp : ' ' + tmp;
    // ABOVE REMOVED since it seems unnecessary and causes problems with export
    return amt < 0 ? '- ' + tmp : tmp;
  }

  static fmtCurrencyAZ(amt: number, decimalDigits = 2) {
    if (amt == null || amt === 0) {
      return '';
    }
    return this.fmtCurrencyA(amt, decimalDigits);
  }

  static fmtCurrencyK(amt: number, decimalDigits = 0) {
    if (amt > 1e6) {
      return UtilFns.fmtCurrency(amt / 1e6, decimalDigits + 1) + 'M';
    } else {
      return UtilFns.fmtCurrency(amt / 1000, decimalDigits) + 'K';
    }
  }

  static fmtCurrencyShort(amt: number, decimalDigits = 0) {
    if (!(typeof amt === 'number')) {
      return '';
    }
    let amount = amt;
    let suffix = '';
    if (amount > 1000000) {
      amount = amount / 1000000;
      suffix = 'M';
    } else if (amount > 1000) {
      amount = amount / 1000;
      suffix = 'K';
    }
    return '$' + amount.toFixed(decimalDigits).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + suffix;
  }

  static parseNumber(value: string) {
    return Number(value.replace(/[^0-9.-]+/g,""));
  }

  static parsePct(value: String) {
    const v = (value || '').trim();
    const hasPct = v.endsWith('%');
    const pct = this.parseNumber(v);
    try {
      return hasPct ? pct/100 : pct;
    } catch {
      return null;
    }
  }

  static fmtPct(amt: number, decimalDigits = 0) {
    if (!(typeof amt === 'number')) {
      return '';
    }
    if (Number.isNaN(amt)) {
      return '';
    }
    return (amt * 100).toFixed(decimalDigits) + '%';
  }

  static fmtPctZ(amt: number, decimalDigits = 0) {
    if (!(typeof amt === 'number')) {
      return '';
    }
    if (Number.isNaN(amt)) {
      return '';
    }
    if (amt === 0) {
      return '';
    }
    return (amt * 100).toFixed(decimalDigits) + '%';
  }

  static getDuplicates(list: any[]) {
    return _(list).groupBy().pickBy(x => x.length > 1).keys().value();
  }

  static getDuplicatesOnProp<T>(list: T[], extractValueFn: (item) => string ) {
    return _(list).groupBy(s => extractValueFn(s)).pickBy(x => x.length > 1).value();
  }

  /** Return true if amt is null or close enough to zero */
  static isZero(amt: number) {
    const z = !amt || Math.abs(amt) < 0.005;
    return z;
  }

  static getPropertyDescriptor(obj: Object, propName: string) {
    const descr = Object.getOwnPropertyDescriptor(obj, propName);
    if (descr != null) {
      return descr;
    }
    obj = Object.getPrototypeOf(obj);
    if (obj == null) {
      return null;
    }
    return this.getPropertyDescriptor(obj, propName);
  }

  static validateGuid(value: string) {
    if (value == null) {
      return true;
    }
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(value);
  }

  static validateEmail(value: string) {
    if (!value || value.length < 6) {
      return false;
    }
    return UtilFns.emailValFn(value);
  }

  static getSettableKeys(obj: object, endsWith: string[]) {
    const keys = _.keysIn(obj).filter(kn => {
      if (endsWith.some(ew => _.endsWith(kn, ew))) {
        const propDescr = UtilFns.getPropertyDescriptor(obj, kn);
        return propDescr != null && (propDescr.writable || propDescr.set != null);
      }
    });
    return keys;
  }

  static getErrorMessage(error) {
    if (!error) {
      return 'Unknown error';
    } else if (typeof error === 'string') {
      return error;
    } else if (error.status === 0) {
      return 'Unable to contact server';
    } else {
      let msg = error.exceptionMessage || error.error || error.message || error.error_description || error.statusText;
      if (msg && msg.substring) {
        if (msg.indexOf('EntityErrorsException') >= 0) {
          return 'Validation errors received from server';
        }
        return msg;
      } else {
        error = (error.json && error.json()) || error;
        msg =
          error.exceptionMessage || error.message || error.error_description || (error.toString && error.toString());
        return msg || JSON.stringify(error);
      }
    }
  }

  /** format address as a CRLF-separated string */
  static getAddress(ad?: IAddress, seper: string = '\r\n') {
    if (!ad) { return ''; }
    let zip = ad.zipcode as string;
    if (zip) { zip = zip.trim(); }
    if (zip && zip.endsWith('-')) { zip = zip.substring(0, zip.length - 1); }
    return [ ad.name, ad.line1, ad.line2, ad.line3, ad.city + ', ' + ad.state + ' ' + zip].filter(x => !!x).join(seper);
  }

  static createIdMap<T extends NumericIdType>(ents: T[])  {
    const entMap = new Map<number, T>();
    ents.forEach(ent => entMap.set(ent.id,ent));
    return entMap;
  }

  static async wrapOp( toggle: (v: boolean) => void, op: () => void | Promise<void> ) {
    toggle(true);
    try {
      await op();
    } finally {
      toggle(false);
    }
  }

  /** Download the data to a file */
  static downloadFile(data: string, mimeType: string, name: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], { type: mimeType }));
    a.download = name;
    a.click();
    a.remove();
  }

  // // converts array into array of arrays
  // groupBy<T>( array: T[] , f: (o: T) => Object[] ) {
  //   var groups = {};
  //   array.forEach( ( o ) =>  {
  //     var key = JSON.stringify( f(o) );
  //     groups[key] = groups[key] || [];
  //     groups[key].push( o );  
  //   });
  //   return Object.keys(groups).map( ( group ) => groups[group]); 
  // }


  // getShortKey(name) {
  //   let ix = 1;
  //   const ok = false;
  //   while (!ok) {
  //     const key = name.substr(0, 3 ).toUpperCase() + '-' + ix;
  //     const nameForKey = this.shortKeyMap[key];
  //     if (nameForKey == null) {
  //       this.shortKeyMap[key] = name;
  //       return key;
  //     }
  //     if (nameForKey === name) {
  //       return key;
  //     } else {
  //       ix++;
  //     }
  //   }
  // }

  // static magnitude(val: number) {
  //   const order = Math.floor(Math.log(val) / Math.LN10
  //                      + 0.000000001); // because float math sucks like that
  //   return Math.pow(10, order);
  // }

  // static fmtCurrencyKShort(amt: number) {
  //   if (amt >= 1E9) {
  //     const suffix = 'B';
  //     const formattedAmount = amt / 1E9;
  //     const decimalDigits = this.calcDigits(formattedAmount,suffix);
  //     return UtilFns.fmtCurrency(formattedAmount, decimalDigits) + suffix;
  //   } else if (amt >= 1E6) {
  //     const suffix = 'M';
  //     const formattedAmount = amt / 1E6;
  //     const decimalDigits = this.calcDigits(formattedAmount, suffix);
  //     return UtilFns.fmtCurrency(formattedAmount, decimalDigits) + suffix;
  //   } else {
  //     const suffix = 'K';
  //     const formattedAmount = amt / 1000;
  //     const decimalDigits = this.calcDigits(formattedAmount, suffix);
  //     return UtilFns.fmtCurrency(formattedAmount, decimalDigits) + suffix;
  //   }
  // }

  // private static calcDigits(amount: number, suffix: string): number {
  //   let decimalDigits = suffix === 'K' ? 2 : 1;
  //   if (amount >= 10 || amount <= -10) { decimalDigits = 1; }
  //   if (amount >= 100 || amount <= -100) { decimalDigits = 0; }
  //   if (decimalDigits === 2) {
  //     if ((amount * 100) % 10 === 0) {
  //       decimalDigits = 1;
  //     } else if ((amount * 10) % 10 === 0) {
  //       decimalDigits = 0;
  //     }
  //   }
  //   if (decimalDigits === 1) {
  //     if ((amount * 10) % 10 === 0) {
  //       decimalDigits = 0;
  //     }
  //   }
  //   return decimalDigits;
  // }

  // // will return an object with the same prop names as T but where the prop each return a string that is the name of the property
  // static fields<T>() {
  //   return new Proxy(
  //       {},
  //       {
  //           get: function (_target, prop, _receiver) {
  //               return prop;
  //           },
  //       }
  //   ) as {
  //       [P in keyof T]: P;
  //   };
  // };

}

// Consider this class for reflective access to nested field names - may with something like the fields method above. 
// export class EntityField {
//   constructor(public _name: string, public _parent?: EntityField) {
// }
//   getPath() {
//     if ( this._parent == null) {
//       return this._name;
//     } else {
//       return this._parent.getPath() + '.' + this._name;
//     }
//   }
// }
