
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, UtilDialogService } from '@core';
import { ProgramSupplier, Supplier } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { ToastrService } from 'ngx-toastr';
import { GridOptions } from '@ag-grid-community/core';
import * as _ from 'lodash';


@Component({
  selector: 'prox-program-suppliers-sub',
  templateUrl: './program-suppliers.sub.component.html'
})
export class ProgramSuppliersSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;

  suppliers: Supplier[] = [];
  programSuppliers: ProgramSupplier[] = [];
  selectedProgramSupplier?: ProgramSupplier;

  supplierGridOptions: GridOptions = {};
  supplierChecklist: { supplier: Supplier, isChecked: boolean }[] = [];

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  get manifests() {
    return this.program?.manifests;
  }

  async ngOnInit() {
    this.programSuppliers = this.program.programSuppliers;
    this.selectedProgramSupplier = _.first(this.programSuppliers);

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);
    // check for new suppliers
    this.programSuppliers = await this.parent.createMissingProgramSuppliers();
    AgFns.sizeColumnsToFit(this.supplierGridOptions);
  }

  

  isReadOnly() {
    return this.parent.isReadOnly();
  }
  

}
