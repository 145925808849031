/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Account,
  AccountAdmin,
  ProximityAreaEnum,
  ProximityRightEnum,
  ProximityUser,
  ActiveStatus,
  ActiveStatusEnum
} from '@models';
import { AccountDbQueryService } from './../services/account-db-query.service';
import { AccountDbSaveService } from './../services/account-db-save.service';
import { UtilFns } from '@utils';
import { EntityError } from 'breeze-client';
import { BaseService, SaveAndQueryComponent, StatusService } from '@core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-account-administrator-frm',
  templateUrl: './account-administrator-frm.component.html',
})
export class AccountAdminFrmComponent extends SaveAndQueryComponent {
  accountId?: string;
  account?: Account;
  accountName?: string;
  accountAdminId?: string;
  accountAdmin!: AccountAdmin;
  proximityUser!: ProximityUser;
  canEditRights!: boolean;
  activeStatuses: ActiveStatus[] = [];
  formTitle = "* New Administrator *";

  isBeingAdded!: boolean;
  areas = [ProximityAreaEnum.Accounts];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public pngDialogService: DialogService,
    private statusService: StatusService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }
  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    this.account = await this.dbQueryService.getAccountById(<string>this.accountId);
    this.accountAdminId = params['accountAdminId']

    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    UtilFns.assertNonNull(this.account, "Account");
    UtilFns.assertNonEmptyString(this.accountAdminId, "accountAdminId");

    this.isBeingAdded = this.accountAdminId === 'add';

    if (this.isBeingAdded) {
      [this.accountAdmin, this.proximityUser] = this.createAccountAdmin();
    } else {
      this.accountAdmin = await this.dbQueryService.getAccountAdminById(this.accountAdminId);
      this.proximityUser = this.accountAdmin?.proximityUser;
      this.formTitle = this.accountAdmin.proximityUser.fullName;
    }

    UtilFns.assertNonNull(this.authUser);
    this.canEditRights = this.authUser.hasRight(ProximityRightEnum.CanEditAccountRights);
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.setTitle("Account Administrator for " + this.account.name);
    this.isPageReady = true;
  }

  override get entity() { return this.accountAdmin };

  private createAccountAdmin() {
    const proximityUser = this.dbSaveService.createEntity(ProximityUser, { 
      activeStatusId: ActiveStatusEnum.Active }
    );

    const accountAdmin = this.dbSaveService.createEntity(AccountAdmin, {
      id: proximityUser.id,
      accountId: this.accountId,
      proximityUserId: proximityUser.id,
    });

    const entities =  [accountAdmin, proximityUser] as const;
    entities.forEach(e => {
      e.entityAspect.validateEntity();
      e.entityAspect.setUnchanged();
    })
    return entities;
  }

  statusMessage(): string {
    return <string>this.statusService.getWorkingStatus(this.accountAdmin as any).statusDisplay;
  }

  public isReadOnly() {
    return this.statusService.getWorkingStatus(this.accountAdmin as any).isReadOnly;
  }

  okToDelete() {
    return false;
  }

  onDelete(){
    //
  }

  isPrimaryAdmin(): boolean {
    return (this.accountAdmin.id === this.account?.primaryAccountAdminId);
  }

  /* async onChangeStatus() {
    const originalId =
      this.proximityUser.entityAspect?.originalValues['activeStatusId'] ??
      this.proximityUser.activeStatusId;
    const isAvailable = (s) => {
      if (
        s.id == (!ActiveStatusEnum.Active) &&
        this.proximityUser.entityAspect.entityState.isAdded()
      ) {
        return false;
      } else {
        return true;
      }
    };

    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: isAvailable,
      },
      {
        header: 'Proximity User Status',
      }
    );

    if (selectedStatusId != null) {
      this.proximityUser.activeStatusId = selectedStatusId;
    }
  }; */

  // --------------- Save & Undo -------------------------------

  override async addCrossValidationErrors() {
    const ok = await this.dbQueryService.checkIfIsUnique(this.proximityUser, 'id', 'username');
    if (!ok) {
      this.createValidationError(this.proximityUser, 'username', 'This Login Name has already been taken.');
    };

    if ((this.proximityUser.activeStatusId !== ActiveStatusEnum.Active) && (this.isPrimaryAdmin()))
    {
      this.createValidationError(this.proximityUser, 'username', "Account Primary Administrator must have Status = 'Active'.  To deactivate this Administrator, reassign a different Primary Administrator.");
    }
  }

  override async beforeSave() {
    if (this.isBeingAdded) {
      [this.accountAdmin, this.proximityUser].forEach( e => {
        e.entityAspect.setAdded();
      }) 
    };
    this.formTitle = this.accountAdmin.proximityUser.fullName;
    return true;
  }

  override async afterSave() {
    // no longer an added entity after a save.
    this.isBeingAdded = false;

    // if saving changes to yourself, reload the page so AuthUser gets the changes
    if (this.proximityUser.id == this.authUser?.id || this.proximityUser.id == this.authUser?.actingAsUser?.id) {
      location.reload();
    }
  }

  override async afterUndo() {
    if (this.isBeingAdded) {
      // previous entities were detached by the undo
      [this.accountAdmin, this.proximityUser] = this.createAccountAdmin();
    }
  }

  override navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    UtilFns.focusInputByEntity('#topLevel', errEnt, prop);  
  }
}
