// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProgramPurchaseOrderTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramPurchaseOrderType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Enum key */
  id!: ProgramPurchaseOrderTypeEnum;
  /** String(250) */
  description!: string;
  /** String(60) */
  name!: string;
}

