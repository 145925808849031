<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="flex flex-row">
    <div class="flex mb-2 mr-2">
      <button (click)="onAddMessage()" pButton type="button" label="Add Message" icon="pi pi-plus"
        class="p-button-sm"></button>
    </div>
    <div class="flex mb-2 mr-2">
      <button (click)="onAdd()" pButton type="button" label="Add Notification" icon="pi pi-plus"
        class="p-button-sm"></button>
    </div>
    <div class="flex mb-2">
      <button (click)="onAdd()" pButton type="button" label="Add Custom Action" icon="pi pi-plus"
        class="p-button-sm"></button>
    </div>
  </div>
  <div class="flex-grow-1 mt-2">
    <prox-ag-grid [gridOptions]="gridOptions" [rowData]="actions"></prox-ag-grid>
  </div>

</ng-template>