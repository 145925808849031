import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class BusyService {

  private _isBusy: boolean = false;
  isBusy() { 
    return this._isBusy; 
  }
  
  setIsBusy(isBusy: boolean) {
    this._isBusy = isBusy;
  }

  // handle nesting...
  // handle putting up wait indicator...
  async wrapBusy<T>(op: Promise<T> | (() => Promise<T>  ) ) {
    try {
      this._isBusy = true;
      if (typeof op === 'function') {
        return await op();
      }
      return await op;
    } finally {
      this._isBusy = false;
    }
  }


  // private busyIndicator = new BusyIndicator();
  // get isBusy() {
  //   return this.busyIndicator.isBusy;
  // }

  // busy<T>(op: Promise<T> | (() => Promise<T>), forceNew = true): Promise<T> {
  //   if (forceNew) this.busyIndicator.forceNew();
  //   return this.busyIndicator.busy(op);
  // }
  // enterBusy() {
  //   this.busyIndicator.enterBusy();
  // }
  // exitBusy() {
  //   this.busyIndicator.exitBusy();
  // }
  // newBusyIndicator(): BusyIndicator {
  //   return new BusyIndicator();
  // }

  // setBusy(val: boolean) {
  //   if (val) {
  //     this.enterBusy();
  //   } else {
  //     this.exitBusy();
  //   }
  // }
}
