/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {
  Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { AgFns, ISortModel, ProxAgFns } from '@core';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { BudgetProductTypeTag, IBudget, JobOrderBudgetLog } from '@models';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { ActivatedRoute } from '@angular/router';

export interface IBudgetWrapper {
  budget: IBudget,
  prevAmt: number,
  remainingAmt: number,
}

@Component({
  selector: 'prox-budget-items',
  templateUrl: './budget-items-list.component.html',
})
export class BudgetItemsListComponent implements OnInit, OnChanges {
  @Input() accountId!: string;
  @Input() budgets!: IBudget[];
  @Input() joBudgetLogs!: JobOrderBudgetLog[] ;
  @Input() addLabel!: string;
  @Input() createEntityFn!: () => IBudget;
  @Input() isProgramBudget?: boolean;
  //@Input() canEdit!: boolean;
  @Input() isReadOnly!: boolean;
  
  budgetGridOptions!: GridOptions;
  budgetWrappers: IBudgetWrapper[] = [];
  budgetProductTypeTags: BudgetProductTypeTag[] = [];
  budgetLogGroupMap!: any;
  isPageReady = false;
  
  constructor(
    route: ActivatedRoute,
    private dbQueryService: AccountDbQueryService
   ) {
  }

  // TODO: need a way to poke this - to refresh budgets data ( needed after an undo)

  async ngOnInit() {
    //this.canEdit = this.canEdit == null ? true : this.canEdit;
    this.isReadOnly = this.isReadOnly == null ? false : this.isReadOnly;
    this.budgetGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onBudgetGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const wrapper = rowIdParams.data as IBudgetWrapper; 
        return wrapper.budget.id;
      }, 
    }, { shouldSizeToFit: true });

    this.budgetProductTypeTags =  await this.dbQueryService.getBudgetProductTypeTags(this.accountId);
    this.budgetProductTypeTags.unshift( { id: null as any , name: '- Any -'} as BudgetProductTypeTag);
    this.isPageReady = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['budgets'] || changes['joBudgetLogs']) {
        this.refreshBudgets();
      }
  }

  refreshBudgets() {
    this.budgetLogGroupMap = _.groupBy(this.joBudgetLogs, x => x.budgetProductTypeTagId || 'All');  
    this.budgetWrappers = this.budgets.map(b => {
      const prevBudgetLogs = this.budgetLogGroupMap[b.budgetProductTypeTagId ?? 'All'] ?? [];
      const prevAmt = _.sumBy(prevBudgetLogs, x => (x as any).amt);
      const remainingAmt = b.budgetAmt - prevAmt;
      return {
        budget: b,
        prevAmt: prevAmt,
        remainingAmt: remainingAmt
      } as IBudgetWrapper;
    });

    AgFns.refreshGrid(this.budgetGridOptions, this.budgetWrappers);
    AgFns.sizeColumnsToFit(this.budgetGridOptions);
    AgFns.selectDefaultRow(this.budgetGridOptions);
  }

  async onBudgetGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getBudgetColDefsAndSortModel();
    AgFns.updateColDefs(colDefs);
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getBudgetColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      //this.canEdit ? ProxAgFns.getEntityStateColDef( (item: IBudgetWrapper) => item.budget) : {} ,
      this.isReadOnly ? {} : ProxAgFns.getEntityStateColDef( (item: IBudgetWrapper) => item.budget),
      { 
        headerName: 'Budget Product Tag', 
        editable: !this.isReadOnly, width: 200, filter: 'agTextColumnFilter', 
        ...AgFns.createDropdownEditor('budget.budgetProductTypeTagId', this.budgetProductTypeTags),
      },
      
      { headerName: 'Amount', field: 'budget.budgetAmt', editable: !this.isReadOnly,  filter: 'agNumberColumnFilter',
      },
      { headerName: 'Amount Used', field: 'prevAmt', editable: false },
      // { headerName: 'Amount Remaining', field: 'remainingAmt',  editable: false },
      { headerName: 'Amount Remaining', colId: 'remainingAmt', editable: false,
        valueGetter: (params) => {
          const x = params.data as IBudgetWrapper;
          if (x == null) return;
          return x.budget.budgetAmt - x.prevAmt;
        }
      },
      { headerName: 'Warning at x% of total', field: 'budget.warnIfWithinRate', editable: !this.isReadOnly, },
        // valueFormatter: params => UtilFns.fmtPct(params.data) },
      { headerName: 'Fiscal Year Start Date', field: 'budget.fiscalStartDate', hide: this.isProgramBudget, editable: false },
      
      this.isReadOnly ? {} : ProxAgFns.getEntityDeleteColDef(this.onBudgetDelete.bind(this)),
    ];
    
    const sortModel: ISortModel = [
      { colId: 'budget.budgetProductTypeTagId', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onBudgetAdd() {
    // disabled button still performs actions (??!?)
    if (this.isReadOnly) {
      return;
    }

    const budget = this.createEntityFn();
    budget.entityAspect.validateEntity();
    const budgetWrapper = {
      budget: budget,
      prevAmt: 0,
      remainingAmt: budget.budgetAmt
    } as IBudgetWrapper;
    this.budgetWrappers.push(budgetWrapper);
    
    AgFns.refreshGrid(this.budgetGridOptions, this.budgetWrappers);
    AgFns.selectGridRowByKey(this.budgetGridOptions, (e: IBudgetWrapper) => e.budget.id , budget.id, 'budgetProductTypeTagId');
    
  }

  async onBudgetDelete(wrapper: IBudgetWrapper) {
    EntityFns.deleteOrDetach(wrapper.budget.entityAspect);
    _.remove(this.budgetWrappers, wrapper);
    AgFns.refreshGrid(this.budgetGridOptions, this.budgetWrappers);
  }

  public refreshGrid() {
    AgFns.refreshGrid(this.budgetGridOptions, this.budgetWrappers);
  }
}
