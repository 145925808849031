<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>
  <h5 class="card-header">
    <div class="flex flex-row">
      <span>{{formTitle}}</span>
      <span  *ngIf="!isBeingAdded" style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </h5>
  <div class="card-body flex-grow-1 flex flex-column" *ngIf="isPageReady">
    <prox-user-frm [proximityUser]="proximityUser!" [isBeingAdded]="isBeingAdded" [isReadOnly]="isReadOnly()"></prox-user-frm>
    <div class="flex-grow-1">
      <prox-user-rights-frm [proximityUser]="proximityUser!" [areas]="areas" [dbQueryService]="dbQueryService"
        [isReadOnly]="!canEditRights || isReadOnly()"></prox-user-rights-frm>
    </div>
  </div>
  <prox-save-cancel-delete [uow]="this" [canDelete]="okToDelete()" (delete)="onDelete()" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel-delete>
</ng-template>