<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [showBack]="true"></prox-main>

<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <div class="flex flex-row">
    <div class="flex mb-2">
      <button (click)="onAdd()" pButton type="button" label="Add User" icon="pi pi-plus" class="p-button-sm" [disabled]="isReadOnly()"></button>
    </div>
    <div class="flex ml-2 mb-2">
      <p-button (click)="onUsersImport()" styleClass="p-button p-button-sm" label="Import Users..." icon="pi pi-plus"
        class="p-button-sm" [disabled]="isReadOnly()"></p-button>
      <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>  
    </div>
  </div>
  <div class="py-2 w-full h-full">
    <prox-ag-grid [gridOptions]="auGridOptions" [rowData]="accountUsers"></prox-ag-grid>
  </div>

  <!-- <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
    <ng-template pTemplate>
      
    </ng-template>

    <ng-template pTemplate>
      
    </ng-template>
  </p-splitter> -->
</ng-template>