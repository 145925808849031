
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProxAgFns, UtilDialogService } from '@core';
import { PricedProductType, ProgramProductCategoryTag, ProgramProductCategoryTagMap, ProgramProductTypeTag, ProgramProductTypeTagMap } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import { ProgramProductTypeTagFinderDialog } from './program-product-type-tag-finder.dialog';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { UtilFns } from '@utils';


@Component({
  selector: 'prox-program-product-type-tags-sub',
  templateUrl: './program-product-type-tags.sub.component.html',
})
export class ProgramProductTypeTagsSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;
        
  savedRefreshId?: number;
  
  ppttGridOptions!: GridOptions;
  pptts?: ProgramProductTypeTag[];
  selectedPptt?: ProgramProductTypeTag;

  ppctGridOptions!: GridOptions;
  ppcts?: ProgramProductCategoryTag[];
  selectedPpct?: ProgramProductCategoryTag;

  tmapGridOptions!: GridOptions;
  tmaps: ProgramProductTypeTagMap[] = []; 
  availablePricedProductTypes!: Set<PricedProductType>;
  buttonProps = { calcDisabled: () => this.parent.isReadOnly() }

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService) {
    //
  }

  get program() {
    return this.parent.program;
  }

  async ngOnInit() {

    this.ppttGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPpttGridReady,
      onRowSelected: this.onPpttRowSelected,
    });

    this.ppctGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPpctGridReady,
    }, { detailProperty: 'programProductCategoryTagMaps'});

    this.tmapGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onTmapGridReady,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data as ProgramProductTypeTagMap;
        return x.pricedProductTypeId;
      },
    });

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh(true);
  }

  async refresh(forceRefresh= false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    this.availablePricedProductTypes = new Set(this.program.programProductTypeConfigs.map(x => x.productTypeConfig.pricedProductType));

    this.pptts = this.parent.pptts;

/*     this.pptts.unshift({
      id: UtilFns.EmptyGuid,
      name: '( No tags )',
    } as unknown as ProgramProductTypeTag); */

    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programProductCategoryTags");
    if (isLoaded && !forceRefresh) {
      this.ppcts = this.program.programProductCategoryTags;
    } else {
      this.ppcts = await this.dbQueryService.getProgramProductCategoryTags(this.program.id);
      this.program.entityAspect.markNavigationPropertyAsLoaded('programProductCategoryTags');
      AgFns.refreshGrid(this.ppctGridOptions, this.ppcts);
    }
   
    AgFns.sizeColumnsToFit(this.ppttGridOptions);
    AgFns.sizeColumnsToFit(this.ppctGridOptions);

    // This is needed if the forceRefresh is as a result of an Undo operation.
    if (forceRefresh) {
      this.ppttGridOptions.api?.deselectAll();
      this.ppctGridOptions.api?.deselectAll();
    }
    AgFns.selectDefaultRow(this.ppttGridOptions);
  }
 

  onPpttGridReady() {
    const [colDefs, sortModel] = this.getPpttColDefsAndSortModel();
    AgFns.initGrid(this.ppttGridOptions, colDefs, sortModel);
    AgFns.refreshGrid(this.ppttGridOptions, this.pptts);
    
  }

  getPpttColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Name', field: 'name', editable: false, filter: 'agTextColumnFilter' },
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onPpttRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const pptt = event.data as ProgramProductTypeTag;
    this.selectedPptt = pptt;
    if (pptt == null) return;

    if (pptt.id !== UtilFns.EmptyGuid && pptt.entityAspect.entityState.isDeleted()) {
      this.selectedPptt = undefined;
      this.ppttGridOptions.api?.deselectAll();
      return;
    }

    this.tmaps = await this.dbQueryService.getProgramProductTypeTagMapsForTag(pptt.id);
    this.tmaps = this.tmaps.filter(x => this.availablePricedProductTypes.has(x.pricedProductType));

    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
  }

  // ----- Apct grid -------------------------------

  onPpctGridReady() {
    const [colDefs, sortModel] = this.getPpctColDefsAndSortModel();
    this.updatePpctMasterDetail(this.ppctGridOptions);
    AgFns.initGrid(this.ppctGridOptions, colDefs, sortModel);
  }

  getPpctColDefsAndSortModel() {
    
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Name', field: 'name', editable: !this.parent.isReadOnly(), filter: 'agTextColumnFilter',  cellRenderer: 'agGroupCellRenderer'  },
      this.parent.ifEditable({ ...AgFns.createButtonProps('', this.onTagAttach.bind(this),  { label: 'Attach Tag', ...this.buttonProps }), minWidth: 150, maxWidth: 150 }),
      this.parent.ifEditable( ProxAgFns.getEntityDeleteColDef(this.onPpctDelete.bind(this), this.buttonProps ))
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  updatePpctMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Tag Name', field: 'programProductTypeTag.name', filter: 'agTextColumnFilter',  },
      this.parent.ifEditable({ ...AgFns.createButtonProps('', this.onTagDetach.bind(this), { label: 'Detach Tag', ...this.buttonProps }), minWidth: 150, maxWidth: 150 } ),
    ];
    detailGridOptions.getRowId = (rowIdParams: GetRowIdParams) => {
      const x = rowIdParams.data as ProgramProductCategoryTagMap;
      return x.programProductTypeTagId;
    }
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',
      detailRowAutoHeight: true,
      getDetailRowData: params => {
        const apct = params.data as ProgramProductCategoryTag;
        params.successCallback(apct.programProductCategoryTagMaps);
      },
    }
  }

  async onPpctAdd() {
    const apct = this.dbSaveService.createEntity(ProgramProductCategoryTag, { programId: this.program.id });
    apct.entityAspect.validateEntity();
    this.ppcts?.push(apct);
    AgFns.refreshGrid(this.ppctGridOptions, this.ppcts); 

  }

  async onPpctDelete(ppct: ProgramProductCategoryTag) {
    // if it's an added record - cascade delete won't catch them.
    if (ppct.entityAspect.entityState.isAdded()) {
      ppct.programProductCategoryTagMaps.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(ppct.entityAspect);

    if (this.ppcts) {
      _.remove(this.ppcts, ppct);
      AgFns.refreshGrid(this.ppctGridOptions, this.ppcts);
      AgFns.refreshGrid(this.ppctGridOptions.detailCellRendererParams?.detailGridOptions);
    }
  }

  async onTagAttach(row: ProgramProductCategoryTag) {
    if (this.pptts == null) return;
    const attachedPpttIds = new Set(row.programProductCategoryTagMaps.map(x => x.programProductTypeTagId));
    const missingPptts = this.pptts.filter(x => !attachedPpttIds.has(x.id));
    const attachPptts = await this.dialogService.createFinder(ProgramProductTypeTagFinderDialog, {
      pptts: missingPptts, rowSelection: 'multiple',
    });
    attachPptts.forEach(aptt => {
      if (!this.dbSaveService.uow.undoIfDeleted(ProgramProductCategoryTagMap, [row.id, aptt.id])) {
        this.dbSaveService.createEntity(ProgramProductCategoryTagMap, {
          programProductCategoryTagId: row.id,
          programProductTypeTagId: aptt.id
        });
      }
    }); 
    // HACK - AgGrid issue - complete hack - needed to show expando on grid  if row doesn't already have it.  
    AgFns.refreshGrid(this.ppctGridOptions, []) ;
    AgFns.refreshGrid(this.ppctGridOptions, this.ppcts)    ;
    const rowNode = this.ppctGridOptions.api?.getRowNode(row.id);
    rowNode?.setExpanded(true);
    AgFns.refreshDetailRowHeights(this.ppctGridOptions);
  }

  async onTagDetach(row: ProgramProductCategoryTagMap) {
    if (this.ppcts == null) return;
    const rowNode = this.ppctGridOptions.api?.getRowNode(row.programProductCategoryTag.id);
    EntityFns.deleteOrDetach(row.entityAspect);

    AgFns.refreshGrid(this.ppctGridOptions, this.ppcts);
    rowNode?.setExpanded(true);

    AgFns.refreshDetailRowHeights(this.ppctGridOptions);


  }

  // ------------- Tmap grid --------------------------

  onTmapGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getTmapColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getTmapColDefsAndSortModel() {
    const ptypePath = 'pricedProductType.productType'
    const colDefs: ColDef[] = [
      { headerName: 'Supplier', field: ptypePath + '.supplier.name', filter: 'agTextColumnFilter', },
      { headerName: 'Manufacturer', field: ptypePath + '.manufacturer.name', filter: 'agTextColumnFilter', },
      { headerName: 'SKU', field: ptypePath + '.productKey', filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: ptypePath + '.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: ptypePath + '.description', filter: 'agTextColumnFilter' },
      { headerName: 'Base Price', field: 'pricedProductType.basePrice' },
    ];
    const sortModel: ISortModel = [
      { colId: ptypePath + '.supplier.name', sort: 'asc' },
      { colId: ptypePath + '.manufacturer.name', sort: 'asc' },
      { colId: ptypePath + 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  
}
