<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Budgets</h5>
  </div>
  <div class="h-full flex flex-column ">
    <div class="flex-grow-1">
      <prox-budget-items [isReadOnly]="parent.isReadOnly()" [accountId]="program.accountId" [budgets]="programBudgets" [joBudgetLogs]="joBudgetLogs" [isProgramBudget]="true" addLabel="Add Budget Items"
        [createEntityFn]="createProgramBudget.bind(this)" [isReadOnly]="parent.isReadOnly()"></prox-budget-items>
    </div>
  </div>
</div>
