<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [showBack]="true"></prox-main>
<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <p-dialog header="Please wait..." [(visible)]="importRunning" [modal]="true" [style]="{ width: '50vw' }"
    [draggable]="false" [resizable]="false" [closable]="false">
    <p class="m-0">
      Import process is running...
    </p>
  </p-dialog>
  <div class="flex flex-row">
    <div class="flex mb-2">
      <button (click)="getTemplate()" pButton type="button" label="Download Template" icon="fa-solid fa-download"
        class="p-button-sm"></button>
    </div>
    <div class="flex ml-2 mb-2">
      <p-fileUpload mode="basic" name="file[]" class="p-button-sm ml-2" chooseLabel="Upload Account Users"
        [customUpload]="true" accept="{{acceptedFiles}}"
        invalidFileTypeMessageDetail="Allowed file types: {{acceptedFiles}}" [maxFileSize]="1000000" [auto]="true"
        (uploadHandler)="onLoad($event)"></p-fileUpload>
    </div>
    <div class="flex ml-2 mb-2">
      <button (click)="onSubmit()" [disabled]="submitDisabled()" pButton type="button" label="Submit"
        icon="fa-solid fa-upload" class="p-button-sm ml-2"></button>
    </div>
  </div>
  <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="gridOptions" [rowData]="importUsers" [rowClassRules]="rowClassRules">
        </prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate *ngIf="includeCodes">
      <p-splitter [panelSizes]="[50, 50]" class="w-full pb-7" styleClass="h-full">
        <ng-template pTemplate>
          <div class="flex-column w-full h-full">
            <div class="flex pb-2">
              <div class="pt-2 mb-1 pr-3 pl-2" style="font-weight: bold;">
                <label>Attach Shipping Groups to Codes</label>
              </div>
            </div>
            <prox-ag-grid [gridOptions]="shippingCodesGridOptions" [rowData]="shippingCodes">
            </prox-ag-grid>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <div class="flex-column pr-1 w-full h-full">
            <div class="flex pb-2">
              <div class="pt-2 mb-1 pr-3 pl-2" style="font-weight: bold;">
                <label>Attach Program Groups to Codes</label>
              </div>
            </div>
            <prox-ag-grid [gridOptions]="programCodesGridOptions" [rowData]="programCodes">
            </prox-ag-grid>
          </div>
        </ng-template>
      </p-splitter>
    </ng-template>
  </p-splitter>
</ng-template>