<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <h5 class="card-header">
    <div class="flex flex-row">
      <span>{{addressTypeName}}</span>
      <span  *ngIf="!isBeingAdded" style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </h5>
  <div *ngIf="isPageReady" class="flex flex-column flex-grow-1">

    <div class="card-body w-12 pb-3 pr-3">
      <prox-field label="Address Description">
        <input type="text" pInputText autocomplete="off" [(ngModel)]="accountAddress.description"
          [bzModel]="accountAddress" name="description" [disabled]="isReadOnly()">
      </prox-field>
    </div>
    <prox-address-frm [address]="accountAddress" [disabled]="isReadOnly()"></prox-address-frm>

    <div class="flex mt-4">
      <div class="w-4">
        <p-checkbox [(ngModel)]="accountAddress.isBillingAddress" [binary]="true"  [bzModel]="accountAddress"
          name="isBillingAddress" label="Is Billing Address" [disabled]="this.addressType==='B' || isReadOnly()"></p-checkbox>
      </div>
      <div class="w-4">
        <p-checkbox [(ngModel)]="accountAddress.isShippingAddress" [binary]="true"  [bzModel]="accountAddress"
          name="isShippingAddress" label="Is Shipping Address" [disabled]="this.addressType==='S' || isReadOnly()"></p-checkbox>
      </div>
      <!-- <div class="w-4">
        <p-checkbox [(ngModel)]="accountAddress.isPersonalAddress" [binary]="true" [disabled]="isDisabled('P')" [bzModel]="accountAddress"
          name="isPersonalAddress" label="Is Personal Address"></p-checkbox>
      </div> -->
    </div>
  </div>

  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>

</ng-template>