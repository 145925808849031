<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Issuance Limits</h5>
  </div>
  <div class="h-full flex flex-column ">
    <div class="flex flex-row gap-2">
      <div class="w-4 pr-4">
        <prox-field label="On Issuance Limit Violation">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="programViolationRules" optionLabel="description"
            optionValue="id" [(ngModel)]="program.issuanceProgramViolationRuleId" [bzModel]="program"
            name="issuanceProgramViolationRuleId"></p-dropdown>
        </prox-field>
      </div>
    </div>
    <div class="flex flex-grow-1">
      <p-tabView class="pt-3 flex-grow-1 w-full prox-tabview" [(activeIndex)]="tabViewIndex"
        (onChange)="onTabChange($event)">
        <p-tabPanel header="Program Issuance Limits">
          <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
            <ng-template pTemplate>
              <div class="flex flex-column w-full h-full">
                <div class="w-2 pb-3">
                  <button [disabled]="parent.isReadOnly()" (click)="onPiAdd()" pButton type="button"
                    label="Add Program Issuance Limit" icon="pi pi-plus" class="p-button-sm"></button>
                </div>
                <div class="flex-grow-1">
                  <prox-ag-grid [gridOptions]="piGridOptions" [rowData]="programIssuances"></prox-ag-grid>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate>
              <div class="flex-grow-1">
                <prox-ag-grid [gridOptions]="piPugGridOptions" [rowData]="availablePiPugs"></prox-ag-grid>
              </div>
            </ng-template>
          </p-splitter>
        </p-tabPanel>
        <p-tabPanel header="Account Issuance Limits">
          <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
            <ng-template pTemplate>

              <div class="flex flex-column w-full h-full">
                <div class="w-2 pb-3">
                  <button [disabled]="parent.isReadOnly()" (click)="onAiAdd()" pButton type="button"
                    label="Add Account Issuance Limit" icon="pi pi-plus" class="p-button-sm"></button>
                </div>
                <div class="flex-grow-1">
                  <prox-ag-grid [gridOptions]="aiGridOptions" [rowData]="accountIssuances"></prox-ag-grid>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate>
              <div class="flex-grow-1">
                <prox-ag-grid [gridOptions]="aiPugGridOptions" [rowData]="availableAiPugs"></prox-ag-grid>
              </div>
            </ng-template>
          </p-splitter>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>