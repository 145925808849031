<prox-main  [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="flex-grow-1" *ngIf="isPageReady">
    <div class="pt-2 pb-2 w-full h-full">
      <prox-ag-grid [gridOptions]="gridOptions" [rowData]="eventSummaries"></prox-ag-grid>
    </div>
  </div>
  <!-- <p-confirmDialog></p-confirmDialog> -->
</ng-template>

<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>