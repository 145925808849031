// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAddress } from './account-address';
import { AccountAdmin } from './account-admin';
import { AccountAdminGroup } from './account-admin-group';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { AccountBudget } from './account-budget';
import { AccountImage } from './account-image';
import { AccountImport } from './account-import';
import { AccountImportCodeOption } from './account-import-code-option';
import { AccountIssuance } from './account-issuance';
import { AccountUser } from './account-user';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { ApprovalTree } from './approval-tree';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { PricedAddon } from './priced-addon';
import { Program } from './program';
import { ProgramProductTypeTag } from './program-product-type-tag';
import { ProgramUserGroup } from './program-user-group';
import { ShippingUserGroup } from './shipping-user-group';
import { SupplierAccount } from './supplier-account';
import { AccountImportCodeOptionEnum } from './enums';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { DateFns } from '@utils';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Account extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getCurrentYearFiscalStartDate() {
    return DateFns.getFiscalStartDate(new Date(), this.fiscalMonthDayStartDate);
  }

  getStatusParents() {
    return [null];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK accountImportCodeOption */
  accountImportCodeOptionId?: AccountImportCodeOptionEnum;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(20) */
  extension?: string;
  /** DateTime */
  fiscalMonthDayStartDate!: Date;
  /** String(60) */
  name!: string;
  /** Guid FK primaryAccountAdmin */
  primaryAccountAdminId?: string;
  /** Binary */
  rowVersion!: any;
  /** DateTime */
  startDate!: Date;
  /** String(50) */
  telephoneNumber?: string;
  /** Boolean */
  wasPopulatedFromUniformax?: boolean;
  /** Inv FK accountId */
  accountAddresses!: AccountAddress[];
  /** Inv FK accountId */
  accountAdminGroups!: AccountAdminGroup[];
  /** Inv FK accountId */
  accountAdmins!: AccountAdmin[];
  /** Inv FK accountId */
  accountBlanketPurchaseOrders!: AccountBlanketPurchaseOrder[];
  /** Inv FK accountId */
  accountBudgets!: AccountBudget[];
  /** Inv FK accountId */
  accountImages!: AccountImage[];
  /** FK accountImportCodeOptionId */
  accountImportCodeOption!: AccountImportCodeOption;
  /** Inv FK accountId */
  accountImports!: AccountImport[];
  /** Inv FK accountId */
  accountIssuances!: AccountIssuance[];
  /** Inv FK accountId */
  accountUsers!: AccountUser[];
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK accountId */
  addons!: Addon[];
  /** Inv FK accountId */
  approvalTrees!: ApprovalTree[];
  /** Inv FK accountId */
  budgetProductTypeTags!: BudgetProductTypeTag[];
  /** Inv FK accountId */
  pricedAddons!: PricedAddon[];
  /** FK primaryAccountAdminId */
  primaryAccountAdmin!: AccountAdmin;
  /** Inv FK accountId */
  programProductTypeTags!: ProgramProductTypeTag[];
  /** Inv FK accountId */
  programUserGroups!: ProgramUserGroup[];
  /** Inv FK accountId */
  programs!: Program[];
  /** Inv FK accountId */
  shippingUserGroups!: ShippingUserGroup[];
  /** Inv FK accountId */
  supplierAccounts!: SupplierAccount[];
}

