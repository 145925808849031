<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>

<ng-template #bodyTemplate>
  <h5 class="card-header">
    <div class="flex flex-row">
      <span>{{formTitle}}</span>
      <span  *ngIf="!isBeingAdded" style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </h5>
  <div class="flex-grow-1">
    <div class="flex flex-column h-full w-full">
      <prox-user-frm [proximityUser]="proximityUser!" [genderModel]="accountUser" [genders]="genders"
        [isBeingAdded]="isBeingAdded" [isReadOnly]="isReadOnly()">
      </prox-user-frm>

      <div class="flex">
        <div class="w-3 pr-3">
          <prox-field label="New Hire Expiration Date (Req'd for New User Programs)">
            <p-calendar [(ngModel)]="accountUser.newHireUntilDate" [bzModel]="accountUser" name="newHireUntilDate" [disabled]="isReadOnly()"></p-calendar>
          </prox-field>
        </div>
      </div> 

      <p-tabView class="pt-3 flex-grow-1 w-full prox-tabview">
        <p-tabPanel header="Shipping User Groups">
          <div class="h-full flex flex-column w-full">
            <div class="mb-2" >
              <button (click)="onSugmAttach()" pButton type="button" label="Attach Shipping User Groups" icon="pi pi-plus"
                  class="p-button-sm" [disabled]="isReadOnly()"></button>
            </div>
            <div class="flex flex-grow-1 w-full">
              <prox-ag-grid class="w-full" [gridOptions]="sugmGridOptions" [rowData]="sugms"></prox-ag-grid>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Program User Group">
          <div class="h-full flex flex-column w-full">
            <div class="mb-2" >
              <button (click)="onPugmAttach()" pButton type="button" label="Attach Program User Group" icon="pi pi-plus"
                  class="p-button-sm" [disabled]="isReadOnly()"></button>
            </div>
            <div class="flex flex-grow-1 w-full">
              <prox-ag-grid class="w-full" [gridOptions]="pugmGridOptions" [rowData]="pugms"></prox-ag-grid>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Personal Shipping Address">
          <div class="h-full flex flex-column ">
            <div *ngIf="!accountUser.shippingAccountAddressId" >
              <button (click)="onAddAddress('S')" pButton type="button" label="Add Shipping Address" icon="pi pi-plus"
                  class="p-button-sm" [disabled]="isReadOnly()"></button>
            </div>
            <div *ngIf="accountUser.shippingAccountAddressId">
              <prox-address-frm  [address]="accountUser!.shippingAccountAddress" [disabled]="isReadOnly()"></prox-address-frm>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Personal Billing Address">
          <div class="h-full flex flex-column ">
            <div *ngIf="!accountUser.billingAccountAddressId" >
              <button (click)="onAddAddress('B')" pButton type="button" label="Add Billing Address" icon="pi pi-plus"
                  class="p-button-sm" [disabled]="isReadOnly()"></button>
            </div>
            <prox-address-frm [address]="accountUser!.billingAccountAddress" [disabled]="isReadOnly()"></prox-address-frm>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>