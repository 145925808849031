// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountAddress } from './account-address';
import { AccountAdmin } from './account-admin';
import { AccountAdminGroup } from './account-admin-group';
import { AccountUser } from './account-user';
import { Invoice } from './invoice';
import { JobOrderDetail } from './job-order-detail';
import { JobOrderHistDetail } from './job-order-hist-detail';
import { JobOrderNote } from './job-order-note';
import { JobOrderOveragePayment } from './job-order-overage-payment';
import { JobOrderStatus } from './job-order-status';
import { JobOrderType } from './job-order-type';
import { Program } from './program';
import { ReturnRequest } from './return-request';
import { ShipFrequency } from './ship-frequency';
import { JobOrderStatusEnum } from './enums';
import { JobOrderTypeEnum } from './enums';
import { ShipFrequencyEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { JobOrderDetailPriceInfo } from '@core';
import * as _ from 'lodash';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrder extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getTotalAmt() {
    const jodPriceInfos = this.jobOrderDetails.map(jod => jod.getPriceInfo()) as JobOrderDetailPriceInfo[];
    const amt = _.sum(jodPriceInfos.map(x => x.extendedPrice)) ?? 0;
    return amt;
  }

  isReturnOrRepairable(): boolean {
    let ok = false;
    this.invoices.forEach (p => {
      if (p.isReturnOrRepairable()) {
        ok = true;
      }
    });
    return ok;
  }

/*   isAllowReturnRepair(): number {
    let maxReturnDays = 0;
    let maxRepairDays = 0;
    this.jobOrderDetails.forEach(i => {
      if ((i.productTypeConfigProduct.productTypeConfig?.returnPolicy) && 
          (i.productTypeConfigProduct.productTypeConfig?.returnPolicy.allowReturn)) {
        if (<number>i.productTypeConfigProduct.productTypeConfig?.returnPolicy.returnDays > maxReturnDays) {
          maxReturnDays = <number>i.productTypeConfigProduct.productTypeConfig?.returnPolicy.returnDays;
        }
      }

      if ((i.productTypeConfigProduct.productTypeConfig?.returnPolicy) && 
          (i.productTypeConfigProduct.productTypeConfig?.returnPolicy.allowRepair)) {
        if (<number>i.productTypeConfigProduct.productTypeConfig?.returnPolicy.repairDays > maxRepairDays) {
          maxRepairDays = <number>i.productTypeConfigProduct.productTypeConfig?.returnPolicy.repairDays;
        }
      }
    });
    return Math.max(maxReturnDays, maxRepairDays);
  } */
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountAdminGroup */
  accountAdminGroupId?: string;
  /** Guid FK account */
  accountId!: string;
  /** Guid FK accountUser */
  accountUserId?: string;
  /** Guid FK approvedByAccountAdmin */
  approvedByAccountAdminId?: string;
  /** Guid FK billingAccountAddress */
  billingAccountAddressId!: string;
  /** Int32 */
  displayId!: number;
  /** Boolean */
  doesAccountPayFreight!: boolean;
  /** Enum FK jobOrderStatus */
  jobOrderStatusId!: JobOrderStatusEnum;
  /** Enum FK jobOrderType */
  jobOrderTypeId!: JobOrderTypeEnum;
  /** DateTimeOffset */
  orderCreatedTs!: Date;
  /** DateTimeOffset */
  orderPlacedTs?: Date;
  /** Decimal */
  prepaymentAmt?: number;
  /** Guid FK program */
  programId!: string;
  /** String */
  purchaseOrder?: string;
  /** Binary */
  rowVersion!: any;
  /** Enum FK shipFrequency */
  shipFrequencyId!: ShipFrequencyEnum;
  /** Guid FK shippingAccountAddress */
  shippingAccountAddressId?: string;
  /** FK accountId */
  account!: Account;
  /** FK accountAdminGroupId */
  accountAdminGroup!: AccountAdminGroup;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** FK approvedByAccountAdminId */
  approvedByAccountAdmin!: AccountAdmin;
  /** FK billingAccountAddressId */
  billingAccountAddress!: AccountAddress;
  /** Inv FK jobOrderId */
  invoices!: Invoice[];
  /** Inv FK jobOrderId */
  jobOrderDetails!: JobOrderDetail[];
  /** Inv FK jobOrderId */
  jobOrderHistDetails!: JobOrderHistDetail[];
  /** Inv FK jobOrderId */
  jobOrderNotes!: JobOrderNote[];
  /** Inv FK jobOrderId */
  jobOrderOveragePayments!: JobOrderOveragePayment[];
  /** FK jobOrderStatusId */
  jobOrderStatus!: JobOrderStatus;
  /** FK jobOrderTypeId */
  jobOrderType!: JobOrderType;
  /** FK programId */
  program!: Program;
  /** Inv FK jobOrderId */
  returnRequests!: ReturnRequest[];
  /** FK shipFrequencyId */
  shipFrequency!: ShipFrequency;
  /** FK shippingAccountAddressId */
  shippingAccountAddress!: AccountAddress;
}

