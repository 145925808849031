/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, EditableCallbackParams, GetRowIdParams, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, PricedProductTypeFinderDialog, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';
import { Account, ProgramProductTypeTag, ProgramProductTypeTagMap,  } from '@models';
import { BaseService } from '@core';
import { UtilFns } from '@utils';
import * as _ from 'lodash';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { EntityFns } from '@data';

@Component({
  selector: 'prox-program-product-type-tags',
  templateUrl: './program-product-type-tags.component.html',
})
export class ProgramProductTypeTagsComponent extends SaveAndQueryComponent {
  accountId!: string;
  account!: Account;
  tagGridOptions!: GridOptions;
  tags?: ProgramProductTypeTag[];
  selectedTag?: ProgramProductTypeTag;
  tmapGridOptions!: GridOptions;
  tmaps: ProgramProductTypeTagMap[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.tagGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onTagGridReady,
      onRowSelected: this.onTagRowSelected,
    });
    AgFns.captureGridRouteParams(this.tagGridOptions, this.route, 'id');

    this.tmapGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onTmapGridReady,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data as ProgramProductTypeTagMap;
        return x.pricedProductTypeId;
      },
    });
    
    this.tags = await this.dbQueryService.getProgramProductTypeTagsForAccount(this.accountId);
    this.setTitle('Account Product Tags for ' + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  // Tag Grid

  onTagGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getTagColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getTagColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Name', field: 'name', editable: !this.isReadOnly(), filter: 'agTextColumnFilter' },
      ProxAgFns.getEntityDeleteColDef(this.onTagDelete.bind(this),  { canDisplay: this.canDelete.bind(this)})
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onTagRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const tag = event.data as ProgramProductTypeTag;
    this.selectedTag = tag;
    if (tag == null) return;

    if (tag.entityAspect.entityState.isDeleted()) {
      this.selectedTag = undefined;
      this.tagGridOptions.api?.deselectAll();
      return;
    }
    this.tmaps = await this.dbQueryService.getProgramProductTypeTagMapsForTag(tag.id);
    if (tag['_tmpCanDelete'] == null) {
      tag['_tmpCanDelete'] = ! await this.dbQueryService.isProgramProductTypeTagUsed(this.selectedTag?.id || '');
    }

    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    this.updateGridRouteParams(this.tagGridOptions, tag.id);
  }

  async onTagAdd() {
    const tag = this.dbSaveService.createEntity(ProgramProductTypeTag, { accountId: this.accountId });
    tag.entityAspect.validateEntity();
    this.tags?.push(tag);
    this.tmaps = [];
    AgFns.refreshGrid(this.tagGridOptions, this.tags);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    AgFns.selectGridRowByKey(this.tagGridOptions, (e: ProgramProductTypeTag) => e.id, tag.id, 'name');
  }

  async onTagDelete(tag: ProgramProductTypeTag) {
    // TODO: need to add logic to prevent deleting InUse tags.
    
    // const inUse = await this.dbQueryService.checkIfInUse(ppt, ProductTypeConfigProduct, 'pricedProductTypeId');
    // if (inUse) {
    //   this.toastr.warning(`You cannot delete a Priced Product Type that is in use.`, 'Cannot delete');
    //   return;
    // }

    // if it's an added record - cascade delete won't catch them.
    if (tag.entityAspect.entityState.isAdded()) {
      tag.programProductTypeTagMaps.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(tag.entityAspect);
    this.selectedTag = undefined;
    this.tmaps = [];
    if (this.tags) {
      _.remove(this.tags, tag);
      AgFns.refreshGrid(this.tagGridOptions, this.tags);
    }
  }

  // ------------- Tmap grid --------------------------

  onTmapGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getTmapColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getTmapColDefsAndSortModel() {
    const ptypePath = 'pricedProductType.productType'
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Supplier', field: ptypePath + '.supplier.name', filter: 'agTextColumnFilter', },
      { headerName: 'Manufacturer', field: ptypePath + '.manufacturer.name', filter: 'agTextColumnFilter', },
      { headerName: 'SKU', field: ptypePath + '.productKey', filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: ptypePath + '.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: ptypePath + '.description', filter: 'agTextColumnFilter' },
      { headerName: 'Base Price', field: 'pricedProductType.basePrice' },
      ProxAgFns.getEntityDeleteColDef(this.onTmapDelete.bind(this), { canDisplay: this.canDelete.bind(this)})
    ];
    const sortModel: ISortModel = [
      { colId: ptypePath + '.supplier.name', sort: 'asc' },
      { colId: ptypePath + '.manufacturer.name', sort: 'asc' },
      { colId: ptypePath + 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onTmapAdd() {
    if (!this.selectedTag) return;
    const tagId = this.selectedTag.id;
    const queryFn = () => this.dbQueryService.createPricedProductTypesQuery(this.accountId, tagId);
    const pricedProductTypes = await this.dialogService.createFinder(PricedProductTypeFinderDialog, {
      queryFn, rowSelection: 'multiple'
    });
    const newPpts = pricedProductTypes.filter(ppt => !ppt.programProductTypeTagMaps.some(x => x.programProductTypeTagId == tagId));
    const newTmaps = newPpts.map(ppt => {
      let tmap = this.dbSaveService.uow.undoIfDeleted(ProgramProductTypeTagMap, [   ppt.id, tagId ]);
      if (tmap == null) {
        tmap = this.dbSaveService.createEntity(ProgramProductTypeTagMap, { 
          pricedProductTypeId: ppt.id,
          programProductTypeTagId: tagId, 
        });
      }
      return tmap;
    });


    this.tmaps.push(...newTmaps);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
  }

  async onTmapDelete(tmap: ProgramProductTypeTagMap) {
    // // const inUse = await this.dbQueryService.checkIfInUse(fc, ...);
    // const inUse = true;
    // if (inUse) {
    //   this.toastr.warning(`You cannot delete a Priced Product Type Feature choice that is in use.`, 'Cannot delete');
    //   return;
    // }

    EntityFns.deleteOrDetach(tmap.entityAspect);
    _.remove(this.tmaps, tmap);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
  }

  isTagEditable(params: EditableCallbackParams) {
    if (!this.selectedTag) return false;
    return this.selectedTag['_tmpCanDelete'] ?? false;
  }

  // ---- Misc ----------------------------

  override async addCrossValidationErrors() {
    //

  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    if (this.tags) {
      this.tags = await this.dbQueryService.getProgramProductTypeTagsForAccount(this.accountId);
      this.tmaps = [];
    }

    AgFns.refreshGrid(this.tagGridOptions, this.tags);

    if (!this.tagGridOptions.api) return;
    if (!this.selectedTag) {
      AgFns.selectFirstRow(this.tagGridOptions);
    } else {
      AgFns.selectGridRowByKey(this.tagGridOptions.api, e => e.id, this.selectedTag.id);
      this.tmaps = await this.dbQueryService.getProgramProductTypeTagMapsForTag(this.selectedTag.id);
      AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    }
  }
}
