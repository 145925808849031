/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseListEditFrmComponent, DateCellEditorPngComponent, ProxAgFns, StatusService } from '@core';
import { Account, AccountBlanketPurchaseOrder, ActiveStatusEnum, Program } from '@models';
import { BaseService } from '@core';

import { DateFns, UtilFns } from '@utils';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import * as _ from 'lodash';

@Component({
  selector: 'prox-account-blanket-purchase-orders',
  template: BaseListEditFrmComponent.HTML,
})
export class AccountBlanketPurchaseOrdersComponent extends BaseListEditFrmComponent<AccountBlanketPurchaseOrder>  {
  accountId!: string;
  account!: Account;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');
    const blanketPos = await this.dbQueryService.getAccountBlanketPurchaseOrders(this.accountId);

    this.initialize(AccountBlanketPurchaseOrder, 'Purchase Orders', blanketPos);
    this.setTitle('Purchase Orders for ' + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  override statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay
  }

  override isAddDisabled(): boolean {
    return this.isReadOnly();
  }

  override async createEntity() {
    return this.dbSaveService.createEntity(AccountBlanketPurchaseOrder, { 
      accountId: this.accountId,  
      startDate: DateFns.startOfDay(new Date()),
      endDate: DateFns.startOfDay(DateFns.dateAdd(new Date(), 1, 'year')),
      activeStatusId: ActiveStatusEnum.Active
    });
  }

  override async  createDefaultEntity(entity: AccountBlanketPurchaseOrder): Promise<AccountBlanketPurchaseOrder | undefined> {
      return undefined;
  }

  override getSortValue(e: AccountBlanketPurchaseOrder) {
    return e.purchaseOrder;
  }

  override getAltKeyValue(e: AccountBlanketPurchaseOrder){
      return e.purchaseOrder;
  }

  override async checkIfInUse(po: AccountBlanketPurchaseOrder)  {
    const isInUse = await this.dbQueryService.checkIfInUse(po, Program, 'accountBlanketPurchaseOrderId')
    return isInUse
  }

  override getColDefs() {
    const columnDefs: ColDef[] = [
      {  headerName: 'Purchase Order', field: 'purchaseOrder',  editable: !this.isReadOnly() },
      { headerName: 'Start Date', field: 'startDate', editable: !this.isReadOnly(),  cellEditor: DateCellEditorPngComponent, filter: 'agDateColumnFilter' },
      { headerName: 'End Date', field: 'endDate', editable: !this.isReadOnly(),  cellEditor: DateCellEditorPngComponent, filter: 'agDateColumnFilter' },
      { headerName: 'Amount', field: 'amt', editable: !this.isReadOnly(),  filter: 'agNumberColumnFilter' },
      { headerName: 'Amount Used',  filter: 'agNumberColumnFilter', 
        valueGetter: (params) => {
          const x = params.data as AccountBlanketPurchaseOrder;
          if (x == null) return;
          return _.sumBy(x.accountBlanketPurchaseOrderLogs, y => y.amt);
        }, colId: 'usedAmt'  // HACK: colId endsWith amt triggers $ formatting.
      } ,
      ProxAgFns.getEntityDeleteColDef(this.onDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    return columnDefs;
  }
}
