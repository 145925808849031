import { GridOptions, GridReadyEvent, ColDef } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, AgFns, ISortModel, QueryComponent } from '@core';
import { UtilFns } from '@utils';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-action-list',
  templateUrl: './action-list.component.html',
})
export class ActionListComponent extends QueryComponent {
  accountId!: string;
  gridOptions: GridOptions = {};
  items: MenuItem[] | undefined;
  actions: any[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    dbQueryService: AccountDbQueryService,
    public primengConfig: PrimeNGConfig,
    public actionDialogService: DialogService
  ) {
    super(baseService, route, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');

/*     this.actions = await this.dbQueryService.getAccountActions(
      this.accountId
    ); */

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
    });
    this.isPageReady = true;
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

  selectTemplates(id: number) {
    console.log("id: " + id);
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        ...AgFns.createButtonProps('', this.onEdit.bind(this), {
          label: 'View/Edit',
        }),
        width: 90,
        maxWidth: 90,
      },
      {
        headerName: 'Event Trigger',
        field: 'accountActionTrigger.name',
        filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Message Templates',
        field: 'accountTemplate.name',
        filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Event Message Name',
        field: 'name',
        minWidth: 300,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Event Message Description',
        field: 'description',
        minWidth: 400,
        filter: 'agTextColumnFilter',
      }
    ];
    const sortModel: ISortModel = [
      { colId: 'accountTemplateType.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  async onAddMessage() {
    // const r = await MessageTemplateDialogComponent.openDialog(this.actionDialogService, {
    //   messageTemplate: undefined
    // });
  }

  onAdd() {
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-actions', 'add' ]);
  }

  onEdit(row: any) {
    this.updateGridRouteParams(this.gridOptions, row.id);
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-actions', row.id]);
  }
}