/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {
  Component,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusChangeDialog, StatusService } from '@core';
import { Account, ActiveStatus, ActiveStatusEnum } from '@models';
import { BaseService } from '@core';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountDbSaveService } from './services/account-db-save.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-account-list',
  templateUrl: './account-list.component.html',
})
export class AccountListComponent extends SaveAndQueryComponent {
  accounts: Account[] = [];
  activeStatuses: ActiveStatus[] = [];
  //selectedRow!: Account;
  @ViewChild('actionCell') actionCell!: TemplateRef<any>;

  //selected: Account | null = null;
  actionSidebar: any;
  gridOptions: GridOptions = {};
  gridApi: GridApi | undefined;
  frameworkComponents: any;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  async updateFromParams(): Promise<void> {
    this.accounts = await this.dbQueryService.createQuery(Account).execute();
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.gridOptions = AgFns.initGridOptions(this, {
      //onRowSelected: this.onRowSelected,
      onGridReady: this.onGridReady
    });
    
    this.isPageReady = true;
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

/*   onRowSelected(event: RowSelectedEvent) {
    const account = <Account> event.data;
    if (account == null) {
      return;
    }

    if (event.node.isSelected()) {
      this.selectedRow = account;
    }
  } */
  
  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
/*       { ...AgFns.createButtonProps('', this.onEdit.bind(this), { label: 'Edit'}), width: 50, maxWidth: 50 }, */
      { ...AgFns.createButtonProps('', this.onManage.bind(this), { label: 'Manage'}), width: 70, maxWidth: 70 },
      { headerName: 'Account Name', field: 'name', maxWidth: 300, filter: 'agTextColumnFilter',   },
      { headerName: 'Primary Administrator', valueGetter: (c =>  
        c.data.primaryAccountAdmin ? 
        (c.data?.primaryAccountAdmin?.proximityUser?.salutation ? (c.data?.primaryAccountAdmin?.proximityUser?.salutation?.trim() + " ") : "") +
        c.data?.primaryAccountAdmin?.proximityUser?.firstName?.trim() + ' ' +
        c.data?.primaryAccountAdmin?.proximityUser?.lastName?.trim() : ''
      ),
      maxWidth: 300,
    },
    { headerName: 'E-mail', field: 'primaryAccountAdmin.proximityUser.email', maxWidth: 200, },
    { headerName: 'Telephone', field: 'primaryAccountAdmin.proximityUser.telephoneNumber', maxWidth: 200, },
    { headerName: 'Extension', field: 'primaryAccountAdmin.proximityUser.extension', maxWidth: 100, },
    { headerName: 'Cell', field: 'primaryAccountAdmin.proximityUser.cellNumber', maxWidth: 140, },
    { headerName: 'Status', field: 'activeStatus.name', maxWidth: 70, filter: 'agTextColumnFilter',   },
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onChangeStatus(row: Account) {
    const errs: string[] = [];

    const selectedStatusId = await StatusChangeDialog.open(this.pngDialogService, {
      statuses: this.activeStatuses,
      isAvailable: s => true,
      currentStatus: row.activeStatus
    },{
      header: "Account Status"
    })

    if (selectedStatusId == row.activeStatusId) {
      return;
    }

    if (selectedStatusId == ActiveStatusEnum.Active) {
      if (row.primaryAccountAdminId == null) {
        errs.push("Active Proximity Accounts must have a Primary Administrator.");
      } else if (row.primaryAccountAdmin.getStatusId() != ActiveStatusEnum.Active) {
        errs.push("Account Primary Administrator must be status: Active.");
      }    
      
      if (row.accountImportCodeOptionId == null) {
        errs.push("Select the User Import Code Requirement before activating Proximity Account.");
      };
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(selectedStatusId, 'Account'), 'Account Status Change');
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.gridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.gridOptions.api?.refreshCells();
  }

  get isTransaction() {
    return this.router.url.includes('/transaction/');
  }
  
  get title() {
    return this.isTransaction ? 'Transactions - Accounts' : 'Global Administration - Accounts ';
  }

  onManage(row: Account) {
    if (this.isTransaction) {
      this.router.navigate(['transaction/accounts', row.id, 'manage'])
    } else {
      this.router.navigate(['account/accounts', row.id, 'manage'])
    }
  }

  onEdit(row: Account) {
    this.router.navigate(['account/account-add-edit', row.id]);
  }

  onAdd() {
    //this.router.navigate(['add'], { relativeTo: this.route })
    this.router.navigate(['account/account-add-edit', 'add']);
  }
}
