<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <div class="flex mb-2">
    <button [disabled]="isReadOnly()" (click)="onAdd()" pButton type="button" label="Add Account Administrator" icon="pi pi-plus" class="p-button-sm"></button>
    <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
  </div>
  <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="gridOptions" [rowData]="administrators"></prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="joGridOptions" [rowData]="jobOrders"></prox-ag-grid>
      </div>
    </ng-template>
  </p-splitter>
</ng-template>