<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Products</h5>
  </div>
  <div class="h-full flex flex-column ">
    <p-splitter class="flex-grow-1 w-full" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
      <ng-template pTemplate>
        <div class="h-full w-full flex flex-column ">
          <div class="flex flex-row">
            <div class="flex pb-2 pr-2">
              <button [disabled]="parent.isReadOnly()" (click)="onPptcAdd()" pButton type="button"
                label="Attach Product Groups" icon="pi pi-plus" class="p-button-sm"></button>
            </div>
            <div class="flex pb-2">
              <button [disabled]="parent.isReadOnly() || (selectCount < 2)" (click)="onMultiTags()" pButton type="button"
               label="Apply Tags to {{selectCount}} Product Types" class="p-button-sm"></button>
            </div>
          </div>
          <div class="flex-grow-1">
            <prox-ag-grid [gridOptions]="pptcGridOptions" [rowData]="pptcs"></prox-ag-grid>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate>
        <p-splitter [panelSizes]="[50, 50]" class="w-full" styleClass="h-full">
          <ng-template pTemplate>
            <div class="flex-grow-1">
              <prox-ag-grid [gridOptions]="ptcpGridOptions" [rowData]="ptcps"></prox-ag-grid>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div class="flex-grow-1">
              <prox-ag-grid [gridOptions]="ptcaGridOptions" [rowData]="ptcas"></prox-ag-grid>
            </div>
          </ng-template>
        </p-splitter>
      </ng-template>
    </p-splitter>
  </div>
</div>