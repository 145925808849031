/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';
import { BaseService } from '@core';
import { UtilFns } from '@utils';
import * as _ from 'lodash';
import { EntityFns } from '@data';
import { EntityError } from 'breeze-client';
import { AccountAddress, ShippingUserGroup, ShippingUserGroupAddressMap, ActiveStatusEnum, Account } from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { ShippingUserGroupFinderDialog } from '../account-users/shipping-user-group-finder.dialog';

@Component({
  selector: 'prox-shipping-account-addresses',
  templateUrl: './shipping-account-addresses.component.html',
})
export class ShippingAccountAddressesComponent extends SaveAndQueryComponent {
  supplierId!: string;
  accountId!: string;
  account!: Account;
  addrGridOptions!: GridOptions;
  addrs?: AccountAddress[];
  selectedAddr?: AccountAddress;
  sugGridOptions!: GridOptions;
  shippingUserGroups: ShippingUserGroup[] = [];
  editLabel = 'Edit';
  editLabelWidth = 50;
  
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    private statusService: StatusService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.addrGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddrGridReady,
      onRowSelected: this.onAddrRowSelected,
      rowModelType: 'clientSide',
    });
    AgFns.captureGridRouteParams(this.addrGridOptions, this.route, 'id');
    this.sugGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSugGridReady,
      rowModelType: 'clientSide',
    }, { detailProperty: 'shippingUserGroupMaps'} );

    if (this.statusService.getWorkingStatus(this.account as any).isReadOnly) {
      this.editLabel = 'Read Only';
      this.editLabelWidth = 100;
    }

    this.addrs = await this.dbQueryService.getShippingAccountAddresses(this.accountId);
    this.setTitle('Account Shipping Addresses for ' + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  okToDelete() {
    return !this.isReadOnly();
  }

  onAddrGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getAddrColDefsAndSortModel();
    // this.updateAslMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.refreshGrid(gridOptions, this.addrs);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getAddrColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      // ProxAgFns.getEntityStateColDef(),
      { ...AgFns.createButtonProps('', this.onAddrEdit.bind(this), { label: this.editLabel }), minWidth: this.editLabelWidth, maxWidth: this.editLabelWidth },
      { headerName: '# Groups', type: 'numericColumn', width: 100,
        valueGetter: (params) => {
          const addr = params.data as AccountAddress
          if (addr == null) return;
          return addr.shippingUserGroupAddressMaps.length;
        },
      },
      { headerName: 'Description', field: 'description',  filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: 'name',  filter: 'agTextColumnFilter' },
      { headerName: 'Line 1', field: 'line1', filter: 'agTextColumnFilter' },
      { headerName: 'Line 2', field: 'line2', filter: 'agTextColumnFilter' },
      { headerName: 'City', field: 'city', filter: 'agTextColumnFilter' },
      { headerName: 'State', field: 'state', filter: 'agTextColumnFilter' },
      { headerName: 'Zip', field: 'zipcode', filter: 'agTextColumnFilter' },
      ProxAgFns.getEntityDeleteColDef(this.onAddrDelete.bind(this), { canDisplay: this.okToDelete.bind(this)})
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  onAddrRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const asl = event.data as AccountAddress;
    if (asl == null) return;
    this.selectedAddr = asl;
    if (asl.entityAspect.entityState.isDeleted()) {
      this.selectedAddr = undefined;
      this.shippingUserGroups = [];
      this.addrGridOptions.api?.deselectAll();
      return;
    }
    this.updateGridRouteParams(this.addrGridOptions, asl.id);
    
    this.showSugEntites();
  }

  onAddrEdit(row: AccountAddress) {
    this.updateGridRouteParams(this.addrGridOptions, row.id);
    this.router.navigate([row.id, 'S'], { relativeTo: this.route });
  }

  async onAddrAdd() {
    this.router.navigate(['add', 'S'], { relativeTo: this.route });
  }

  async onAddrDelete(addr: AccountAddress) {

    // if it's an added record - cascade delete won't catch them.
    if (addr.entityAspect.entityState.isAdded()) {
      addr.shippingUserGroupAddressMaps.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(addr.entityAspect);
    this.selectedAddr = undefined;
    this.shippingUserGroups = [];
    if (this.addrs) {
      _.remove(this.addrs, addr);
      AgFns.refreshGrid(this.addrGridOptions, this.addrs);
    }
  }

  // ----------------------------------------

  onSugGridReady() {
    const colDefs = [
      { headerName: 'Group Name', field: 'name', filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' },
      ProxAgFns.getEntityDeleteColDef(this.onSugDelete.bind(this), { canDisplay: this.okToDelete.bind(this)})
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ];
    this.updateSugMasterDetail(this.sugGridOptions);
    AgFns.initGrid(this.sugGridOptions, colDefs, sortModel);
  }

  updateSugMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Last Name', field: 'proximityUser.lastName', filter: 'agSetColumnFilter', },
      { headerName: 'First Name', field: 'proximityUser.firstName', filter: 'agSetColumnFilter',  },
      { headerName: 'Middle Name', field: 'proximityUser.middleName', filter: 'agSetColumnFilter',  },
      { headerName: 'Salutation',  field: 'proximityUser.salutation', filter: 'agSetColumnFilter', },
      { headerName: 'Gender', field: 'gender.name',  filter: 'agSetColumnFilter',    },
      { headerName: 'E-Mail', field: 'proximityUser.email', filter: 'agSetColumnFilter',   },
        ProxAgFns.getWorkingStatusDef('Status', this.statusService)
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',
      getDetailRowData: params => {
        const aug = params.data as ShippingUserGroup;
        params.successCallback(aug.shippingUserGroupMaps.map(augm => augm.accountUser));
      },
    }
  }

  async onSugAdd() {
    if (this.shippingUserGroups == null) return;
    if (this.selectedAddr == null) return;
    
    const allShippingUserGroups = await this.dbQueryService.getShippingUserGroups(this.accountId);
    const sugSet = new Set(this.shippingUserGroups);
    const unusedUserGroups = allShippingUserGroups.filter(x => !sugSet.has(x));
    const newSugs = await this.dialogService.createFinder(ShippingUserGroupFinderDialog, {
      shippingUserGroups: unusedUserGroups,
      rowSelection: 'multiple',
    }) ;
    const addrId = this.selectedAddr.id;
    const newSaaUgs = newSugs.map(sug => {
      let sugMap = this.dbSaveService.uow.undoIfDeleted(ShippingUserGroupAddressMap, [ addrId, sug.id]);
      if (sugMap == null) {
        sugMap = this.dbSaveService.createEntity(ShippingUserGroupAddressMap, {
          shippingAccountAddressId: addrId,
          shippingUserGroupId: sug.id
        });
      }
      return sugMap;
    });
    this.shippingUserGroups?.push(...newSugs);
    AgFns.refreshGrid(this.sugGridOptions, this.shippingUserGroups);
  }

  async onSugDelete(sug: ShippingUserGroup) {
    if (!this.shippingUserGroups) return;
    if (this.selectedAddr == null) return;
    const aslug = this.selectedAddr.shippingUserGroupAddressMaps.find(x => sug.id == x.shippingUserGroup.id);
    UtilFns.assertNonNull(aslug, 'Shipping Account Address User group should have been found');
    EntityFns.deleteOrDetach(aslug.entityAspect);
    _.remove(this.shippingUserGroups, sug);
    AgFns.refreshGrid(this.sugGridOptions, this.shippingUserGroups);
  }

  private showSugEntites() {
    if (this.selectedAddr) {
      this.shippingUserGroups = this.selectedAddr.shippingUserGroupAddressMaps.map(aslug => aslug.shippingUserGroup)
    } else {
      this.shippingUserGroups = [];
    }
    AgFns.refreshGrid(this.sugGridOptions, this.shippingUserGroups);
  }

  // ---------------------------------------------------------------------------------------

  override async beforeSave() {
    // duplicate names are allowed. JM 3/5/24
    let validSugs = true;
    if (!this.addrs) return true;
/*     const dupNames = UtilFns.getDuplicates(this.addrs.map(x => x.name));
    if (dupNames.length > 0) {
      this.toastr.warning(`The following names are duplicates which are not allowed: ${dupNames.join(', ')} `);
      validSugs = false;
    }; */

    this.shippingUserGroups.forEach(sup => {
      if (sup.activeStatusId == ActiveStatusEnum.Active) {
        if (sup.shippingUserGroupAddressMaps.length <= 0) {
          this.toastr.warning('Active Shipping User Groups must have one or more Proximity Users');
          validSugs = false
        }
      }
    });
    return validSugs;
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    this.addrs = await this.dbQueryService.getShippingAccountAddresses(this.accountId);

    if (this.selectedAddr == null || this.selectedAddr.entityAspect.entityState.isDetached()) {
      AgFns.selectFirstRow(this.addrGridOptions);
    }
    
    if (this.addrGridOptions.api == null) return;
    AgFns.refreshGrid(this.addrGridOptions, this.addrs);
    AgFns.selectGridRowByKey(this.addrGridOptions.api, e => e.id, this.selectedAddr?.id || '')
    this.showSugEntites();
  } 

  override navigateToValidationError(error: EntityError, event: MouseEvent) {
    const errEnt = error.entity;
    console.log("entityError: " + JSON.stringify(error));
    const prop = error.propertyName;
    if (errEnt instanceof ShippingUserGroup) {
      AgFns.selectGridRowByKey(this.addrGridOptions, (e: AccountAddress) => e.id, errEnt.id);
    } 
    
  }
}
