<div class="h-full flex flex-column">
  <div class="pb-2">
    <prox-breadcrumb></prox-breadcrumb>
  </div>
  <div class="flex-grow-1">
    <div class="layout-menu-container h-full flex gap-3">
        <p-menu styleClass="layout-submenu" *ngIf="items.length" [model]="items"></p-menu> 
      <div class="card flex-grow-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>