<div class="layout-dashboard">
    <div class="grid">
        <div class="col-12 md:col-6 xl:col-3" *ngFor="let metric of metrics">
            <div class="card shadow-1 flex flex-column" [style.borderColor]="metric.color_light" [style]="{'border-color': metric.color_light, 'border-left': '4px solid'}">
                <div class="flex align-items-center">
                    <div class="flex justify-content-center align-items-center p-2 border-round" [style.backgroundColor]="metric.color_light">
                        <i [class]="metric.icon" [style.color]="metric.color_dark"></i>
                    </div>
                    <span class="text-xl ml-2 font-semibold" [style.color]="metric.color_light">{{metric.title}}</span>
                </div>

                <div class="grid mt-3">
                    <div class="col-6 flex flex-column p-3 text-center" *ngFor="let content of metric.textContent; let i = index" [class]="i === 0 ? 'border-right-1 surface-border' : '' ">
                        <span class="text-color text-2xl font-semibold">{{content.amount}}</span>
                        <span class="text-color font-semibold">{{content.text}}</span>
                    </div>
                </div>
            </div>
<!--         </div>

        <div class="col-12 xl:col-6">
            <div class="card">
                <div class="flex w-full justify-content-between align-items-center">
                    <h4>Orders</h4>
                    <button type="button" pButton class="p-button-text p-button-secondary" icon="pi pi-search" label="Show" (click)="menu.toggle($event)"></button>
                </div>
                <p-menu #menu [popup]="true" [model]="items"></p-menu>

                <div class="grid mt-3">
                    <div class="col-6 md:col-3 relative transition-all transition-duration-300 hover:shadow-3 cursor-pointer h-5rem" (click)="changeDataset($event)" data-label="New Orders" data-index="0" data-fill="rgba(100, 181, 246, 0.2)" data-stroke="#BBDEFB">
                        <span class="flex align-items-center"><i class="pi pi-plus-circle mr-1"></i> New</span>
                        <img src="assets/demo/images/dashboard/graph-new.svg" class="absolute w-11" style="left: 5%; bottom: 0">
                    </div>
                    <div class="col-6 md:col-3  relative transition-all transition-duration-300 hover:shadow-3 cursor-pointer h-5rem" (click)="changeDataset($event)"  data-label="Completed Orders" data-index="1" data-stroke="#C5CAE9" data-fill="rgba(121, 134, 203, 0.2)">
                        <span class="flex align-items-center"><i class="pi pi-check-circle mr-1"></i> Completed</span>
                        <img src="assets/demo/images/dashboard/graph-completed.svg" class="absolute w-11" style="left: 5%; bottom: 0">
                    </div>
                    <div class="col-6 md:col-3  relative transition-all transition-duration-300 hover:shadow-3 cursor-pointer h-5rem" (click)="changeDataset($event)" data-label="Refunded Orders" data-index="2" data-stroke="#B2DFDB" data-fill="rgba(224, 242, 241, 0.5)">
                        <span class="flex align-items-center"><i class="pi pi-refresh mr-1"></i> Refunded</span>
                        <img src="assets/demo/images/dashboard/graph-refunded.svg" class="absolute w-11" style="left: 5%; bottom: 0">
                    </div>
                    <div class="col-6 md:col-3  relative transition-all transition-duration-300 hover:shadow-3 cursor-pointer h-5rem" (click)="changeDataset($event)" data-label="Cancelled Orders" data-index="3" data-stroke="#B2EBF2" data-fill="rgba(224, 247, 250, 0.5)">
                        <span class="flex align-items-center"><i class="pi pi-times-circle mr-1"></i> Cancelled</span>
                        <img src="assets/demo/images/dashboard/graph-cancelled.svg" class="absolute w-11" style="left: 5%; bottom: 0">
                    </div>

                    <div class="col-12 mt-5">
                        <p-chart #chart type="line" [data]="ordersChart" [options]="ordersChartOptions" id="order-chart"></p-chart>
                    </div>
            </div>
        </div> -->

        <!-- <div class="col-12 xl:col-6">
            <div class="card">
                <div class="flex justify-content-between">
                    <h4>Recent Sales</h4>
                    <p-dropdown [options]="orderWeek" [(ngModel)]="selectedOrderWeek" optionLabel="name" (onChange)="recentSales($event)" styleClass="w-9rem"> </p-dropdown>
                </div>

                <p>Your sales activity over time.</p>

				<p-table [value]="products" [paginator]="true" [rows]="5" responsiveLayout="scroll">
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="id" style="width:10%;min-width:6rem;">ID
								<p-sortIcon field="id"></p-sortIcon>
							</th>
							<th pSortableColumn="category" style="width:30%;min-width:10rem;">Category
								<p-sortIcon field="category"></p-sortIcon>
							</th>
							<th pSortableColumn="price" style="width:20%;min-width:10rem;">Price
								<p-sortIcon field="price"></p-sortIcon>
							</th>
							<th pSortableColumn="inventoryStatus" style="width:30%;min-width:10rem;">Status
								<p-sortIcon field="inventoryStatus"></p-sortIcon>
							</th>
							<th style="width:10%;min-width:6rem;"></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-product>
						<tr>
							<td>
								<span class="p-column-title">Id</span>
								{{product.id}}</td>
							<td>
								<span class="p-column-title">Category</span>
								{{product.category}}</td>
							<td>
								<span class="p-column-title">Price</span>
								{{product.price | currency:'USD'}}</td>
							<td>
								<span class="p-column-title">Status</span>
								<span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
							</td>
							<td style="text-align: center">
								<button pButton type="button" icon="pi pi-search"></button>
							</td>
						</tr>
					</ng-template>
				</p-table>
            </div>
        </div>
        
        <div class="col-12 lg:col-4">
            <div class="card">
                <h4>Tasks</h4>
                <p>Overview of your pending tasks.</p>
                <div class="mt-4">
                    <span class="block mb-2"><span class="font-semibold">12 Orders</span> to fulfill</span>
                    <div class="w-full border-round" style="height: 7px; background-color: var(--surface-d)">
                        <div class="w-7 h-full border-round" style="background-color: #64B5F6"></div>
                    </div>
                </div>
                <div class="mt-4">
                    <span class="block mb-2"><span class="font-semibold">40+ Payments</span> to withdraw</span>
                    <div class="w-full border-round" style="height: 7px; background-color: var(--surface-d)">
                        <div class="w-5 h-full border-round" style="background-color: #A5D6A7"></div>
                    </div>
                </div>
                <div class="mt-4">
                    <span class="block mb-2"><span class="font-semibold">4 Repors</span> to revise</span>
                    <div class="w-full border-round" style="height: 7px; background-color: var(--surface-d)">
                        <div class="w-8 h-full border-round" style="background-color: #7986CB"></div>
                    </div>
                </div>
                <div class="mt-4">
                    <span class="block mb-2"><span class="font-semibold">6 Questions</span> to respond</span>
                    <div class="w-full border-round" style="height: 7px; background-color: var(--surface-d)">
                        <div class="w-4 h-full border-round" style="background-color: #9575CD"></div>
                    </div>
                </div>
                <div class="mt-4">
                    <span class="block mb-2"><span class="font-semibold">2 Chargebacks</span> to review</span>
                    <div class="w-full border-round" style="height: 7px; background-color: var(--surface-d)">
                        <div class="w-6 h-full border-round" style="background-color: #4DB6AC"></div>
                    </div>
                </div>
            </div>

            <div class="card">
                <h4>Best Sellers</h4>
				<p>Most popular products from your collection.</p>

                <ul class="list-none p-0 m-0">
                    <li class="p-3 surface-border border-bottom-1 flex justify-content-between align-items-center">
                        <span class="font-bold">Product</span>
                    <span class="font-bold">Sales</span>
                    </li>
                    <li *ngFor="let product of productsBestSellers; let i = index;" class="p-3 surface-border" [ngClass]="{'border-bottom-1': i !== 6}">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex gap-3 align-items-center">
                                <img [src]="'assets/demo/images/product/'" class="w-5rem h-3rem shadow-5" alt="product.name">
                                <span>{{product.name}}</span>
                            </div>
                            <span class="font-semibold">$12.23</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div> -->

        <!-- <div class="col-12 lg:col-8">
            <div class="card">
                <h4>Revenue stream</h4>
                <p>Comparison of your revenue sources.</p>
                <div class="flex justify-content-center align-items-center">
                    <p-chart type="pie" [data]="revenueChart" [options]="revenueChartOptions" [style]="{'width': '50%'}"></p-chart>
                </div>
            </div>

            <div class="card">
                <h4>Team Members</h4>
                <ul class="list-none p-0 m-0">
                    <li *ngFor="let member of teamMembers; let i = index;" class="p-3 surface-border" [ngClass]="{'border-bottom-1': i !== 4}">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex gap-2 align-items-center">
                                <img [src]="'assets/demo/images/avatar/'+ member.image +'.png'" class="w-4rem h-4rem border-circle" alt="member.name">
                                <div>
                                    <span class="block text-xl font-bold">{{member.name}}</span>
                                    <span class="block">{{member.desc}}</span>
                                </div>
                            </div>
                            <div class="flex gap-2">
                                <button pButton class="p-button-icon-only p-button-rounded p-button-success" icon="pi pi-envelope"></button>
                                <button pButton class="p-button-icon-only p-button-rounded p-button-warning" icon="pi pi-cog"></button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div> -->
  <!--   </div> -->

</div>