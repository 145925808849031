import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMessage {
  from?: string;
  id: MessageTypeEnum;
  message?: string;
}

export enum MessageTypeEnum {
  Noop = 'noop',
  Login = 'login',
  Notification = 'notification',
  
}

/** Simple message bus using an observable */
@Injectable({providedIn: 'root'})
export class MessageBusService {
  private messageSubject = new BehaviorSubject<IMessage>({ id: MessageTypeEnum.Noop });

  public notify(message: IMessage) {
    this.messageSubject.next(message);
  }

  public get messages() {
    return this.messageSubject.asObservable();
  }
}
