// Projections returned by server-side queries

/** Returned by AccountController.JoHistSummaries endpoint */
export interface JoHistSummary {
  /** JobOrder id */
  id: string;
  displayid: string;
  program: string;
  status: string;
  type: string;
  user: string;
  approvedBy: string;
  orderPlacedDate: Date;
  overageAmt: number;
  orderDetailAmt: number;
  orderAddonAmt: number;
  orderFeatureAmt: number;
}

/** Returned by AccountController.JoHistTotalsBySupplier  */
export interface JoHistTotalsBySupplier {
  jobOrderId: string;
  /** displayId */
  jobOrderName: string;
  supplierId: string;
  supplierName: string;
  amt: number;
  orderAddonAmt: number;
  orderFeatureAmt: number;
}

