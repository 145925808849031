/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusChangeDialog, StatusService } from '@core';
import { BaseService } from '@core';
import { UtilFns } from '@utils';
import * as _ from 'lodash';
import { EntityFns } from '@data';
import { EntityError } from 'breeze-client';
import {
  ProgramProductTypeTag,
  AccountUser,
  ShippingUserGroup,
  ActiveStatus,
  ActiveStatusEnum,
  ShippingUserGroupMap,
  Account,
  PurchaseOrder
} from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountUserFinderDialog } from './account-user-finder.dialog';
import { BudgetItemsListComponent } from '../budgets/budget-items-list.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-shipping-user-groups',
  templateUrl: './shipping-user-groups.component.html',
})
export class ShippingUserGroupsComponent extends SaveAndQueryComponent {
  @ViewChild(BudgetItemsListComponent)
  budgetListItemsComponent!: BudgetItemsListComponent;
  supplierId!: string;
  accountId!: string;
  account?: Account;
  tabViewIndex = 0;
  activeStatuses!: ActiveStatus[];
  originalActiveStatusId!: ActiveStatusEnum;

  allSugs!: ShippingUserGroup[];
  sugGridOptions!: GridOptions;
  sugs: ShippingUserGroup[] = [];
  selectedSug?: ShippingUserGroup;
  userGridOptions!: GridOptions;
  users: AccountUser[] = [];
  programProductTypeTags!: ProgramProductTypeTag[];
  ref!: DynamicDialogRef;
  purchaseOrders: PurchaseOrder[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public pngDialogService: DialogService,
    private statusService: StatusService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);

    const po = await this.dbQueryService.getAccountBlanketPurchaseOrders(this.accountId);
    po.forEach(p => {
      this.purchaseOrders.push(new PurchaseOrder (p.id, p.purchaseOrder));
    })

    this.purchaseOrders.unshift({
      id: null,
      purchaseOrder: '- None -',
    } as PurchaseOrder);

    this.sugGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSugGridReady,
      onRowSelected: this.onSugRowSelected,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const sug = rowIdParams.data as ShippingUserGroup;
        return sug.id;
      },
    });

    AgFns.captureGridRouteParams(this.sugGridOptions, this.route, 'id');
    this.userGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onUserGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const user = rowIdParams.data as AccountUser;
        return user.id;
      },
    });

    this.allSugs = await this.dbQueryService.getShippingUserGroups(
      this.accountId
    );
    this.setTitle("Shipping User Groups for " + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  // Aug Grid

  onSugGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getSugColDefsAndSortModel();
    // this.updateSugMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    this.refreshSugGrid();

    AgFns.applyGridRouteParams(this.sugGridOptions);
  }

  getSugColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'name',
        editable: !this.isReadOnly(),
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Shipping User Group Purchase Order',
        editable: !this.isReadOnly(),
        width: 200,
        ...AgFns.createRichDropdownEditor(
          'accountBlanketPurchaseOrderId',
          this.purchaseOrders,
          this.onChangeSelectPurchaseOrder.bind(this),
          'id',
          'purchaseOrder'
        ),
      },
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
      ProxAgFns.getEntityDeleteColDef(this.onSugDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  async onChangeSelectPurchaseOrder() {}

  async onChangeStatus(row: ShippingUserGroup) {
    if (this.dbSaveService.hasChanges()){
      this.toastr.warning("Save or undo all changes before changing status", 'Unable to Proceed');
      return;
    }
  
    const errs: string[] = [];
    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: () => true,
        currentStatus: row.activeStatus,
      },
      {
        header: 'Shipping User Group Status',
      }
    );

    if (selectedStatusId == row.activeStatusId) {
      return;
    }

    if (selectedStatusId != null) {
      if (!(row as ShippingUserGroup).entityAspect.validateEntity()) {
        errs.push('Shipping User Group failed validation test.  Fix Shipping User Group before changing status.');
      }
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(selectedStatusId, 'Shipping User Group'), 'Shipping User Group Status Change');
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.sugGridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.sugGridOptions.api?.refreshCells();
  }

  onSugRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const sug = event.data as ShippingUserGroup;
    if (sug == null) return;
    this.selectedSug = sug;
    if (sug.entityAspect.entityState.isDeleted()) {
      this.selectedSug = undefined;
      this.sugGridOptions.api?.deselectAll();
      return;
    }

    this.updateGridRouteParams(this.sugGridOptions, sug.id);

    this.users = sug.shippingUserGroupMaps.map((x) => x.accountUser);
    AgFns.refreshGrid(this.userGridOptions, this.users);
  }

  async onSugAdd() {
    const newAug = this.dbSaveService.createEntity(ShippingUserGroup, {
      accountId: this.accountId,
      activeStatusId: ActiveStatusEnum.Hold,
    });
    newAug.entityAspect.validateEntity();
    this.allSugs.push(newAug);
    this.refreshSugGrid();
    const gridApi = this.sugGridOptions.api;
    if (!gridApi) return;
    const rowIndex = AgFns.getRowIndex(gridApi, newAug.id);
    this.sugGridOptions.api?.setFocusedCell(rowIndex ?? 0, 'name');
  }

  async onSugDelete(sug: ShippingUserGroup) {
    sug.shippingUserGroupMaps
      .slice()
      .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    sug.shippingUserGroupAddressMaps
      .slice()
      .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(sug.entityAspect);
    this.selectedSug = undefined;
    this.users = [];
    if (this.allSugs) {
      _.remove(this.allSugs, sug);
      this.refreshSugGrid();
    }
  }

  async refreshSugGrid(fullRefresh: boolean = false) {
    this.sugs = this.allSugs.slice();
    if (this.selectedSug) {
      this.selectedSug = this.sugs.includes(this.selectedSug)
        ? this.selectedSug
        : undefined;
    }
    AgFns.refreshGrid(this.sugGridOptions, this.sugs || []);
    if (fullRefresh) {
      AgFns.refreshGrid(this.sugGridOptions, this.sugs || []);
      if (this.selectedSug) {
        AgFns.selectGridRowByKey(
          this.sugGridOptions,
          (e) => e.id,
          this.selectedSug.id
        );
      } else if (this.sugs.length > 0) {
        this.selectedSug = await AgFns.selectFirstRow(this.sugGridOptions);
      }
    } else {
      if (this.selectedSug) {
        this.selectedSug = this.sugs.includes(this.selectedSug)
          ? this.selectedSug
          : undefined;
      }
    }

    if (this.selectedSug == null) {
      this.users = [];
    } else {
      this.users = this.selectedSug.shippingUserGroupMaps.map(
        (x) => x.accountUser
      );
    }
    AgFns.refreshGrid(this.userGridOptions, this.users);
  }

  // User Grid

  onUserGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getUserColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getUserColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Last Name',
        field: 'proximityUser.lastName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'First Name',
        field: 'proximityUser.firstName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Middle Name',
        field: 'proximityUser.middleName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Salutation',
        field: 'proximityUser.salutation',
        maxWidth: 100,
      },
      { headerName: 'User Name', field: 'proximityUser.username', maxWidth: 150, filter: 'agTextColumnFilter',   },
      {
        headerName: 'Gender',
        field: 'gender.name',
        maxWidth: 130,
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'E-Mail',
        field: 'proximityUser.email',
        filter: 'agTextColumnFilter',
      },
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
      ProxAgFns.getEntityDeleteColDef(this.onUserDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  async onUserAttach() {
    const newUsers = await this.attachUsers();
    if (newUsers.length == 0) return;
    this.users.push(...newUsers);
    AgFns.refreshGrid(this.userGridOptions, this.users);
    AgFns.updateMasterDetail(this.sugGridOptions, this.selectedSug);
  }

  async attachUsers() {
    if (this.selectedSug == null) return [];
    const sug = this.selectedSug;

    const allAccountUsers = await this.dbQueryService.getAccountUsers(
      this.accountId
    );

    const currentAccountUserIds = new Set(
      this.selectedSug.shippingUserGroupMaps.map((y) => y.accountUserId)
    );
    const remainingAccountUsers = allAccountUsers.filter(
      (au) => !currentAccountUserIds.has(au.id)
    );
    const newAccountUsers = await this.dialogService.createFinder(
      AccountUserFinderDialog,
      {
        accountUsers: remainingAccountUsers,
        rowSelection: 'multiple',
      }
    );
    if (newAccountUsers.length == 0) return [];
    newAccountUsers.forEach((user) => {
      if (!this.dbSaveService.uow.undoIfDeleted(ShippingUserGroupMap, [ this.selectedSug?.id,  user.id ])) {
        this.dbSaveService.createEntity(ShippingUserGroupMap, {
          shippingUserGroupId: this.selectedSug?.id,
          accountUserId: user.id,
        });
      }
    });
    return newAccountUsers;
  }

/*   async onUsersImport() {
    this.router.navigate([this.selectedSug?.id, this.selectedSug?.name], { relativeTo: this.route })
  } */
  
  async onUserDelete(accountUser: AccountUser) {
    if (!this.selectedSug) return;
    this.selectedSug.shippingUserGroupMaps
      .filter((x) => x.accountUserId == accountUser.id)
      .forEach((x) => {
        EntityFns.deleteOrDetach(x.entityAspect);
      });
    this.addEntitiesToValidate(this.selectedSug);
    _.remove(this.users, accountUser);
    AgFns.refreshGrid(this.userGridOptions, this.users);
  }

  // -----------------------------------------------------------------------

  //

  override async addCrossValidationErrors() {
    EntityFns.checkForDupErrors(
      this.allSugs,
      (x) => x.name,
      (e, dupName) =>
        this.createValidationError(
          e,
          'name',
          `This Proximity User Group Name is a duplicate: '${dupName}.`
        )
    );

/*     const sugs = this.getEntitiesToValidate().filter(
      (x) => x instanceof ShippingUserGroup
    ) as ShippingUserGroup[]; */
    
    // User Groups do not need a record to be saved  Records may be imported.

   /*  sugs.forEach((s) => {
      if (
        s.activeStatusId === ActiveStatusEnum.Active &&
        s.shippingUserGroupMaps.length == 0
      ) {
        this.createValidationError(
          s,
          'name',
          `The Active Proximity User Group, '${s.name}', must have at least one Proximity User.`
        );
      }
    }); */
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    this.allSugs = await this.dbQueryService.getShippingUserGroups(
      this.accountId
    );
    this.refreshSugGrid(true);
  }

  override async navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    if (errEnt instanceof ShippingUserGroup) {
      AgFns.selectGridRowByKey(
        this.sugGridOptions,
        (e: ShippingUserGroup) => e.id,
        errEnt.id,
        error.propertyName
      );
    }
  }
}
