<ag-grid-angular 
  
  class="prox-ag-grid-list" 
  [ngClass]="userPrefsService.gridTheme"
  [rowHeight]="27" 
  animateRows
  [style]="style"
  [pagination]="hasFooter" 
  [gridOptions]="gridOptions" 
  [rowData]="rowData"
  [rowClassRules]="rowClassRules"
  >
</ag-grid-angular>
<div *ngIf="hasFooter" class="grid-footer" >
  <a *ngIf="hasColSizeButtons" (click)="this.gridOptions.columnApi?.autoSizeAllColumns()">[ Autosize Columns ]</a>
  <a *ngIf="hasColSizeButtons" class="ml-12" (click)="this.gridOptions.api?.sizeColumnsToFit()">[ Size to Fit ]</a>
  <a class="ml-12" [hidden]="!hasFilters()" (click)="this.clearAllFilters()">[ Clear all filters ]</a>
</div>  
