/* eslint-disable @typescript-eslint/no-unused-vars */

import { CellValueChangedEvent, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProxAgFns, UtilDialogService } from '@core';
import { AccountIssuance, AccountIssuanceUserGroupMap, Program, ProgramAccountIssuanceMap, ProgramAllowance, ProgramIssuance, ProgramIssuanceUserGroupMap, ProgramProductTypeTag, ProgramUserGroup, ProgramViolationRule } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { UtilFns } from '@utils';
import { ToastrService } from 'ngx-toastr';
import { Entity } from 'breeze-client';


@Component({
  selector: 'prox-program-issuances-sub',
  templateUrl: './program-issuances.sub.component.html',
})
export class ProgramIssuancesSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;

  piGridOptions: GridOptions = {};
  programIssuances: ProgramIssuance[] = [];
  selectedPi?: ProgramIssuance;

  aiGridOptions: GridOptions = {};
  accountIssuances: AccountIssuance[] = [];
  selectedAi?: AccountIssuance;

  availablePiPugs: ProgramUserGroup[] = [];
  piPugGridOptions: GridOptions = {};

  availableAiPugs: ProgramUserGroup[] = [];
  aiPugGridOptions: GridOptions = {};

  programViolationRules!: ProgramViolationRule[];
  programProductTypeTags: ProgramProductTypeTag[] = [];

  tabViewIndex = 0;

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  async ngOnInit() {

    this.programProductTypeTags = this.program.account.programProductTypeTags.slice();
    this.programProductTypeTags.unshift({
      id: UtilFns.EmptyGuid,
      name: '- None -',
    } as ProgramProductTypeTag);


    this.piGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onPiGridReady,
        onRowSelected: this.onPiRowSelected,
        onCellValueChanged: this.onPiCellValueChanged,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is ProgramIssueance
        getRowId: (params) => params.data.id,
      },
    );

    this.aiGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onAiGridReady,
        onRowSelected: this.onAiRowSelected,
        onCellValueChanged: this.onAiCellValueChanged,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is AccountIssueance
        getRowId: (params) => params.data.id,
      },
    );

    this.piPugGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onPiAugGridReady,
        onCellValueChanged: this.onPiAugCellValueChanged,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is ProgramUserGroup
        getRowId: (params) => params.data.id,
      },
    );

    this.aiPugGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onAiAugGridReady,
        onCellValueChanged: this.onAiAugCellValueChanged,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is ProgramUserGroup
        getRowId: (params) => params.data.id,
      },
    );

    
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    this.programViolationRules = await this.dbQueryService.getAll(
      ProgramViolationRule
    );

    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programIssuances");
    if (isLoaded && !forceRefresh) {
      this.programIssuances = this.program.programIssuances;
    } else {
      this.programIssuances = await this.dbQueryService.getProgramIssuances(this.program.id);
      this.accountIssuances = await this.dbQueryService.getAccountIssuances(this.program.accountId);

      this.program.entityAspect.markNavigationPropertyAsLoaded('programIssuances');
      this.program.account.entityAspect.markNavigationPropertyAsLoaded('accountIssuances');

      // can happen if copying a program
      if (this.selectedPi?.programId != this.program.id) {
        this.selectedPi = undefined;
      }

      this.refreshAvailablePiPugs(this.selectedPi);
      
      // needed to refresh product type tag dropdowns.
      this.onPiGridReady();
      AgFns.refreshGrid(this.piGridOptions, this.programIssuances);
    }

    AgFns.sizeColumnsToFit(this.piGridOptions);

    AgFns.selectDefaultRow(this.piGridOptions);
  }

  onTabChange(x: any) {
    //
  }

  onPiGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'name',
        editable: !this.parent.isReadOnly(),
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Program Product Tag',
        editable: !this.parent.isReadOnly(),
        width: 200,
        filter: 'agTextColumnFilter',
        ...AgFns.createDropdownEditor('programProductTypeTagId', this.programProductTypeTags),
        colId: 'productTypeTag',
      },
      
      {
        headerName: 'Min Qty',
        field: 'minimumQty',
        editable: !this.parent.isReadOnly(),
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Max Qty',
        field: 'maximumQty',
        editable: !this.parent.isReadOnly(),
        filter: 'agNumberColumnFilter',
      },
      this.parent.ifEditable(ProxAgFns.getEntityDeleteColDef(this.onPiDelete.bind(this))),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.piGridOptions, colDefs, sortModel);

  }

  onPiAugGridReady() {
    const colDefs = [
      {
        headerName: 'Applies to Group',
        field: 'name',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          ProgramIssuanceUserGroupMap,
          this.dbQueryService.uow,
          'Is Included?',
          (e) => {
            return (
              this.selectedPi?.programIssuanceUserGroupMaps.find(
                (x) => x.programUserGroupId == e.data.id
              ) != null
            );
          },
          (e) => {
            return {
              programIssuanceId: this.selectedPi?.id,
              programUserGroupId: e.data.id,
            };
          },
          !this.parent.isReadOnly()
        ),
      },
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.piPugGridOptions, colDefs, sortModel);
  }

  onPiRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const pi = event.data as ProgramIssuance;
    if (pi == null) return;
    this.selectedPi = pi;
    if (pi.entityAspect.entityState.isDeleted()) {
      this.selectedPi = undefined;
    }

    this.refreshAvailablePiPugs(this.selectedPi);
  }

  async onPiAdd() {
    if (!this.programIssuances) return;

    const pi = this.dbSaveService.createEntity(ProgramIssuance, {
      programId: this.program.id,
    });
    pi.entityAspect.validateEntity();

    this.programIssuances?.push(pi);
    AgFns.refreshGrid(this.piGridOptions, this.programIssuances);
    AgFns.selectGridRowByKey(
      this.piGridOptions,
      (e: ProgramIssuance) => e.id,
      pi.id,
      'name'
    );
  }

  async onPiDelete(pi: ProgramIssuance) {
    if (!this.programIssuances) return;

    UtilFns.assertNonNull(pi, 'Product Group should have been found');
    const ok = await this.dialogService.askIfOk(
      'Confirm Delete',
      `You will delete this Program Issuance record along with all job order logs associated with it.  THIS IS NOT REVERSIBLE.  Are you sure?`
    );
    if (!ok) return;
    const logs = await this.dbQueryService.getProgramIssuanceUserLogsByProgramIssuanceId(pi.id);
    logs.forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    pi.programIssuanceUserGroupMaps.slice().forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(pi.entityAspect);

    // for display
    _.remove(this.programIssuances, pi);
    AgFns.refreshGrid(this.piGridOptions, this.programIssuances);
    this.refreshAvailablePiPugs(undefined);
    //TODO: select 'next' row row
  }

  onPiCellValueChanged(event: CellValueChangedEvent) {
    if (event.colDef.colId == 'productTypeTag') {
      this.selectedPi?.programIssuanceUserGroupMaps
        .slice()
        .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
      this.refreshAvailablePiPugs(this.selectedPi);
    }
  }

  onPiAugCellValueChanged(event: CellValueChangedEvent) {
    if (this.selectedPi?.programIssuanceUserGroupMaps.length == 0) {
      this.toastr.warning('At least one Account User Group must be selected');
    }
  }

  refreshAvailablePiPugs(iss?: ProgramIssuance) {
    if (iss == null || iss.programProductTypeTag == null) {
      this.availablePiPugs = [];
    } else {
      const usedPugIds = new Set(
        _.flatten(
          this.program.programIssuances
            // all issuances with same tag as this one
            .filter(
              (x) => x.programProductTypeTagId == iss.programProductTypeTagId
            )
            .map((x) =>
              x.programIssuanceUserGroupMaps.map((x) => x.programUserGroupId)
            )
        )
      );

      const pugs = this.program.approvalTree.approvalTreeUserGroups.map(x => x.programUserGroup);
      const availablePugs = pugs.filter((x) => !usedPugIds.has(x.id));

      const currentPugs = iss.programIssuanceUserGroupMaps.map(x => x.programUserGroup);
      availablePugs.push(...currentPugs);
      this.availablePiPugs = availablePugs;
    }
    AgFns.refreshGrid(this.piPugGridOptions, this.availablePiPugs, 'sizeToFit');
    return this.availablePiPugs;
  }

  // --------------------------------------------------


  onAiGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          ProgramAccountIssuanceMap,
          this.dbQueryService.uow,
          'Is Included?',
          (e) => {
            return (
              e.data.programAccountIssuanceMaps.find(
                (x) => x.programId == this.program.id
              ) != null
            );
          },
          (e) => {
            return {
              programId: this.program.id,
              accountIssuanceId: e.data.id,
            };
          },
          !this.parent.isReadOnly()
        ),
      },
      { headerName: 'Name', field: 'name', editable: !this.parent.isReadOnly(), filter: 'agTextColumnFilter', },
      {
        headerName: 'Program Product Tag',
        editable: !this.parent.isReadOnly(),
        width: 200,
        filter: 'agTextColumnFilter',
        ...AgFns.createDropdownEditor('programProductTypeTagId', this.programProductTypeTags),
        colId: 'productTypeTag',
      },
      
      { headerName: 'Max Qty', field: 'maximumQty', editable: !this.parent.isReadOnly(), filter: 'agNumberColumnFilter', },
      { headerName: 'Timeframe Days', field: 'timeframeDays', editable: !this.parent.isReadOnly(), filter: 'agNumberColumnFilter', },
      this.parent.ifEditable(ProxAgFns.getEntityDeleteColDef(this.onAiDelete.bind(this))),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.aiGridOptions, colDefs, sortModel);

  }

  onAiAugGridReady() {
    const colDefs = [
      {
        headerName: 'Applies to Group',
        field: 'name',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          AccountIssuanceUserGroupMap,
          this.dbQueryService.uow,
          'Is Included?',
          (e) => {
            return (
              this.selectedAi?.accountIssuanceUserGroupMaps.find(
                (x) => x.programUserGroupId == e.data.id
              ) != null
            );
          },
          (e) => {
            return {
              accountIssuanceId: this.selectedAi?.id,
              programUserGroupId: e.data.id,
            };
          },
          !this.parent.isReadOnly()
        ),
      },
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.aiPugGridOptions, colDefs, sortModel);
  }

  onAiRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const iss = event.data as AccountIssuance;
    if (iss == null) return;
    this.selectedAi = iss;
    if (iss.entityAspect.entityState.isDeleted()) {
      this.selectedAi = undefined;
    }

    this.refreshAvailableAiPugs(this.selectedAi);
  }

  async onAiAdd() {
    if (!this.accountIssuances) return;

    const iss = this.dbSaveService.createEntity(AccountIssuance, {
      accountId: this.program.accountId
    });
    iss.entityAspect.validateEntity();

    this.accountIssuances?.push(iss);
    AgFns.refreshGrid(this.aiGridOptions, this.accountIssuances);
    AgFns.selectGridRowByKey(
      this.aiGridOptions,
      (e: AccountIssuance) => e.id,
      iss.id,
      'name'
    );
  }

  async onAiDelete(ai: AccountIssuance) {
    if (!this.accountIssuances) return;

    UtilFns.assertNonNull(ai, 'Product Group should have been found');
    const ok = await this.dialogService.askIfOk(
      'Confirm Delete',
      `This will delete this AccountIssuance along with all job order logs associated with it. Are you sure?`
    );
    if (!ok) return;
    const logs = await this.dbQueryService.getAccountIssuanceUserLogsByAccountIssuanceId(ai.id);
    logs.forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    
    ai.accountIssuanceUserGroupMaps.slice().forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(ai.entityAspect);

    // for display
    _.remove(this.accountIssuances, ai);
    AgFns.refreshGrid(this.aiGridOptions, this.accountIssuances);
    this.refreshAvailableAiPugs(undefined);
    //TODO: select 'next' row row
  }

  onAiCellValueChanged(event: CellValueChangedEvent) {
    if (event.colDef.colId == 'productTypeTag') {
      this.selectedAi?.accountIssuanceUserGroupMaps
        .slice()
        .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
      this.refreshAvailableAiPugs(this.selectedAi);
    }
  }

  onAiAugCellValueChanged(event: CellValueChangedEvent) {
    if (this.selectedAi?.accountIssuanceUserGroupMaps.length == 0) {
      this.toastr.warning('At least one user group must be selected.');
    }
  }

  refreshAvailableAiPugs(iss?: AccountIssuance) {
    if (iss == null || iss.programProductTypeTag == null) {
      this.availableAiPugs = [];
    } else {
      const usedPugIds = new Set(
        _.flatten(
          this.program.account.accountIssuances.filter(
            (x) => x.programProductTypeTagId == iss.programProductTypeTagId
          )
            .map((x) =>
              x.accountIssuanceUserGroupMaps.map(y => y.programUserGroupId)
            )
        )
      );

      if (this.program.approvalTree && this.program.approvalTree.approvalTreeUserGroups && iss.accountIssuanceUserGroupMaps) {
        const pugs = this.program.approvalTree.approvalTreeUserGroups.map(x => x.programUserGroup);
        const availablePugs = pugs.filter((x) => !usedPugIds.has(x.id));
        const currentPugs = iss.accountIssuanceUserGroupMaps.map(x => x.programUserGroup);
        availablePugs.push(...currentPugs);
        this.availableAiPugs = availablePugs;
      }
    }
    AgFns.refreshGrid(this.aiPugGridOptions, this.availableAiPugs, 'sizeToFit');
    return this.availableAiPugs;
  }

  // -------------------------------------------------------------------------

  // TODO: handle accountIssuances
  async markError(errEnt: Entity, propName?: string) {
    const ent = errEnt as ProgramIssuance;
    AgFns.selectGridRowByKey(
      this.piGridOptions,
      (e: ProgramIssuance) => e.id,
      ent.id,
      propName
    );
  }
}
