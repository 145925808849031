<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="mb-2">
    <button (click)="onAddrAdd()" pButton type="button" label="Add Billing Address" icon="pi pi-plus"
      class="p-button-sm" [disabled]="isReadOnly()"></button>
    <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>   
  </div>
  <div class="flex-grow-1 mt-2">
    <prox-ag-grid [gridOptions]="addrGridOptions" [rowData]="addrs"></prox-ag-grid>
  </div>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>