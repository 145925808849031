/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColDef, ValueGetterParams, ValueSetterParams } from "@ag-grid-community/core";
import { Feature, FeatureChoice, JobOrder, JobOrderDetail, JobOrderDetailAddon, 
  PricedProductTypeFeatureChoice, Product, ProductType, ProductTypeConfigAddon, ProductTypeConfigProduct } from "@models";
import { UtilFns } from "@utils";
import * as _ from "lodash-es";

type GetProductFn = (params: any) => Product;

type FormatFeatureChoiceFn = (product: Product, featureName: string, featureChoiceValue: string) => string;

export interface JobOrderPriceInfo {
  jobOrder: JobOrder,
  
}

export interface JobOrderDetailPriceInfo {
  jobOrderDetail: JobOrderDetail,
  productTypeConfigProduct: ProductTypeConfigProduct,
  
  // Not available on version with data from JobOrderDetailPrice
  pricedProductTypeFeatureChoices: PricedProductTypeFeatureChoice[]
  jobOrderDetailAddons: JobOrderDetailAddon[]
  basePrice: number,
  totalFeaturesPrice: number,
  totalAddonsPrice: number,
  totalUnitPrice: number;
  extendedPrice: number;
  returnPolicyId: string;
  reissuancePrice?: number;
}

export interface ProductTypeConfigPriceInfo {
  pricedProductTypeFeatureChoices: PricedProductTypeFeatureChoice[]
  // productTypeConfigAddons: ProductTypeConfigAddon[]
  basePrice: number,
  totalFeaturesPrice: number;
  returnPolicyId: string;
  reissuancePrice?: number;
}

export class ProductFns {

  static getJobOrderDetailPriceInfo(jod: JobOrderDetail) {
    if (jod.productTypeConfigProduct == null) {
      return undefined;
    }
    const jodPriceInfo =  {} as JobOrderDetailPriceInfo;
    jodPriceInfo.jobOrderDetail = jod;    
    jodPriceInfo.jobOrderDetailAddons = jod.jobOrderDetailAddons;
    
    const ptcPriceInfo = this.getProductTypeConfigPriceInfo(jod.productTypeConfigProduct);
    jodPriceInfo.returnPolicyId = ptcPriceInfo.returnPolicyId;
    // producTypeConfigAddons no longer needed - but needed to use it to isolate the rest of ptcPriceInfo.
    Object.assign(jodPriceInfo, ptcPriceInfo);
    jodPriceInfo.totalAddonsPrice = _.sum(jod.jobOrderDetailAddons.map(x => x.pricedAddon.price));

    
    jodPriceInfo.totalUnitPrice = jodPriceInfo.basePrice + jodPriceInfo.totalFeaturesPrice + jodPriceInfo.totalAddonsPrice;
    jodPriceInfo.extendedPrice = jodPriceInfo.totalUnitPrice * jod.qty;
    jodPriceInfo.reissuancePrice = ptcPriceInfo.reissuancePrice;
    return jodPriceInfo;
  }

  static getProductTypeConfigPriceInfo(ptcProduct: ProductTypeConfigProduct) {
    
    const ptc = ptcProduct.productTypeConfig;
    const basePrice = ptc.pricedProductType.basePrice;
    const allPptFeatureChoices = ptc.pricedProductType.pricedProductTypeFeatureChoices;

    const product = ptcProduct.product;
    const pptFeatureChoices: PricedProductTypeFeatureChoice[] = [];
    product.productFeatureChoices.forEach(pfc => {
      const pptFeatureChoice = allPptFeatureChoices.find(x => x.featureChoiceId == pfc.featureChoiceId);
      if (pptFeatureChoice != null) {
        pptFeatureChoices.push(pptFeatureChoice);
      }
    });
    const totalFeaturesPrice = _.sum(pptFeatureChoices.map(x => x.price));

    return {
      pricedProductTypeFeatureChoices: pptFeatureChoices,
      basePrice: basePrice,
      totalFeaturesPrice: totalFeaturesPrice,
      returnPolicyId: ptc.returnPolicyId,
      reissuancePrice: ptc.pricedProductType.reissuancePrice,
    } as ProductTypeConfigPriceInfo;

  }

  static getAllFeaturesColDef(getProductFn: GetProductFn = (params) => params.data as Product, maxWidth: number = 300) {
    return { 
      headerName: 'All Features',
      colId: 'f-all-features', 
      editable: false,
      sortable: false,
      maxWidth: maxWidth,
      valueGetter: (params) => {
          const prod = getProductFn(params);
          if (prod == null) return '';
          const s =  prod.productFeatureChoices.map(pfc => pfc.featureChoice.feature.getDisplayName() + ': ' + pfc.featureChoice.validValue).join(', ');
          return s;
      }
    }
  }

  static getAllFeaturesFromProduct(product: Product) {
    if (!product) return '';
    return product.productFeatureChoices.map(pfc => pfc.featureChoice.feature.getDisplayName() + ': ' + pfc.featureChoice.validValue).join(', ');
  }

  static getColDefsForProductType(productType?: ProductType, getProductFn: GetProductFn = (params) => params.data as Product) {
    if (productType == null) {
      return [] as ColDef[];
    }
    const features = productType.productTypeFeatures.map(ptf => ptf.feature);
    const colDefs = this.getFeatureColDefs(features, getProductFn)
    return colDefs;
  }

  static getFeatureColDefs(features: Feature[], getProductFn: GetProductFn = (params) => params.data as Product) {
    const featureColDefs = features.map(x => this.createFeatureColDef(x, getProductFn));
    return featureColDefs;
  }

  static createFeatureColDef(feature: Feature, getProductFn: GetProductFn = (params) => params.data as Product) {
    return { 
      headerName: 'Feature: ' + feature?.getDisplayName(), 
      colId: 'f' + feature?.name, 
      editable: false,
      sortable: false,
      valueGetter: (params) => {
          const prod = getProductFn(params) 
          if (prod == null) return;
          const pfc =  prod.productFeatureChoices.find(fc => fc.featureChoice.featureId == feature.id);
          return pfc?.featureChoice.validValue ?? '';
      }
    } as ColDef
  }
}