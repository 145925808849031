import { Injectable } from "@angular/core";
import { SupplierUnitOfWork } from "@data";
import { AccountAdmin, AccountUser, ProximityRightEnum, SupplierAdmin } from '@models';
import { AuthService, Roles } from "../../services/auth.service";
import { UtilDialogService } from "../../services/dialog.service";
import { CoreAccountFinderDialog } from '../user/core-account-finder.dialog';
import { CoreSupplierFinderDialog } from '../user/core-supplier-finder.dialog';
import { CoreUserFinderDialog } from "../user/core-user-finder.dialog";
import { ImpersonateDialogComponent } from "./impersonate-dialog.component";
import { MessageBusService, MessageTypeEnum } from "../../services/message-bus.service";

@Injectable({ providedIn: 'root' })
export class ImpersonationService {
  constructor(private uow: SupplierUnitOfWork, private authService: AuthService, private dialogService: UtilDialogService,
    private messageBusService: MessageBusService
  ) {}

  async openDialog() {
    let ref: any;
    return new Promise(resolve => {
      ref = this.dialogService.pngDialogService.open(ImpersonateDialogComponent, {
        data: { impersonationService: this },
        header: 'Impersonate User',
        width: '30rem',
        contentStyle: { height: '20rem' },
        draggable: true,
        modal: false,
        resizable: true,
      });

      ref.onClose.subscribe(() => {
        resolve(true);
      });    
    });

  }

  canImpersonateAccountUser() {
    return this.authService.getUser()?.hasOrigRole(Roles.SuperUser, Roles.AccountAdmin);
  }

  canImpersonateAccountAdmin() {
    const user = this.authService.getUser();
    return user?.hasOrigRole(Roles.SuperUser) || user?.hasRight(ProximityRightEnum.CanImpersonateOtherAdmins);
  }

  canImpersonateSupplierAdmin() {
    return this.authService.getUser()?.hasOrigRole(Roles.SuperUser);
  }

  async impersonateAccountUser(): Promise<void> {
    const accountId = await this.getAccountId();
    if (accountId) {
      const allAccountUsers = await this.uow.createQuery(AccountUser).where({ accountId }).execute();
      return this.selectUser(allAccountUsers, 'Select an Account User');
    }
  }

  async impersonateAccountAdmin(): Promise<void> {
    const accountId = await this.getAccountId();
    if (accountId) {
      const allAccountUsers = await this.uow.createQuery(AccountAdmin).where({ accountId }).execute();
      return this.selectUser(allAccountUsers, 'Select an Account Admin');
    }
  }

  async impersonateSupplierAdmin(): Promise<void> {
    const suppliers = await this.dialogService.createFinder(CoreSupplierFinderDialog, { rowSelection: 'single' });
    if (suppliers.length) {
      const allSupplierUsers = await this.uow.createQuery(SupplierAdmin).where({ supplierId: suppliers[0].id }).execute();
      return this.selectUser(allSupplierUsers, 'Select a Supplier Admin');
    }
  }

  /** If current user belongs to an account, return that accountId; else show an account finder dialog */
  private async getAccountId() {
    let accountId = this.authService.getUser()?.accountId;
    if (!accountId) {
      const accounts = await this.dialogService.createFinder(CoreAccountFinderDialog, { rowSelection: 'single' });
      if (accounts.length) {
        accountId = accounts[0].id;
      }
    }
    return accountId;
  }

  private async selectUser(allUsers: any[], title?: string) {
    const selectedUsers = await this.dialogService.createFinder(CoreUserFinderDialog, {
      users: allUsers,
      rowSelection: 'single',
      title: title
    });
    if (selectedUsers.length) {
      await this.authService.impersonateUser(selectedUsers[0].proximityUserId);
      
      
      location.reload();
      
    }

  }

  stopImpersonation(): void {
    this.authService.stopImpersonation();
  }
}
