<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Allowances</h5>
  </div>

  <div class="h-full flex flex-column ">
    <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
      <ng-template pTemplate>
        <div class="h-full w-full flex flex-column ">
          <div class="flex flex-row gap-2">
            <div class="w-4 pr-4">
              <prox-field label="On Allowance Violation">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="programViolationRules" optionLabel="description"
                  optionValue="id" [(ngModel)]="program.allowanceProgramViolationRuleId" [bzModel]="program"
                  name="allowanceProgramViolationRuleId"></p-dropdown>
              </prox-field>
            </div>
            <div class="w-4 pr-4">
              <prox-field label="Allowance Proration Rule (all allowances in program)">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="allowanceProrationRules" optionLabel="name"
                  optionValue="id" [(ngModel)]="program.allowanceProrationRuleId" [bzModel]="program"
                  name="allowanceProrationRuleId"></p-dropdown>
              </prox-field>
            </div>
            <div class="w-4">
              <prox-field label="Next Program Allowance Rule (all allowances in program)">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="nextProgramAllowanceRules" optionLabel="name"
                  optionValue="id" [(ngModel)]="program.nextProgramAllowanceRuleId" [bzModel]="program"
                  name="nextProgramAllowanceRuleId"></p-dropdown>
              </prox-field>
            </div>
          </div>
          <div class="flex">
            <div class="w-2 pt-4 pb-3">
              <button [disabled]="parent.isReadOnly()" (click)="onPaAdd()" pButton type="button" label="Add Allowance"
                icon="pi pi-plus" class="p-button-sm"></button>
            </div>
          </div>
          <div class="flex-grow-1">
            <prox-ag-grid [gridOptions]="paGridOptions" [rowData]="programAllowances"></prox-ag-grid>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate>
        <p-splitter layout="horizontal" [panelSizes]="[40, 60]">
          <ng-template pTemplate>
            <div class="flex-grow-1">
              <prox-ag-grid [gridOptions]="paPugGridOptions" [rowData]="availablePaPugs"></prox-ag-grid>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <p-tabView class="pt-1 flex-grow-1 w-full prox-tabview">
              <p-tabPanel header="Feature Price Exclusions">
                <div class="flex flex-column w-full h-full">
                  <div class="flex-grow-1 w-full h-full">
                    <prox-ag-grid [gridOptions]="paFeatureGridOptions" [rowData]="paFeatures"></prox-ag-grid>
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Add-On Price Exclusions">
                <div class="flex-grow-1 w-full h-full">
                  <prox-ag-grid [gridOptions]="paAddonGridOptions" [rowData]="paAddons"></prox-ag-grid>
                </div>
              </p-tabPanel>
            </p-tabView>
          </ng-template>
        </p-splitter>
      </ng-template>
    </p-splitter>
  </div>
</div>