<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="flex mb-2">
    <button (click)="onTagAdd()" pButton type="button" label="Add Product Tag" icon="pi pi-plus"
      class="p-button-sm" [disabled]="isReadOnly()"></button>
    <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>              
  </div>
  <p-splitter class="flex-grow-1 w-full" layout="vertical" styleClass="h-full" [panelSizes]="[60, 40]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="tagGridOptions" [rowData]="tags"></prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate>

      <div class="flex flex-column w-full h-full">
        <div class="flex">
          <p-button [disabled]="!selectedTag || isReadOnly()" (click)="onTmapAdd()" styleClass="p-button p-button-sm"
            label="Assign Product Types" icon="pi pi-plus" class="p-button-sm"></p-button>
        </div>
        <div class="flex-column pt-2 pb-2 w-full h-full">
          <prox-ag-grid [gridOptions]="tmapGridOptions" [rowData]="tmaps">
          </prox-ag-grid>
        </div>
      </div>
    </ng-template>
  </p-splitter>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" [isReadOnly]="isReadOnly()" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>