
<div class="h-full flex flex-column">

  <div class="flex html-editor-menu-bar">
    <div *ngIf="!isTextOnly">
      <button [disabled]="isDisabled" title="Bold" (click)="foc.toggleBold().run()" [ngClass]="{ 'is-active': editor.isActive('bold') }" class="font-bold">B</button>
      <button [disabled]="isDisabled" title="Italic" (click)="foc.toggleItalic().run()" [ngClass]="{ 'is-active': editor.isActive('italic') }" class="font-italic">I</button>
      <button [disabled]="isDisabled" (click)="foc.toggleUnderline().run()" [ngClass]="{ 'is-active': editor.isActive('underline') }" class="underline">U</button>
      <button [disabled]="isDisabled" title="Strikethrough" (click)="foc.toggleStrike().run()" [ngClass]="{ 'is-active': editor.isActive('strike') }" class="line-through">S</button>
  
      <p-menu #headingMenu [model]="headingItems" [popup]="true" appendTo="body" proxKeyMenu></p-menu>
      <button [disabled]="isDisabled" (click)="headingMenu.toggle($event)" [ngClass]="{ 'is-active': editor.isActive('heading') }">Heading<i class="pi pi-chevron-down ml-1"></i></button>
      
      <!-- <button [disabled]="isDisabled" (click)="foc.toggleHeading({ level: 1 }).run()" [ngClass]="{ 'is-active': editor.isActive('heading', { level: 1 }) }">H1</button>
      <button [disabled]="isDisabled" (click)="foc.toggleHeading({ level: 2 }).run()" [ngClass]="{ 'is-active': editor.isActive('heading', { level: 2 }) }">H2</button>
      <button [disabled]="isDisabled" (click)="foc.toggleHeading({ level: 3 }).run()" [ngClass]="{ 'is-active': editor.isActive('heading', { level: 3 }) }">H3</button>
      <button [disabled]="isDisabled" (click)="foc.toggleHeading({ level: 4 }).run()" [ngClass]="{ 'is-active': editor.isActive('heading', { level: 4 }) }">H4</button>
      <button [disabled]="isDisabled" (click)="foc.toggleHeading({ level: 5 }).run()" [ngClass]="{ 'is-active': editor.isActive('heading', { level: 5 }) }">H5</button> -->
  
      <!-- <button [disabled]="isDisabled" title="Code" (click)="foc.toggleCode().run()" [ngClass]="{ 'is-active': editor.isActive('code') }">C</button>
      <button [disabled]="isDisabled" title="Code Block" (click)="foc.toggleCodeBlock().run()" [ngClass]="{ 'is-active': editor.isActive('codeBlock') }">CB</button> -->
  
      <button [disabled]="isDisabled" title="Block Quote" (click)="foc.toggleBlockquote().run()" [ngClass]="{ 'is-active': editor.isActive('blockQuote') }"><i class="fa-solid fa-indent"></i></button>
  
      <!-- <button [disabled]="isDisabled" title="Link" (click)="foc.setLink({ href: 'https://example.com', target: '_blank' }).run()" [ngClass]="{ 'is-active': editor.isActive('blockQuote') }"><i class="fa-solid fa-indent"></i></button> -->
  
      <button [disabled]="isDisabled" title="Bulleted List" (click)="foc.toggleBulletList().run()" [ngClass]="{ 'is-active': editor.isActive('bulletList') }"><i class="fa-solid fa-list-ul"></i></button>
      <button [disabled]="isDisabled" title="Numbered List" (click)="foc.toggleOrderedList().run()" [ngClass]="{ 'is-active': editor.isActive('orderedList') }"><i class="fa-solid fa-list-ol"></i></button>
  
      <p-menu #alignMenu [model]="alignItems" [popup]="true" appendTo="body" proxKeyMenu></p-menu>
      <button [disabled]="isDisabled" (click)="alignMenu.toggle($event)" [ngClass]="{ 'is-active': editor.isActive('textAlign') }">Align<i class="pi pi-chevron-down ml-1"></i></button>
  
      <!--
      <button [disabled]="isDisabled" (click)="foc.setTextAlign('left').run()" [ngClass]="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">B</button>
      <button [disabled]="isDisabled" (click)="foc.setTextAlign('center').run()" [ngClass]="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">B</button>
      <button [disabled]="isDisabled" (click)="foc.setTextAlign('right').run()" [ngClass]="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">B</button>
      <button [disabled]="isDisabled" (click)="foc.setTextAlign('justify').run()" [ngClass]="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">B</button>
      <button [disabled]="isDisabled" (click)="foc.toggleBold().run()" [ngClass]="{ 'is-active': editor.isActive('bold') }">B</button>
      <button [disabled]="isDisabled" (click)="foc.toggleBold().run()" [ngClass]="{ 'is-active': editor.isActive('bold') }">B</button> -->
  
      <button [disabled]="isDisabled" title="Horizontal Rule" (click)="foc.setHorizontalRule().run()" class="font-bold">&mdash;</button>
      <p-menu #tableMenu [model]="tableMenuItems" [popup]="true" appendTo="body" proxKeyMenu></p-menu>
      <button [disabled]="isDisabled" title="Table" (click)="tableMenu.toggle($event)" [ngClass]="{ 'is-active': editor.isActive('table') }"><i class="fa-solid fa-table"></i></button>
    </div>
    <button [disabled]="isDisabled" *ngIf="!!suggestItems" title="Variable" (click)="setSuggestMenu();templateMenu.toggle($event);" class="font-bold">{{</button>

    <!-- <button [disabled]="isDisabled" (click)="foc.setHardBreak().run()">HB</button> -->
    <!-- <button [disabled]="isDisabled" (click)="foc.toggleBold().run()" [ngClass]="{ 'is-active': editor.isActive('bold') }">B</button> -->
    <!-- <button [disabled]="isDisabled" (click)="foc.toggleBold().run()" [ngClass]="{ 'is-active': editor.isActive('bold') }">B</button> -->
    <button [disabled]="isDisabled" title="Undo" (click)="foc.undo().run()"><i class="fa-solid fa-arrow-rotate-left"></i></button>
    <button [disabled]="isDisabled" title="Redo" (click)="foc.redo().run()"><i class="fa-solid fa-arrow-rotate-right"></i></button>
    <button title="Source" (click)="toggleSource()" [ngClass]="{ 'is-active': isSource }" ><i class="fa-solid fa-code"></i></button>
  </div>

  <div class="flex-grow-1 border-200 border-2 border-round mb-3" style="overflow:auto; resize: both; height: 60px" > 
    <div *ngIf="!isSource" tiptap [editor]="editor" [(ngModel)]="value" class="h-full"></div>
    <div *ngIf="isSource" class="h-full w-full">
      <textarea disabled class="h-full w-full">
        {{editor.getHTML()}}
      </textarea>
    </div>
    <p-menu *ngIf="!!suggestItems" #templateMenu appendTo="body" styleClass="prox-menu" [model]="suggestItems" [popup]="true" proxKeyMenu></p-menu>
  </div>

</div>
