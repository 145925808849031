/* eslint-disable @nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { ChangePasswordComponent, LoginComponent, homeGuard } from '@core';
import { ProxHomeComponent } from './layout/prox.home.component';

const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  // enableTracing: true,
};

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [homeGuard],
        component: ProxHomeComponent,
      },
      {
        path: 'account',
        loadChildren: () => import('@account').then((m) => m.AccountModule),
      },
      {
        path: 'supplier',
        loadChildren: () => import('@supplier').then((m) => m.SupplierModule),
      },
      {
        path: 'transaction',
        loadChildren: () =>
          import('@account-user').then((m) => m.AccountUserModule),
      },
      {
        path: 'account-admin',
        loadChildren: () =>
          import('@account-admin').then((m) => m.AccountAdminModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  // { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
