<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [showBack]="true"></prox-main>
<ng-template #bodyTemplate>
  <div *ngIf="isPageReady" [innerHtml]="narrative"></div>

  <div class="narrative-subheader">
    <div [innerHtml]="titleHtml"></div>
  </div>
  <div [innerHTML]="subTitleHtml"></div>

  <div *ngIf="isSummary">
    <div class="narrative-subheader">Summary</div>
    <div><p [innerHtml]="summaryHtml"></p></div>
  </div>

  <div *ngIf="isStatus">
    <div class="narrative-subheader">Status</div>
    <div><p [innerHtml]="statusHtml"></p></div>
  </div>

  <div *ngIf="isTerm">
    <div class="narrative-subheader">Term</div>
    <div><p [innerHtml]="termHtml"></p></div>
  </div>

  <div *ngIf="isParameters">
    <div class="narrative-subheader">Program Parameters</div>
    <div *ngIf="program.programAllowances.length > 0">
      <div><p [innerHtml]="allowanceParametersHtml"></p></div>
      <div *ngFor="let allowance of program.programAllowances"> 
        <div class="narrative-sub2header">Allowance Plan: {{ allowance.name }}</div>
        <div><p [innerHTML]="getAllowance(allowance)"></p></div>
      </div>
    </div>

    <div *ngIf="program.programIssuances.length > 0">
      <div><p [innerHtml]="issuanceParametersHtml"></p></div>
      <div *ngFor="let issuance of program.programIssuances"> 
        <div class="narrative-sub2header">Issuance Plan: {{ issuance.name }}</div>
        <div><p [innerHTML]="getIssuance(issuance)"></p></div>
      </div>
    </div>
    <div *ngIf="program.programAllowances.length === 0 && program.programAllowances.length === 0">
      <div><p [innerHtml]="noParametersHtml"></p></div>
    </div>
  </div>

  <div *ngIf="isUserGroups">
    <div class="narrative-subheader">User Groups</div>
    <p>The following Proximity User Groups participate in the {{program.name}} Program.</p>
    <div style="max-width: 700px;">
      <p-table [value]="userGroupDtos" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th style="text-align: left; font-weight: bold; width: 300px;">User Group Name</th>
            <th style="text-align: left; font-weight: bold; width: 100px;">No. Users</th>
            <th style="text-align: left; font-weight: bold; width: 120px;">No. Excluded Users</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userGroupDto>
          <tr>
            <td>{{ userGroupDto.userGroupName }}</td>
            <td>{{ userGroupDto.userCount }}</td>
            <td>{{ userGroupDto.excludedUserCount }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>