import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { NotificationQueryEnum } from '@models';
import { startCase } from 'lodash';
import { firstValueFrom } from 'rxjs';

export interface TemplateItem {
  key: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})

// templateIndex(0)
export class TemplatesService {
  private varLookup: { [queryId: string]: string[] } = {};

  constructor(private httpClient: HttpClient) {
  }

  /** Get the template vars from the server and make menu items from them */
  async getNotificationMenuItems(queryId: NotificationQueryEnum) {
    const vars = await this.getNotificationVars(queryId);
    // vars ending with '+' indicate iterable items, e.g. orderDetails+
    return vars.map((x) => ({ automationId: x, label: x.endsWith('+') ? startCase(x) + ' (table)' : startCase(x), 
      style: x.endsWith('-') ? { 'padding-left': '1em' } : undefined
     }));
  }

  /** Get the template vars for the given query type */
  async getNotificationVars(queryId: NotificationQueryEnum) {
    const key = '' + queryId;
    let vars = this.varLookup[key];
    if (!vars) {
      const url = environment.accountUrl + '/GetNotificationVars?{}&queryId=' + queryId;
      vars = await firstValueFrom(this.httpClient.get<string[]>(url));
      this.varLookup[key] = vars;
    }
    return vars;
  }
}