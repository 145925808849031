<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <div class="flex mb-2">
    <button (click)="onPugAdd()" pButton type="button" label="Add Program User Group" icon="pi pi-plus"
      class="p-button-sm" [disabled]="isReadOnly()"></button>
      <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>      
  </div>
  <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="pugGridOptions"></prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <p-tabView class="pt-3 flex-grow-1 w-full prox-tabview" [(activeIndex)]="tabViewIndex"
        (onChange)="onTabChange($event)">
        <p-tabPanel header="Users">
          <div class="flex flex-column w-full h-full">
            <div class="flex my-2">
              <p-button [disabled]="!selectedPug || isReadOnly()" (click)="onPugmAdd()" styleClass="p-button p-button-sm"
                label="Add Users to Program User Group" icon="pi pi-plus" class="p-button-sm"></p-button>
            </div>
            <div class="flex-grow-1 pt-2 w-full h-full">
              <prox-ag-grid [gridOptions]="pugmGridOptions" [rowData]="pugms">
              </prox-ag-grid>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Budgets">
          <prox-budget-items [accountId]="accountId" [budgets]="programUserGroupBudgets" [joBudgetLogs]="joBudgetLogs"
            addLabel="Add Budget Items to Program User Group"
            [createEntityFn]="createPugBudget.bind(this)" [isReadOnly]="isReadOnly()"></prox-budget-items>
        </p-tabPanel>
      </p-tabView>
    </ng-template>
  </p-splitter>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>