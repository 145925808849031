/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValueGetterParams } from '@ag-grid-community/core';
import { AgFns } from './ag-fns';
import { EntityStateRendererComponent } from './entity-state-renderer.component';
//import * as _ from "lodash";
import { Entity } from 'breeze-client';
import {
  IEntityWithStatus,
  StatusService,
} from '../../services/status.service';
import { Product } from '@models';
import { AgButtonProps } from './button-renderer.component';

export class ProxAgFns {
  static getEntityStateColDef(
    getEntityFn?: (item: any) => Entity,
  ) {
    const cellRendererParams: any = {};
    if (getEntityFn) {
      cellRendererParams.getEntityFn = getEntityFn;
    }
    return {
      headerName: 'State',
      sortable: false,
      cellRenderer: EntityStateRendererComponent,
      cellRendererParams: cellRendererParams,
      minWidth: 55,
      maxWidth: 55
    };
  }

  static getEntityDeleteColDef<T>(
    deleteMethodBound: (arg: T) => void | Promise<void>,
    buttonProps?: AgButtonProps
  ) {
    return {
      ...AgFns.createButtonProps('', deleteMethodBound, {
        icon: 'pi pi-trash',
        ...buttonProps,
      }),
      minWidth: 40,
      maxWidth: 40,
    };
  }

  static getEntityStatusColDef<T>(
    statusMethodBound: (arg: T) => void | Promise<void>,
    buttonProps?: AgButtonProps,
  ) {
    return {
      ...AgFns.createButtonProps('', statusMethodBound, {
        icon: 'fa-regular fa-key',
        tooltip: 'Change Status',
        ...buttonProps,
      }),
      minWidth: 40,
      maxWidth: 40
    };
  }

  static getWorkingStatusDef(
    headerName: string,
    statusService: StatusService,
    entityFn: (p: ValueGetterParams) => IEntityWithStatus = (p) => p.data
  ) {
    return {
      headerName: headerName,
      valueGetter: (params: ValueGetterParams) => {
        return statusService.getWorkingStatus(entityFn(params)).shortDisplay;
      },
      width: 100,
      maxWidth: 140,
      sortable: false
    };
  }

  static getProductSortOrderColDef(
    productFn: (p: ValueGetterParams) => Product,
    colId = 'productSortOrder',
    shouldHide = true
  ) {
    return {
      colId: colId,
      headerName: 'Product Sort Order', // if not hidden
      hide: shouldHide,
      valueGetter: (params) => {
        const product = productFn(params);
        return product?.getSortOrder();
      },
    };
  }

  /** Move moveItem in the array before or after targetItem, and renumber the displayOrder values.
   * If targetItem is null, move moveItem to the end of the array.
   * Assumes items are already sorted by displayOrder. */
  static moveItemInDisplayOrder<T>(
    items: T[],
    fieldName: string,
    moveItem: T,
    targetItem?: T,
    isAfter = false
  ) {
    // set initial numbers if none exist
    if (items.every((i) => i[fieldName] === 0)) {
      this.renumberSortOrder(items, fieldName);
    }

    if (!targetItem) {
      // add moveItem to the end
      moveItem[fieldName] = items[items.length - 1][fieldName] + 1;
    } else {
      // move to just before targetItem.  Use backingStore to prevent rounding to int.
      const offset = isAfter ? 0.5 : -0.5;
      moveItem['_backingStore'][fieldName] = targetItem[fieldName] + offset;
    }
    items.sort((a, b) => a[fieldName] - b[fieldName]);
    this.renumberSortOrder(items, fieldName);
  }

  /** renumber the displayOrder of the rows, based on current sort */
  static renumberSortOrder<T>(items: T[], fieldName: string) {
    let i = 1;

    for (const me of items) {
      if (me[fieldName] !== i) {
        me[fieldName] = i;
      }
      i++;
    }
  }

  //// Old code for dragging and dropping rows to set display order column
  //// No longer used because it was too tedious to use for large lists. - Now we just edit the display order.

  // static getDisplayOrderColumnDef<T>(gridOptions: GridOptions, getItems: () => T[], fieldName: string, dragFieldPath: string): ColDef {
  //   if (!gridOptions.onRowDragEnd) {
  //     gridOptions.onRowDragEnd = (event: RowDragEvent) => {
  //       const items = getItems();
  //       this.sortDisplayOrderAfterDrag(items, fieldName, event);
  //     };
  //     gridOptions.rowDragText = (params) => {
  //       return _.get(params.rowNode?.data, dragFieldPath) ?? 'item';
  //     };
  //   }
  //   const onShift = (itemToMove: T, event?: CellClickedEvent, origEvent?) => {
  //     const items = getItems();
  //     this.moveItemUpOrDown(items, fieldName, itemToMove, event, origEvent);
  //   }
  //   return {
  //     field: fieldName, minWidth: 50, maxWidth: 50, ...AgFns.createIconProps('', 'pi pi-sort', onShift,
  //       { label: '', icon: 'pi pi-sort py-2', rowDrag: true },  ), sortable: false
  //     };
  // }

  // /** Move the item up or down in the display order */
  // private static moveItemUpOrDown<T>(items: T[], fieldName: string, moveItem: T, event?: CellClickedEvent, origEvent?: UIEvent) {
  //   // move down if click was in lower half of icon
  //   const y = (origEvent as PointerEvent).offsetY;
  //   const isDown = y > ((origEvent as any).target.offsetHeight / 2);

  //   const pos = items.findIndex(x => x === moveItem);
  //   if (isDown) {
  //     if (pos < 0 || pos > items.length - 2) {
  //       return;
  //     }
  //     this.moveItemInDisplayOrder(items, fieldName, moveItem, items[pos + 2]);
  //   } else {
  //     if (pos <= 0) {
  //       return;
  //     }
  //     this.moveItemInDisplayOrder(items, fieldName, moveItem, items[pos - 1]);
  //   }

  //   AgFns.refreshGrid(event?.context.gridOptions, items as object[]);
  //   event?.api.clearFocusedCell();
  // }

  /** Set the display order and sort the rows after a drag-drop move.
   * Assumes items are already in display order.
   */
  // private static sortDisplayOrderAfterDrag<T>(items: T[], fieldName: string, event: RowDragEvent) {
  //   event.api.refreshCells();
  //   const movingItem = event.node.data as T;
  //   const overItem = event.overNode?.data as T;

  //   // move after the overItem if the drop event was in the lower half of the row
  //   const isAfter = event.overNode && event.y - (event.overNode.rowTop || 0) > 15;

  //   this.moveItemInDisplayOrder(items, fieldName, movingItem, overItem, isAfter);

  //   // tell the grid to refresh
  //   AgFns.refreshGrid(event?.context.gridOptions, items as object[]);
  //   event?.api.clearFocusedCell();
  // }
}
