// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Addon } from './addon';
import { ProgramAllowance } from './program-allowance';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramAllowanceAddonException extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK programAllowance */
  programAllowanceId!: string;
  /** Guid key FK addon */
  addonId!: string;
  /** FK addonId */
  addon!: Addon;
  /** FK programAllowanceId */
  programAllowance!: ProgramAllowance;
}

