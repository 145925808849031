/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';
import { BaseService } from '@core';
import { UtilFns } from '@utils';
import * as _ from 'lodash';
import { EntityFns } from '@data';
import { EntityError } from 'breeze-client';
import { Account, AccountAddress } from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';


@Component({
  selector: 'prox-billing-account-addresses',
  templateUrl: './billing-account-addresses.component.html',
})
export class BillingAccountAddressesComponent extends SaveAndQueryComponent {
  supplierId!: string;
  accountId!: string;
  account!: Account;
  addrGridOptions!: GridOptions;
  addrs?: AccountAddress[];
  selectedAddr?: AccountAddress;
  editLabel = 'Edit';
  editLabelWidth = 50;
  
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.addrGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddrGridReady,
      onRowSelected: this.onAddrRowSelected,
      rowModelType: 'clientSide',
    });

    if (this.statusService.getWorkingStatus(this.account as any).isReadOnly) {
      this.editLabel = 'Read Only';
      this.editLabelWidth = 100;
    }

    AgFns.captureGridRouteParams(this.addrGridOptions, this.route, 'id');
    this.addrs = await this.dbQueryService.getBillingAccountAddresses(this.accountId);
    this.setTitle("Account Billing Addresses for " + this.account.name)
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  onAddrGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getAddrColDefsAndSortModel();
    // this.updateAslMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.refreshGrid(gridOptions, this.addrs);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getAddrColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { ...AgFns.createButtonProps('', this.onAddrEdit.bind(this), { label: this.editLabel }), minWidth: this.editLabelWidth, maxWidth: this.editLabelWidth },
      { headerName: 'Name', field: 'name',  filter: 'agTextColumnFilter' },
      { headerName: 'Line 1', field: 'line1', filter: 'agTextColumnFilter' },
      { headerName: 'Line 2', field: 'line2', filter: 'agTextColumnFilter' },
      { headerName: 'City', field: 'city', filter: 'agTextColumnFilter' },
      { headerName: 'State', field: 'state', filter: 'agTextColumnFilter' },
      { headerName: 'Zip', field: 'zipcode', filter: 'agTextColumnFilter' },
      ProxAgFns.getEntityDeleteColDef(this.onAddrDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }
  
  onAddrRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const addr = event.data as AccountAddress;
    if (addr == null) return;
    this.selectedAddr = addr;
    if (addr.entityAspect.entityState.isDeleted()) {
      this.selectedAddr = undefined;
      this.addrGridOptions.api?.deselectAll();
      return;
    }
    this.updateGridRouteParams(this.addrGridOptions, addr.id);
  }

  onAddrEdit(row: AccountAddress) {
    this.updateGridRouteParams(this.addrGridOptions, row.id);
    this.router.navigate([row.id, 'B'], { relativeTo: this.route });
  }

  async onAddrAdd() {
    this.router.navigate(['add', 'B'], { relativeTo: this.route });
  }

  async onAddrDelete(addr: AccountAddress) {

    // if it's an added record - cascade delete won't catch them.
    if (addr.entityAspect.entityState.isAdded()) {
      addr.shippingUserGroupAddressMaps.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(addr.entityAspect);
    this.selectedAddr = undefined;
    
    if (this.addrs) {
      _.remove(this.addrs, addr);
      AgFns.refreshGrid(this.addrGridOptions, this.addrs);
    }
  }

  
  // ---------------------------------------------------------------------------------------

  override async beforeSave() {
    // duplicate names are allowed. JM 3/5/24
    
/*     if (!this.addrs) return true;
    const dupNames = UtilFns.getDuplicates(this.addrs.map(x => x.name));
    if (dupNames.length > 0) {
      this.toastr.warning(`The following names are duplicates which are not allowed: ${dupNames.join(', ')} `);
      return false;
    } */
    return true;
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    if (this.addrs) {
      this.addrs = undefined;
    }

    this.addrs = await this.dbQueryService.getBillingAccountAddresses(this.accountId);
    if (this.selectedAddr == null || this.selectedAddr.entityAspect.entityState.isDetached()) {
      AgFns.selectFirstRow(this.addrGridOptions);
    }
    
    if (this.addrGridOptions.api == null) return;
    AgFns.refreshGrid(this.addrGridOptions, this.addrs);
    AgFns.selectGridRowByKey(this.addrGridOptions.api, e => e.id, this.selectedAddr?.id || '')

  } 

  override navigateToValidationError(error: EntityError, event: MouseEvent) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    if (errEnt instanceof AccountAddress) {
      AgFns.selectGridRowByKey(this.addrGridOptions, (e: AccountAddress) => e.id, errEnt.id);
    } 
  }
}
