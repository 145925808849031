import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DbQueryService, UnitOfWork } from '@data';
import { EntityError } from 'breeze-client';
import { sanitize } from 'dompurify';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HtmlEditorComponent } from '../editor/html-editor.component';
import { HelpService } from './help.service';

export interface HelpDialogData {
    /** Component that called the dialog.  Route is used to determine help content */
    parent: { selector: string, dbQueryService?: DbQueryService };

    /** Name of field on the page, for field-specific content; null/undefined for page content */
    field: string;

    /** For calling navigateToValidationError to use help page to activate field */
    callback?: (ee: EntityError, event: MouseEvent) => void;

    /** UOW for querying help items.  If not specified, uow is taken from dbQueryService of the parent */
    uow?: UnitOfWork;
}

@Component({
  selector: 'prox-help-dialog',
  template: `
  <button pButton *ngIf="canEdit()" (click)="edit()" class="p-button-rounded p-button-text" icon="pi pi-pencil" style="position: absolute; top: 1.5rem; right: 4rem"></button>
  <div class="h-full flex flex-column" >
    <div *ngIf="!isEdit" class="flex-grow-1" [innerHTML]="content">
    </div>
    <div *ngIf="isEdit" class="flex-grow-1">
      <prox-html-editor [suggestItems]="menuItems" [value]="template"></prox-html-editor>
    </div>
    <div *ngIf="isEdit" class="text-right">
      <button (click)="save()" pButton type="button" icon="pi pi-check" label="Save" class="p-button-raised p-button-sm mr-2"></button>
      <button (click)="cancelEdit()" pButton type="button" icon="pi pi-times" label="Cancel" class="p-button-raised p-button-sm"></button>
    </div>
  </div>`
})
export class HelpDialogComponent implements OnInit {
  @ViewChild(HtmlEditorComponent) htmlEditorComponent!: HtmlEditorComponent;

  /** Identifier for help content in the database. */
  key: string;
  /** html with placeholder tokens */
  template?: string;
  /** html with values merged */
  content?: SafeHtml;
  data: HelpDialogData;
  isEdit = false;
  menuItems: MenuItem[] = [];

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef, private domSanitizer: DomSanitizer, private helpService: HelpService) {
    this.data = dialogConfig.data;
    helpService.init(dialogConfig.data.uow || dialogConfig.data.parent.dbQueryService.uow);
    this.key = this.data.parent.selector;
  }

  async ngOnInit(): Promise<void> {
    this.menuItems = await this.helpService.getMenuItems();
    this.showContent();
  }

  async showContent(): Promise<void> {
    const item = await this.helpService.getItem(this.key, this.data.field);
    this.template = item?.html;
    const content = item?.mergedHtml ? sanitize(item.mergedHtml) : 'no content';
    this.content = this.domSanitizer.bypassSecurityTrustHtml(content);
  }

  canEdit() {
    return !this.isEdit && this.helpService.canEdit();
  }

  edit() {
    if (this.isEdit) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      setTimeout(() => this.htmlEditorComponent.value = this.template, 1);
    }
  }

  cancelEdit() {
    this.isEdit = false;
  }

  async save() {
    let value = this.htmlEditorComponent?.value;
    if (value) {
      value = sanitize(value);
      await this.helpService.save(this.key, this.data.field, value);
      await this.showContent();
    }
    this.cancelEdit();
  }

  onClick(ee: EntityError, event: MouseEvent) {
    if (this.data.callback) {
      this.data.callback(ee, event);
    }
  }

  close() {
    this.ref.close();
  }

}
