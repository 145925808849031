
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, EventScheduleDialog, ISortModel, ProxAgFns, RRuleFns, UtilDialogService } from '@core';
import { Manifest, Supplier, SupplierManifestMap, SupplierSubmissionStatusEnum } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { ToastrService } from 'ngx-toastr';
import { AccountAddressFinderDialog } from '../addresses/account-address-finder.dialog';
import { GridOptions, RowSelectedEvent, ValueGetterParams, ValueSetterParams } from '@ag-grid-community/core';
import * as _ from 'lodash';
import { EntityFns } from '@data';


@Component({
  selector: 'prox-program-billing-sub',
  templateUrl: './program-billing.sub.component.html'
})
export class ProgramBillingSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;

  suppliers: Supplier[] = [];
  manifestGridOptions: GridOptions = {};
  selectedManifest?: Manifest;

  supplierGridOptions: GridOptions = {};
  supplierChecklist: { supplier: Supplier, isChecked: boolean }[] = [];

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  get manifests() {
    return this.program?.manifests;
  }

  async ngOnInit() {
    this.suppliers = _.uniq(this.program.programProductTypeConfigs.map(x => x.productTypeConfig.pricedProductType.productType.supplier));

    this.manifestGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onManifestGridReady,
        onRowSelected: this.onManifestRowSelected,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is Manifest
        getRowId: (params) => params.data.id,
      },
    );

    this.supplierGridOptions = AgFns.initGridOptions(this,
      {
        onGridReady: this.onSupplierGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is Manifest
        getRowId: (params) => params.data.supplier.id,
      },
    );
    this.supplierChecklist = this.suppliers.map(x => {
      return {
        supplier: x,
        isChecked: false
      }
    });

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);
    AgFns.sizeColumnsToFit(this.manifestGridOptions);
  }


  async onSelectBillingAddress() {
    const addrs = await this.dialogService.createFinder(
      AccountAddressFinderDialog,
      {
        accountId: this.program.accountId,
        addressType: 'B',
        rowSelection: 'single',
      }
    );
    if (addrs.length > 0) {
      this.program.billingAccountAddressId = addrs[0].id;
    }
  }

  onManifestGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        editable: true,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Should Include Invoices',
        field: 'shouldIncludeInvoices',
        editable: true,
        width: 110
      },
      {
        ...AgFns.createButtonProps('Schedule', this.editManifestSchedule.bind(this), {
          label: 'Edit'
        }),
        width: 50,
      },
      {
        headerName: "",
        valueGetter: (params) => {
          const manifest = params.data as Manifest;
          return this.getManifestScheduleText(manifest);
        },
      },
      // { headerName: 'Suppliers',
      //   valueGetter: (params) => {
      //     const manifest = params.data as Manifest;
      //     const supplierNames = manifest.supplierManifestMaps.map(x => x.supplier.name);
      //     return supplierNames.join(', ');
      //   },
      // },


      ProxAgFns.getEntityDeleteColDef(this.deleteManifest.bind(this)),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];

    AgFns.initGrid(this.manifestGridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(this.manifestGridOptions);
  }

  async onManifestRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const manifest = event.data as Manifest;
    if (manifest == null) return;
    this.selectedManifest = manifest;
    this.supplierChecklist.forEach(x => {
      x.isChecked = manifest.supplierManifestMaps.some(y => y.supplierId == x.supplier.id)
    });
    AgFns.refreshGrid(this.supplierGridOptions, this.supplierChecklist);
  }

  onSupplierGridReady() {
    const colDefs = [
      {
        headerName: 'Included',
        field: 'isChecked',
        maxWidth: 110,
        editable: (params) => this.canEditSupplierMap(params.data.supplier),
        valueSetter: (p: ValueSetterParams) => {
          const item = p.data as { supplier: Supplier, isChecked: boolean };
          if (!item) return false;
          if (p.newValue && this.selectedManifest != null) {
            this.dbSaveService.createEntity(SupplierManifestMap, {
              supplierId: item.supplier.id,
              manifestId: this.selectedManifest.id
            });
            item.isChecked = true;
          } else {
            for (const map of this.selectedManifest?.supplierManifestMaps || []) {
              if (map.supplierId == item.supplier.id) {
                EntityFns.deleteOrDetach(map.entityAspect);
                item.isChecked = false;
              }
            };
          }
          return true;
        }
      },
      {
        headerName: 'Name',
        field: 'supplier.name',
      },
      {
        headerName: 'Status',
        valueGetter: (p: ValueGetterParams) => {
          const ok = this.canEditSupplierMap(p.data.supplier);
          return ok ? 'Editable' : 'Not editable - already sent to Supplier';
        }, 
        width: 200
      }
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];

    AgFns.initGrid(this.supplierGridOptions, colDefs, sortModel);

  }

  canEditSupplierMap(supplier: Supplier) {
    const sm = this.selectedManifest?.supplierManifestMaps.find(m => m.supplierId == supplier.id);
    if (sm == null) return true;
    return sm.entityAspect.entityState.isAdded() || this.selectedManifest?.supplierSubmissionStatusId == SupplierSubmissionStatusEnum.New;
  }

  addManifest() {
    const alreadyUsedSupplierIds = _.flatMap(this.program.manifests, x => x.supplierManifestMaps.map(y => y.supplierId));
    const remainingSuppliers = this.suppliers.filter(x => !alreadyUsedSupplierIds.includes(x.id));
    if (remainingSuppliers.length == 0) {
      this.dialogService.okDialog('Unable to add manifest', 'All of the suppliers for this program already have an associated manifest');
      return;
    }
    if (this.suppliers.length == this.program.manifests.length) {
      this.dialogService.okDialog('Unable to add manifest', 'You cannot have more manifests than suppliers for a single program');
      return;
    }
    const manifest = this.dbSaveService.createEntity(Manifest, {
      name: 'Manifest for: ' + this.program.name,
      programId: this.program.id,
      supplierSubmissionStatusId: SupplierSubmissionStatusEnum.New
    });
    remainingSuppliers.forEach(s => {
      this.dbSaveService.createEntity(SupplierManifestMap, {
        supplierId: s.id,
        manifestId: manifest.id
      });
    });
    AgFns.refreshGrid(this.manifestGridOptions, this.program.manifests, 'sizeToFit');
  }

  async deleteManifest(manifest: Manifest) {
    if (manifest.supplierSubmissionStatusId != SupplierSubmissionStatusEnum.New) {
      this.dialogService.okDialog('Cannot delete', 'This manifest has already been sent to at least one supplier.')
      return;
    }
    const supplierManifestMaps = manifest.supplierManifestMaps.slice();
    supplierManifestMaps.forEach(x => {
      EntityFns.deleteOrDetach(x.entityAspect);
    });
    EntityFns.deleteOrDetach(manifest.entityAspect);
    AgFns.refreshGrid(this.manifestGridOptions, this.program.manifests, 'sizeToFit');
  }

  async editManifestSchedule(manifest: Manifest) {
    const r = await EventScheduleDialog.openDialog(this.pngDialogService, {
      rruleString: manifest.rRule
    });
    if (r != null) {
      manifest.rRule = r;
    }
    AgFns.refreshGrid(this.manifestGridOptions, this.program.manifests, 'sizeToFit');
  }

  getManifestScheduleText(manifest: Manifest) {
    if (manifest?.rRule == null) {
      return ''
    }
    const rrule = RRuleFns.fromString(manifest.rRule);
    return RRuleFns.toText(rrule);
  }

}
