// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { NotificationTemplate } from './notification-template';
import { ProximityUser } from './proximity-user';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class InAppNotification extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** DateTimeOffset */
  createdTs!: Date;
  /** String */
  notificationMessage!: string;
  /** Guid FK notificationTemplate */
  notificationTemplateId?: string;
  /** Int32 */
  priority!: number;
  /** Guid FK proximityUser */
  proximityUserId!: string;
  /** String */
  queryContext?: string;
  /** Boolean */
  wasRead!: boolean;
  /** FK accountId */
  account!: Account;
  /** FK notificationTemplateId */
  notificationTemplate!: NotificationTemplate;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
}

