import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileGalleryDialogComponent, FileGalleryDialogData, FileService, UtilDialogService } from "@core";
import { AccountImage } from "@models";
import { ImageFns, ProxImage } from "@utils";
import { Entity } from "breeze-client";
import * as _ from "lodash-es";
import { AccountDbQueryService } from "./account-db-query.service";

interface RelatedAccountImage extends Entity {
  accountImageId: string;
  accountImage: AccountImage;
  isPrimary: boolean;
}

/** Handles image uploads and interactions with image gallery */
@Component({
  selector: 'prox-account-image-handler',
  template: `<div>
  <label pButton class="p-button-sm p-button-raised mr-2 mb-2" icon="pi pi-image">
    <span class="ml-2"> Choose images </span>
    <input style="width: 1px; height: 1px;" type="file" accept="image/png,image/jpeg,image/webp,.png,.jpeg,.jpg,.webp"  id="doc_file" #doc_file name="doc_file" multiple (change)="addFiles($event)"/>
  </label>
  <button (click)="openFileViewDialog()" [disabled]="!imageCount" pButton type="button" icon="pi pi-images" label="View {{ imageCount }} Images"
    class="p-button-sm p-button-raised mb-2"></button>
</div>
`
})
export class AccountImageHandlerComponent {
  /** Linking entity collection on the parent entity, e.g. manufacturer.manufacturerImages */
  @Input() oldImages!: RelatedAccountImage[];
  @Input() accountId!: string;
  /** Create the join entity between AccountImage and parent entity.  Example implementation:
   * this.dbSaveService.createEntity(ManufacturerImage, {
   *   manufacturerId: this.manufacturer.id,
   *   accountImageId: event.accountImageId,
   *   isPrimary: event.isPrimary
   * });
   */
  @Output() createJoinEntity = new EventEmitter<{ accountImageId: string, isPrimary: boolean }>();
  
  isChanged = false;
  newImages: ProxImage[] = [];

  constructor(
    private dbQueryService: AccountDbQueryService,
    private dialogService: UtilDialogService, 
    private fileService: FileService) {
  }
  
  /** Add files chosen by the file control to the newImages collection */
  addFiles(event: Event) {
    if (event.target && (event.target as HTMLInputElement).files) {
      const files = (event.target as HTMLInputElement).files as FileList;
      for (let i=0; i < files?.length; i++) {

        ImageFns.resizeFileToStandard(files[i]).then(proxImage => {
          this.newImages.push(proxImage);
        })

      }
      this.isChanged = true;
    }
  }

  get imageCount(): number {
    // files just added + files already attached to this product type
    return this.newImages.length + this.oldImages.length;
  }

  async openFileViewDialog() {
    if (this.oldImages.some(pti => pti.accountImage == null)) {
      // load the AccountImages
      const accountImageIds = this.oldImages.map(pti => pti.accountImageId);
      await this.dbQueryService.getAccountImages(...accountImageIds);
    }
    const images = this.oldImages.map(pti => {
      const image = pti.accountImage as ProxImage;
      image.isPrimary = pti.isPrimary;
      return image;
    });

    await this.dialogService.create<FileGalleryDialogData>(FileGalleryDialogComponent, 
      { newImages: this.newImages, oldImages: images, onDelete: this.deleteFile.bind(this), onChange: this.fileChange.bind(this) }, 
      FileGalleryDialogComponent.config);
  }

  /** Upload the files to the server, and attach the resulting AccountImages to ProductTypeImages */
  async attachFiles() {
    const accountImages = await this.fileService.uploadAccountImages(this.accountId, this.newImages);

    for (let i=0; i<accountImages.length; i++) {
      const si = accountImages[i];
      this.createJoinEntity.emit({ accountImageId: si.id, isPrimary: this.newImages[i].isPrimary || false });
    }
    this.newImages = [];
    this.isChanged = false;
  }

  /** When an image is deleted from the gallery dialog, mark it deleted here */
  private deleteFile(image: ProxImage) {
    if (image instanceof AccountImage) {
      const pti = this.oldImages.find(pti => pti.accountImage == image);
      if (pti) {
        pti.entityAspect.setDeleted();
      }
      image.entityAspect.setDeleted();
    } else if (image.file) {
      _.remove(this.newImages, f => f === image);
    }
  }

  /** When an image is added or changed in the gallery dialog, change it here */
  private fileChange(image: ProxImage) {
    if (image instanceof AccountImage) {
      const pti = this.oldImages.find(pti => pti.accountImage == image);
      if (pti) {
        pti.isPrimary = !!(image as ProxImage).isPrimary;
      }
    }
    this.isChanged = true;
  }
}
