/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { AgFns, EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';

import { ProductTypeConfig } from '@models';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { AccountDbQueryService } from '../services/account-db-query.service';


export interface ProductTypeConfigFinderParams extends EntityFinderParams {
  accountId?: string,
  productTypeConfigs?: ProductTypeConfig[];
}

@Component({
  selector: 'prox-product-type-config-finder',
  template: EntityFinderDialog.HTML,

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductTypeConfigFinderDialog extends EntityFinderDialog<ProductTypeConfig, ProductTypeConfigFinderParams> {

  params!: ProductTypeConfigFinderParams;
  
  constructor(
    ref: DynamicDialogRef,
    override config: DynamicDialogConfig<ProductTypeConfigFinderParams>,
    public dbQueryService: AccountDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assertNonNull(config.data);
    this.params = config.data;
  }

  override async initialize() {
    return {
      header: 'Select Product Groups',
      width: 'calc(80vw)',
    }
  }

  override async initDatasource() {
    if (this.params.productTypeConfigs) {
      return this.params.productTypeConfigs;
    } else if (this.params.accountId != null) {
      const queryFn = () => this.dbQueryService.createProductTypeConfigsQuery(this.params.accountId || '', null, false )
      return AgFns.buildDatasource(queryFn);
    } else {
      throw new Error('This finder needs either an account id or an array of ProductTypeConfigs');
    }
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      { headerName: 'Supplier', field: 'pricedProductType.productType.supplier.name',  filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple'  },
      { headerName: 'Manufacturer', field: 'pricedProductType.productType.manufacturer.name', filter: 'agTextColumnFilter', },
      { headerName: 'Product Group Name', field: 'name', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' }, // I like this checkbox here too!  Please retain.
      { headerName: 'SKU', field: 'pricedProductType.productType.productKey', filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: 'pricedProductType.productType.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'pricedProductType.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Base Price', field: 'pricedProductType.basePrice' },
    ] as ColDef[]
    const sortModel = [
      { colId: 'pricedProductType.productType.manufacturer.name', sort: 'asc' },
      { colId: 'pricedProductType.productType.name', sort: 'asc' }
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }
}