/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import { AccountUnitOfWork } from '@data';
import { JobOrder, JobOrderStatusEnum } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AgFns, ISortModel } from '../ag-grid';
import { EntityFinderDialog, EntityFinderParams } from '../base/entity-finder.dialog';

interface JobOrderFinderParams extends EntityFinderParams {
  accountId?: string;
  jobOrderStatusId?: JobOrderStatusEnum;
}

@Component({
  selector: 'prox-joborder-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CoreJobOrderFinderDialog extends EntityFinderDialog<JobOrder, JobOrderFinderParams> implements OnInit {
  
  params: JobOrderFinderParams;
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig,
    public uow: AccountUnitOfWork,
    
  ) {
    super(ref, config);
    this.params = config.data;
  }

  override async initialize(): Promise<EntityFinderParams> {
      return {
        header: 'Select a Job Order'
      }
  }

  async initDatasource() {
    const datasource = AgFns.buildDatasource(() => {
      let q = this.uow.createQuery(JobOrder);
      if (this.params.accountId) {
        q = q.where({ accountId: this.params.accountId });
      }
      if (this.params.jobOrderStatusId) {
        q = q.where({ jobOrderStatusId: this.params.jobOrderStatusId });
      }
      return q;
    });
    return datasource;
  }

  override getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Order Id', field: 'displayId', filter: 'agNumberColumnFilter',   },
      { headerName: 'Status', field: 'jobOrderStatusId', filter: 'agNumberColumnFilter', valueFormatter: (p) => `${p.value} - ${JobOrderStatusEnum[p.value]}`},
      { headerName: 'Created', field: 'orderCreatedTs', filter: 'agDateColumnFilter' },
      { headerName: 'Placed', field: 'orderPlacedTs', filter: 'agDateColumnFilter' },
      { headerName: 'Purchase Order', field: 'purchaseOrder', filter: 'agTextColumnFilter' },
    ];
    const sortModel: ISortModel = [
      { colId: 'displayId', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }
}
