<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div class="card-body flex flex-grow-1 flex-column gap-3">
    <div>
      <h5>Program Supplier Settings</h5>
    </div>
    <div class="flex gap-3" style="margin-top: -20px;">
      <div class="w-4">
        <prox-field label="Program Supplier"></prox-field>
        <p-dropdown [options]="programSuppliers" [(ngModel)]="selectedProgramSupplier" optionLabel="supplier.name"
          placeholder="Select Program Supplier" name="suppliers"></p-dropdown>
      </div>
    </div>

    <div class="w-4" *ngIf="selectedProgramSupplier">
      <p-checkbox [(ngModel)]="selectedProgramSupplier.doesAccountPayFreight" [binary]="true"
        [bzModel]="selectedProgramSupplier" [disabled]="isReadOnly()" name="doesAccountPayFreight"
        label="Add Freight Charges to Invoice"></p-checkbox>
    </div>
    <div class="w-4"  *ngIf="selectedProgramSupplier && selectedProgramSupplier.doesAccountPayFreight">
      <p-checkbox [(ngModel)]="selectedProgramSupplier.shouldChargeFreightOnFirstShipmentOnly" [binary]="true"
        [bzModel]="selectedProgramSupplier" [disabled]="isReadOnly()" name="shouldChargeFreightOnFirstShipmentOnly"
        label="Charge Freight on First Shipment Only"></p-checkbox>
    </div>

    <div class="flex gap-3 mt-1" *ngIf="selectedProgramSupplier">

      <div class="w-4">
        <prox-field label="Handling Amount/Order">
          <p-inputNumber [(ngModel)]="selectedProgramSupplier.handlingChargePerOrderAmt" inputId="currency-us"
            mode="currency" currency="USD" locale="en-US" [bzModel]="selectedProgramSupplier" [disabled]="isReadOnly()"
            name="handlingChargePerOrderAmt" />
        </prox-field>
      </div>
      <div class="w-4">
        <prox-field label="Handling Amount/Shipment">
          <p-inputNumber [(ngModel)]="selectedProgramSupplier.handlingChargePerShipmentAmt" inputId="currency-us"
            mode="currency" currency="USD" locale="en-US" [bzModel]="selectedProgramSupplier" [disabled]="isReadOnly()"
            name="handlingChargePerShipmentAmt" />
        </prox-field>
      </div>
    </div>

  </div>