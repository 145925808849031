import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UtilFns } from "@utils";
import { Entity } from "breeze-client";
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from "primeng/dynamicdialog";
import { firstValueFrom } from "rxjs";
import { StatusPriority } from "../../services/status.service";
import * as _ from "lodash-es";

interface IStatus extends Entity {
  id: any, // number, 
  name: string,
  description?: string;
}

export interface IStatusChangeDialogParams {
  title?: string;
  statuses: IStatus[];
  currentStatus?: IStatus;
  isAvailable: (s: IStatus) => boolean
}

@Component({
  selector: 'prox-status-change-dialog',
  templateUrl: './status-change.dialog.html',
})


// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StatusChangeDialog implements OnInit, AfterViewInit {
  @ViewChild('ok') okBtn!: ElementRef;

  initParams!: IStatusChangeDialogParams | DynamicDialogConfig;
  statuses!: IStatus[];
  isAvailable!: (s: IStatus) => boolean
  currentStatus!: IStatus;

  defaultParams: DynamicDialogConfig<IStatusChangeDialogParams> = {
    header: 'Select status...',
    width: 'calc(30vw)',
    height: 'calc(30vh)'
  }
  
  selectedIndex?: number = undefined;
  isPageReady = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<IStatusChangeDialogParams>,
  ) {
  }

  static async open(dialogService: DialogService, data: IStatusChangeDialogParams, config?: DynamicDialogConfig<IStatusChangeDialogParams> ) {
    const ref = dialogService.open(StatusChangeDialog, {
      closable: false, 
      draggable: true,
      // baseZIndex: 10000,
      // contentStyle: { overflow: 'auto' },
      ...config,
      data: data
    });
    const r = await firstValueFrom(ref.onClose);
    return r as number;
  }


  async ngOnInit() {
    UtilFns.assertNonNull(this.config.data?.statuses);
    this.statuses = this.config.data.statuses;
    UtilFns.assertNonNull(this.config.data?.isAvailable);
    this.isAvailable = this.config.data.isAvailable;
    this.statuses = _.sortBy(this.statuses, s => StatusPriority.indexOf(s.id));
    this.currentStatus = this.config.data.currentStatus ?? this.statuses[0];    
    this.isPageReady = true;
  }

  // Ugly hack - needed to remove primeng focus from first radio button 
  ngAfterViewInit(): void {
    setTimeout( () => {
      this.okBtn.nativeElement.focus();  
    }, 20);
    
  }

  isDisabled() {
    this.currentStatus == null;
  }
  
  onOk() {
    this.ref.close(this.currentStatus.id);
  }

  onCancel() {
    this.ref.close();
  }
}