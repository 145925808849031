<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Rapid Orders</h5>
  </div>
  <div class="h-full flex flex-column ">
    <div class="mb-3">
      <button [disabled]="parent.isReadOnly()"  (click)="onRtAdd()" pButton type="button" label="Add Rapid Order" icon="pi pi-plus"
        class="p-button-sm"></button>
    </div>
    <div class="mb-3" style="margin-top: -5px;">
      <h6><i>Remember! Set default Rapid Order Detail quantities by clicking on the caret (>) next to the Rapid
          Order after Attaching Pruducts</i></h6>
    </div>
    <div class="flex-grow-1" style="margin-top: -20px;">
      <prox-ag-grid [gridOptions]="rtGridOptions" [rowData]="rapidTemplates"></prox-ag-grid>
    </div>
  </div>
</div>
