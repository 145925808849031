import { CellValueChangedEvent, GridOptions, GridReadyEvent, ValueSetterParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  BaseService,
  ISortModel,
  QueryComponent,
  SaveAndQueryComponent,
  TemplatesService,
} from '@core';
import { Account, NotificationEvent, NotificationEventAccountMap, NotificationEventEnum, NotificationType, NotificationTypeEnum } from '@models';
import { UtilFns } from '@utils';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';



type NotificationTypeMap = {
  [key in NotificationTypeEnum]?: NotificationEventAccountMap; 
}

interface EventSummary extends NotificationTypeMap {
  notificationEvent: NotificationEvent;
};


@Component({
  selector: 'prox-account-notification-settings',
  templateUrl: './account-notification-settings.component.html',
})
export class AccountNotificationSettingsComponent extends SaveAndQueryComponent {
  accountId!: string;
  account?: Account;
  notificationEvents: NotificationEvent[] = [];
  notificationTypes: NotificationType[] = [];
  accountMaps: NotificationEventAccountMap[] = [];
  gridOptions: GridOptions = {};

  eventSummaries: EventSummary[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public override dbQueryService: AccountDbQueryService,
    public override dbSaveService: AccountDbSaveService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');

    // this.templates = this.getComms();
    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      rowSelection: 'single',
      getRowId: undefined, // couldn't refresh rows without setting this
    });

    
    this.notificationEvents = await this.dbQueryService.getAll(NotificationEvent);
    this.notificationTypes = await this.dbQueryService.getAll(NotificationType);
    await this.loadMaps();

    AgFns.captureGridRouteParams(this.gridOptions, this.route, 'id');
    this.isPageReady = true;
  }

  private async loadMaps() {
    const eventMap = new Map<NotificationEventEnum, EventSummary>();
    this.eventSummaries = this.notificationEvents.map(ne => {
      const eventSummary = <EventSummary> {
        notificationEvent:  ne
      };
      eventMap.set(ne.id, eventSummary);
      return eventSummary;
    })
    this.accountMaps = await this.dbQueryService.getNotificationEventAccountMaps(this.accountId);
    if (this.accountMaps.length == 0) {
      await this.addDefaultMaps();
    }
    
    this.accountMaps.forEach(map => {
      const eventSummary = eventMap.get(map.notificationEventId)!;
      eventSummary[map.notificationTypeId] = map;
    })
    
  }

  
  onGridReady(event: GridReadyEvent) {
    const colDefs = [
      {
        headerName: 'Notification Event',
        field: 'notificationEvent.description',
        sortable: true,
        width: 250,
        maxWidth: 500,
      },
      this.createNtProps(NotificationTypeEnum.InApp),
      this.createNtProps(NotificationTypeEnum.Email),
      this.createNtProps(NotificationTypeEnum.SMS),
      
    ];

    const sortModel: ISortModel = [{ colId: 'notificationEvent.description', sort: 'asc' }];

    AgFns.initGrid(this.gridOptions, colDefs, sortModel);
  }

  

  createNtProps(notificationTypeId: NotificationTypeEnum) {
    return {
      headerName: NotificationTypeEnum[notificationTypeId],
      cellEditor: 'agCheckboxCellEditor', 
      cellRenderer: 'agCheckboxCellRenderer',
      valueGetter: (params) => {
        const eventSummary = params.data as EventSummary;
        if (eventSummary == null) return;
        return eventSummary[notificationTypeId] != null;
      },
      valueSetter: (params: ValueSetterParams) => {
        const eventSummary = params.data as EventSummary;
        let accountMap = eventSummary[notificationTypeId];
        if (accountMap == null) {
          if (params.newValue) {
            accountMap = this.dbSaveService.createEntity(NotificationEventAccountMap, {
              notificationEventId: eventSummary.notificationEvent.id,
              notificationTypeId: notificationTypeId,
              accountId: this.accountId,
            })
            eventSummary[notificationTypeId] = accountMap;
          }
        } else {
          if (params.newValue) {
            accountMap.entityAspect.rejectChanges();
          } else {
            const es = EntityFns.deleteOrDetach(accountMap.entityAspect);
            if (es.isDetached()) {
              eventSummary[notificationTypeId] = undefined;
            }
          }
        }
        
        return true;
      },
      sortable: true,
      editable: true,
      width: 250,
      maxWidth: 500,
    };
  }


  onRowSelected() {}

  /** Add all maps that don't already exist for this account.  Primarily for testing. */
  async addDefaultMaps() {
    for (const evt of this.notificationEvents) {
      this.dbQueryService.uow.createEntity(NotificationEventAccountMap, {
        accountId: this.accountId,
        notificationEventId: evt.id,
        notificationTypeId: NotificationTypeEnum.InApp,
      });

    }

    await this.dbSaveService.saveChanges();
    this.accountMaps = await this.dbQueryService.getNotificationEventAccountMaps(this.accountId);
  }

  
}
