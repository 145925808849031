<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="mt-3">
    <h4 class="card-header">
      <span>Status Management for Program: {{program.name}}</span>
    </h4>
  </div>

  <div class="card-body flex-grow-1 flex flex-column">
    <div class="flex">
      <div class="flex flex-1 width: 100px">
        <ul class="list-none p-0 m-0">
          <li class="flex justify-content-between mb-1">
            <span>Current Status: {{program.activeStatus.name}}</span>
          </li>      
        </ul>
      </div>
    </div>
    <div class="w-4 pb-3 pr-3">
      <prox-field label="Change Status To: ">
        <p-dropdown [options]="nextStatusOptions" [(ngModel)]="selectedNextStatus" optionLabel="name" optionValue="id"
          name="nextStatusOption" (onChange)="onChangeNextStatus($event)"></p-dropdown>
      </prox-field>
    </div>
  </div>
  <prox-save-cancel [uow]="this" (undo)="onCancel()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>
