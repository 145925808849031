// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ActiveStatus } from './active-status';
import { BudgetProductTypeTagMap } from './budget-product-type-tag-map';
import { PricedProductTypeFeatureChoice } from './priced-product-type-feature-choice';
import { ProductType } from './product-type';
import { ProductTypeConfig } from './product-type-config';
import { ProgramProductTypeTagMap } from './program-product-type-tag-map';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class PricedProductType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('basePrice', {
        validators: [ BaseEntity.greaterThanZeroValidator() ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });
  }

  getStatusParents() {
    return [this.productType];
  }

  getStatusId() {
    return this.activeStatusId;
  }

  get tagCount(): number {
    return this.programProductTypeTagMaps.length;
  }

  get productTags(): string {
    const list = this.productTagList;
    return list.length == 0 ? '' : list.join(', ');
  }

  get productTagList(): string[] {
    return this.programProductTypeTagMaps.map(p => p.programProductTypeTag.name);
  }
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Decimal */
  basePrice!: number;
  /** Guid FK productType */
  productTypeId!: string;
  /** Decimal */
  reissuancePrice?: number;
  /** Binary */
  rowVersion!: any;
  /** FK accountId */
  account!: Account;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK pricedProductTypeId */
  budgetProductTypeTagMaps!: BudgetProductTypeTagMap[];
  /** Inv FK pricedProductTypeId */
  pricedProductTypeFeatureChoices!: PricedProductTypeFeatureChoice[];
  /** FK productTypeId */
  productType!: ProductType;
  /** Inv FK pricedProductTypeId */
  productTypeConfigs!: ProductTypeConfig[];
  /** Inv FK pricedProductTypeId */
  programProductTypeTagMaps!: ProgramProductTypeTagMap[];
}

