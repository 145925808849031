import { DbQueryService } from '@data';
import { BaseService } from '../../services/base.service';


// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type GConstructor<T = {}> = abstract new (...args: any[]) => T;

// properties that TrackChangesMixin needs
type QueryModel =  {
  baseService: BaseService;
  dbQueryService: DbQueryService;
  deactivate(): void;
  navigateBack(): void;
}

export function queryComponentMixin<TBase extends GConstructor<QueryModel>>(BaseClass: TBase) {

  abstract class QueryComponentMixin extends BaseClass {

    override deactivate() { 
      super.deactivate();
      // TODO clear only at certain times, to better preserve caching and avoid repeated queries
      this.dbQueryService.clearRegisteredEntityTypes();
    }

    async onCancel() {
      this.navigateBack();
    }

    showHelp(title?: string, field?: string): void {
      this.baseService.dialogService.helpDialog(title || '', this, field);
    }  

  }

  return QueryComponentMixin;
}
