/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, EditableCallbackParams, GetRowIdParams, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, PricedProductTypeFinderDialog, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';
import { Account, BudgetProductTypeTag, BudgetProductTypeTagMap,  } from '@models';
import { BaseService } from '@core';
import { UtilFns } from '@utils';

import * as _ from 'lodash';

import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { EntityFns } from '@data';



@Component({
  selector: 'prox-budget-product-type-tags',
  templateUrl: './budget-product-type-tags.component.html',
})
export class BudgetProductTypeTagsComponent extends SaveAndQueryComponent {

  accountId!: string;
  account!: Account;
  bpttGridOptions!: GridOptions;
  bptts?: BudgetProductTypeTag[];
  selectedBptt?: BudgetProductTypeTag;

  tmapGridOptions!: GridOptions;
  tmaps: BudgetProductTypeTagMap[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    this.account = await this.dbQueryService.getAccountById(this.accountId);

    UtilFns.assertNonNull(this.account, "Account");
    this.bpttGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onBpttGridReady,
      onRowSelected: this.onBpttRowSelected,
    });
    AgFns.captureGridRouteParams(this.bpttGridOptions, this.route, 'id');

    this.tmapGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onTmapGridReady,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data as BudgetProductTypeTagMap;
        return x.pricedProductTypeId;
      },
    });
    
    this.bptts = await this.dbQueryService.getBudgetProductTypeTags(this.accountId);
    this.setTitle("Budget Product Tags for " + this.account.name);
    this.isPageReady = true;
  }

  canDelete() {
    return !this.isReadOnly();
  }
  
  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  // Bptt Grid

  onBpttGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getBpttColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getBpttColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Name', field: 'name', editable: !this.isReadOnly(), filter: 'agTextColumnFilter' },
      ProxAgFns.getEntityDeleteColDef(this.onBpttDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onBpttRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const bptt = event.data as BudgetProductTypeTag;
    this.selectedBptt = bptt;
    if (bptt == null) return;

    if (bptt.entityAspect.entityState.isDeleted()) {
      this.selectedBptt = undefined;
      this.bpttGridOptions.api?.deselectAll();
      return;
    }
    this.tmaps = await this.dbQueryService.getBudgetProductTypeTagMapsForTag(bptt.id);
    if (bptt['_tmpCanDelete'] == null) {
      bptt['_tmpCanDelete'] = ! await this.dbQueryService.isBudgetProductTypeTagUsed(this.selectedBptt?.id || '');
    }

    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    

    this.updateGridRouteParams(this.bpttGridOptions, bptt.id);
  }

  async onBpttAdd() {
    const bptt = this.dbSaveService.createEntity(BudgetProductTypeTag, { accountId: this.accountId });
    bptt.entityAspect.validateEntity();
    
          
    this.bptts?.push(bptt);
    this.tmaps = [];
    AgFns.refreshGrid(this.bpttGridOptions, this.bptts);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    AgFns.selectGridRowByKey(this.bpttGridOptions, (e: BudgetProductTypeTag) => e.id, bptt.id, 'name');
  }

  async onBpttDelete(bptt: BudgetProductTypeTag) {
    // TODO: need to add logic to prevent deleting InUse bptts.
    
    // const inUse = await this.dbQueryService.checkIfInUse(ppt, ProductTypeConfigProduct, 'pricedProductTypeId');
    // if (inUse) {
    //   this.toastr.warning(`You cannot delete a Priced Product Type that is in use.`, 'Cannot delete');
    //   return;
    // }

    // if it's an added record - cascade delete won't catch them.
    if (bptt.entityAspect.entityState.isAdded()) {
      bptt.budgetProductTypeTagMaps.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(bptt.entityAspect);
    this.selectedBptt = undefined;
    this.tmaps = [];
    if (this.bptts) {
      _.remove(this.bptts, bptt);
      AgFns.refreshGrid(this.bpttGridOptions, this.bptts);
    }
  }

  // ------------- Tmap grid --------------------------

  onTmapGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getTmapColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getTmapColDefsAndSortModel() {
    const ptypePath = 'pricedProductType.productType'
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Supplier', field: ptypePath + '.supplier.name', filter: 'agTextColumnFilter', },
      { headerName: 'Manufacturer', field: ptypePath + '.manufacturer.name', filter: 'agTextColumnFilter', },
      { headerName: 'SKU', field: ptypePath + '.productKey', filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: ptypePath + '.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: ptypePath + '.description', filter: 'agTextColumnFilter' },
      { headerName: 'Base Price', field: 'pricedProductType.basePrice' },
      ProxAgFns.getEntityDeleteColDef(this.onTmapDelete.bind(this)),
    ];
    const sortModel: ISortModel = [
      { colId: ptypePath + '.supplier.name', sort: 'asc' },
      { colId: ptypePath + '.manufacturer.name', sort: 'asc' },
      { colId: ptypePath + 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  async onTmapAdd() {
    if (!this.selectedBptt) return;
    const bpttId = this.selectedBptt.id;
    const queryFn = () => this.dbQueryService.createPricedProductTypesQuery(this.accountId, bpttId);
    const pricedProductTypes = await this.dialogService.createFinder(PricedProductTypeFinderDialog, {
      queryFn, rowSelection: 'multiple'
    });
    const newPpts = pricedProductTypes.filter(ppt => !ppt.budgetProductTypeTagMaps.some(x => x.budgetProductTypeTagId == bpttId));
    const newTmaps = newPpts.map(ppt => {
      let tmap = this.dbSaveService.uow.undoIfDeleted(BudgetProductTypeTagMap, [   ppt.id, bpttId ]);
      if (tmap == null) {
        tmap = this.dbSaveService.createEntity(BudgetProductTypeTagMap, { 
          pricedProductTypeId: ppt.id,
          budgetProductTypeTagId: bpttId, 
        });
      }
      return tmap;
    });


    this.tmaps.push(...newTmaps);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
  }

  async onTmapDelete(tmap: BudgetProductTypeTagMap) {
    // // const inUse = await this.dbQueryService.checkIfInUse(fc, ...);
    // const inUse = true;
    // if (inUse) {
    //   this.toastr.warning(`You cannot delete a Priced Product Type Feature choice that is in use.`, 'Cannot delete');
    //   return;
    // }

    EntityFns.deleteOrDetach(tmap.entityAspect);
    _.remove(this.tmaps, tmap);
    AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
  }

  

  isTagEditable(params: EditableCallbackParams) {
    if (!this.selectedBptt) return false;
    return this.selectedBptt['_tmpCanDelete'] ?? false;
  }

  // ---- Misc ----------------------------


  override async addCrossValidationErrors() {
    //
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    if (this.bptts) {
      this.bptts = await this.dbQueryService.getBudgetProductTypeTags(this.accountId);
      this.tmaps = [];
    }

    AgFns.refreshGrid(this.bpttGridOptions, this.bptts);
    if (!this.bpttGridOptions.api) return;
    if (!this.selectedBptt) {
      AgFns.selectFirstRow(this.bpttGridOptions);
    } else {
      AgFns.selectGridRowByKey(this.bpttGridOptions.api, e => e.id, this.selectedBptt.id);
      this.tmaps = await this.dbQueryService.getBudgetProductTypeTagMapsForTag(this.selectedBptt.id);
      AgFns.refreshGrid(this.tmapGridOptions, this.tmaps);
    }
  }
}
