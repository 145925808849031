
<div *ngIf="isPageReady && budgetWrappers" class="flex flex-column w-full h-full">
  <div class="flex my-2">
    <p-button [disabled]="isReadOnly" (click)="onBudgetAdd()" styleClass="p-button p-button-sm"
      [label]="addLabel" icon="pi pi-plus" class="p-button-sm"></p-button>
  </div>
  <div class="flex-grow-1 pt-2 w-full h-full">
    <prox-ag-grid [gridOptions]="budgetGridOptions" [rowData]="budgetWrappers">
    </prox-ag-grid>
  </div>
</div>
