<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" ></prox-main>

<ng-template #bodyTemplate>

  <div class="flex flex-column pb-2">

    <div class="flex">
      <div *ngIf="!isBeingAdded"  >
        <h5>Program: {{program.name}}
          <span  style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
        </h5>
      </div>
      <div *ngIf="isBeingAdded"  >
        <h4>* New Program *</h4>
      </div>
    </div>
  </div>

  <div *ngIf="program" class="flex flex-row flex-grow-1 gap-4 mb-3">
    <div class="flex flex-row">
      <div class="flex flex-grow-1 flex-column mb-3">
        <div class="layout-menu-container flex-column mb-1">
          <p-menu styleClass="layout-form-menu" [model]="items"></p-menu>
        </div>


      </div>
    </div>

    <div *ngIf="program" class="flex flex-column flex-grow-1 w-12" >
      
      <prox-program-info-sub style="display: contents" [visible]="isView(ViewEnum.Info)" [parent]="this"  #programSub></prox-program-info-sub>
      
      <prox-program-user-groups-sub style="display: contents" [visible]="isView(ViewEnum.UserGroups)" [parent]="this"  #programSub ></prox-program-user-groups-sub>
      
      <prox-program-product-type-configs-sub style="display: contents" [visible]="isView(ViewEnum.ProductTypeConfigs)" [parent]="this" #programSub ></prox-program-product-type-configs-sub>
      
      <prox-program-product-type-tags-sub style="display: contents"  [visible]="isView(ViewEnum.ProductTags)" [parent]="this"  #programSub  ></prox-program-product-type-tags-sub>
      
      <prox-program-views-sub style="display: contents"  [visible]="isView(ViewEnum.ProductViews)" [parent]="this"  #programSub ></prox-program-views-sub>

      <prox-program-issuances-sub style="display: contents"  [visible]="isView(ViewEnum.Issuances)" [parent]="this"  #programSub ></prox-program-issuances-sub>

      <prox-program-allowances-sub style="display: contents"  [visible]="isView(ViewEnum.Allowances)" [parent]="this" #programSub ></prox-program-allowances-sub>
      
      <prox-program-rapid-templates-sub style="display: contents"  [visible]="isView(ViewEnum.RapidTemplates)" [parent]="this"  #programSub ></prox-program-rapid-templates-sub>
      
      <prox-program-budgets-sub style="display: contents"  [visible]="isView(ViewEnum.Budgets)" [parent]="this"  #programSub ></prox-program-budgets-sub>

      <prox-program-billing-sub style="display: contents"  [visible]="isView(ViewEnum.Billing)" [parent]="this"  #programSub ></prox-program-billing-sub>

      <prox-program-suppliers-sub style="display: contents"  [visible]="isView(ViewEnum.Suppliers)" [parent]="this"  #programSub ></prox-program-suppliers-sub>

      <div *ngIf="activeView === ViewEnum.Cancellation" class="card-body flex flex-grow-1 flex-column gap-3">
        <div>
          <h5>Cancellation</h5>
        </div>
        <div class="flex flex-row" style="margin-top: -20px;">
          <div class="w-2">
            <prox-field label="Cancellation Date">
              <p-calendar [disabled]="isReadOnly()" [(ngModel)]="program.cancellationDate" [bzModel]="program"
                name="cancellationDate"></p-calendar>
            </prox-field>
          </div>
        </div>

      </div>

    </div>
  </div>
  <prox-save-cancel [uow]="this" [hideUndo]="isBeingAdded" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>