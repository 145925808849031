
export interface IAddress   {
  attn?: string;
  city?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  name?: string;
  state?: string;
  zipcode?: string;
}
