/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { SharedQueries } from '@core';
import {
  Account,
  AccountAddress,
  AccountAdmin,
  AccountAdminGroup,
  AccountBlanketPurchaseOrder,
  AccountBudget,
  AccountImage,
  AccountUser,
  Addon,
  AddonImage,
  ApprovalTree,
  ApprovalTreeAdminGroup,
  ApprovalTreeUserGroup,
  BudgetProductTypeTag,
  BudgetProductTypeTagMap,
  JobOrder,
  JobOrderBudgetLog,
  JobOrderDetail,
  JobOrderDetailAddon,
  JobOrderNote,
  JobOrderOveragePayment,
  PricedProductType,
  ProductTypeConfig,
  ProductTypeImage,
  Program,
  ProgramBudget,
  ProgramRapidTemplate,
  ProgramView,
  ProgramAllowance,
  ProgramAllowanceUserGroupMap,
  ProgramAllowanceUserLog,
  ProgramIssuance,
  ProgramIssuanceUserGroupMap,
  ProgramIssuanceUserLog,
  ProgramProductCategoryTag,
  ProgramProductTypeConfig,
  ProgramProductTypeTag,
  ProgramProductTypeTagMap,
  ProgramUserGroup,
  ProgramUserGroupBudget,
  ProximityRight,
  ProximityUserRight,
  ShippingUserGroup,
  Supplier,
  SupplierAccount,
  JobOrderHistDetail,
  JobOrderHistDetailFeature,
  JobOrderHistDetailAddon,
  JobOrderHistDetailReceipt,
  JobOrderStatusEnum,
  AccountBlanketPurchaseOrderLog,
  ShippingUserGroupMap,
  AccountAdminGroupMap,
  AccountIssuance,
  AccountIssuanceUserGroupMap,
  AccountIssuanceUserLog,
  ProgramAccountIssuanceMap,
  ProgramUserGroupMap,
  ProgramUserGroupExclusion,
  NarrativeUserGroupDto,
  SupplierSubmission,
  NotificationTemplate,
  ReturnReason,
  ReturnRequest,
  ReturnRequestStatusEnum,
  NotificationEventAccountMap,
  ReturnRequestDetail,
  NotificationSubmission, InAppNotification,
  ProgramPurchaseOrderType
} from '@models';
import { UtilFns, mapObject } from '@utils';
import * as _ from 'lodash';
import { JoHistSummary, JoHistTotalsBySupplier } from './account-interfaces';
import { AccountUnitOfWork, DbQueryService } from '@data';
JobOrderDetail

@Injectable({ providedIn: 'root' })
export class AccountDbQueryService extends DbQueryService {
  constructor(public override uow: AccountUnitOfWork) {
    super(uow);
    // Make sure that you don't strand a mapping type by deleting either end but leaving it intact.  And don't add lookup types.
    this.registeredEntityTypes = [
      Account,
      AccountUser,
      AccountAdmin,
      AccountBudget,
      AccountBlanketPurchaseOrder,
      AccountIssuance,
      AccountIssuanceUserGroupMap,
      AccountIssuanceUserLog,

      ApprovalTreeUserGroup,
      ApprovalTreeAdminGroup,

      BudgetProductTypeTag,
      BudgetProductTypeTagMap,

      Program,
      ProgramAccountIssuanceMap,
      ProgramProductTypeConfig,
      ProductTypeConfig,
      PricedProductType,
      ProgramBudget,
      ProgramUserGroup,
      ProgramUserGroupMap,
      ProgramUserGroupExclusion,
      ProgramUserGroupBudget,
      ProgramProductCategoryTag,
      ProgramProductTypeTag,
      ProgramProductTypeTagMap,
      ProgramIssuance,
      ProgramIssuanceUserLog,
      ProgramIssuanceUserGroupMap,
      ProgramAllowance,
      ProgramAllowanceUserLog,
      ProgramAllowanceUserGroupMap,

      JobOrder,
      JobOrderDetail,
      JobOrderDetailAddon,
      JobOrderHistDetail,
      JobOrderHistDetailFeature,
      JobOrderHistDetailAddon,
      JobOrderHistDetailReceipt,
      JobOrderOveragePayment,
      JobOrderNote,
      JobOrderBudgetLog,

      ShippingUserGroup,
      ShippingUserGroupMap,
    ];
  }

  async getAccountById(accountId: string) {
    const r = await this.uow
      .createQuery(Account)
      .where({ id: accountId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountWithIssuancesAndSupplierAccounts(accountId: string) {
    const r = await this.uow
      .createQuery(Account)
      .where({ id: accountId })
      .expand([
        'accountIssuances.accountIssuanceUserGroupMaps',
        'accountIssuances.programProductTypeTag',
        'supplierAccounts'
      ])
      .executeCached();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountByIdWithAdmins(accountId: string) {
    const r = await this.uow
      .createQuery(Account)
      .where({ id: accountId })
      .expand(['primaryAccountAdmin', 'accountAdmins.proximityUser'])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getSupplierById(supplierId: string) {
    const r = await this.uow
      .createQuery(Supplier)
      .where({ id: supplierId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountByIdForDelete(accountId: string) {
    const r = await this.uow
      .createQuery<Account>(null, 'AccountByIdForDelete')
      .withParameters({ accountId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getSupplierAccount(supplierId: string, accountId: string) {
    const r = await this.uow
      .createQuery(SupplierAccount)
      .where({
        supplierId: supplierId,
        accountId: accountId,
      })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountAdminById(accountAdminId: string) {
    const r = await this.uow
      .createQuery(AccountAdmin)
      .where({ id: accountAdminId })
      .expand(['proximityUser'])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountAdminsByAccountId(accountId: string) {
    return this.uow
      .createQuery(AccountAdmin)
      .where({ accountId: accountId })
      .expand(['account', 'proximityUser'])
      .execute();
  }

  // WIP
  async getAccountAdminGroupsByAdminId(accountAdminId: string) {
    return this.uow
      .createQuery(AccountAdminGroup)
      .where({
        accountAdminGroupMaps: {
          any: { accountAdminId: accountAdminId },
        },
      })

      .execute();
  }

  async getAccountAdminsForAccountAdminGroup(accountAdminGroupId: string) {
    const aags = await this.uow
      .createQuery(AccountAdminGroup)
      .where({ id: accountAdminGroupId })
      .expand([
        'accountAdminGroupMaps.accountAdmin'
      ])
      .execute();
    if (aags.length == 0) {
      return <AccountAdmin[]>[];
    }
    const r = aags[0].accountAdminGroupMaps.map(x => x.accountAdmin);
    return r
  }


  async getAccountAdminsByAdminGroup(accountAdminGroupId: string) {
    return this.uow
      .createQuery(AccountAdmin)
      .where({
        accountAdminGroupMaps: {
          any: { accountAdminGroupId: accountAdminGroupId },
        },
      })
      .expand(['proximityUser'])
      .execute();
  }



  async getAccountAdminGroups(accountId: string) {
    return this.uow
      .createQuery(AccountAdminGroup)
      .where({ accountId: accountId })
      .expand(['accountAdminGroupMaps.accountAdmin.proximityUser'])
      .execute();
  }


  async getAccountUsers(accountId: string, useCached = true) {
    const q = this.uow
      .createQuery(AccountUser)
      .where({ accountId: accountId })
      .expand(['account', 'proximityUser']);
    return useCached ? q.executeCached() : q.execute();
  }

/*   createAccountUsersQuery(accountId: string) {
    const q = this.uow
      .createQuery(AccountUser)
      .where({
        accountId: accountId,
      })
      .expand([
        'account',
        'proximityUser',
        'programUserGroupMaps.programUserGroup',
      ]);

    return q;
  } */

    createAccountUsersQuery(accountId: string) {
      const q = this.uow
        .createQuery(AccountUser)
        .expand([
          'account',
          'proximityUser',
          'programUserGroupMaps.programUserGroup',
        ]);
  
        if (accountId) {
          return q.where({ accountId: accountId });
        } else {
          return q;
        }
    }

  async getAccountByIdForStatus(accountId: string) {
    const r = await this.uow
      .createQuery<Account>(null, 'AccountByIdForStatus')
      .withParameters({ accountId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getShippingUserGroups(accountId: string) {
    return this.uow
      .createQuery(ShippingUserGroup)
      .where({ accountId: accountId })
      .expand([
        'account',
        'shippingUserGroupAddressMaps.shippingAccountAddress',
        'shippingUserGroupMaps.accountUser.proximityUser',
      ])
      .orderBy('name')
      .execute();
  }

  async getProgramUserGroupsWithBudgets(accountId: string) {
    return this.uow
      .createQuery(ProgramUserGroup)
      .where({ accountId: accountId })
      .expand(['account', 'programUserGroupBudgets'])
      .orderBy('name')
      .execute();
  }

  async getProgramUserGroups(accountId: string) {
    return this.uow
      .createQuery(ProgramUserGroup)
      .expand(['account'])
      .where({ accountId: accountId })
      .execute();
  }

  async getProgramUserGroupsForApprovalTree(approvalTreeId: string) {
    return this.uow
      .createQuery(ProgramUserGroup)
      .where({
        approvalTreeUserGroups: { any: { approvalTreeId: approvalTreeId } },
      })
      .expand([
        'account',
        'approvalTreeUserGroups.approvalTree',
        // 'programUserGroupMaps.accountUser.proximityUser',
        'programUserGroupExclusions',
      ])
      .execute();
  }

  async getProgramChangeState(programId: string) {
    const r = await this.uow
      .createQuery(Program)
      .where({
        id: programId,
      })
      .expand([
        'programProductTypeConfigs.productTypeConfig.pricedProductType.productType',
        'activeStatus',
      ])
      .execute();
    return r[0];
  }

  async getProgramPurchaseOrderTypes() {
    const r = await this.uow
      .createQuery(ProgramPurchaseOrderType)
      .execute();
    return r;
  }

  async getAccountUserById(accountUserId: string) {
    const r = await this.uow
      .createQuery(AccountUser)
      .where({ id: accountUserId })
      .expand([
        'account',
        'billingAccountAddress',
        'shippingAccountAddress',
        'proximityUser',
        'programUserGroupMaps.programUserGroup',
        'programUserGroupExclusions'
      ])
      .executeCached();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountUsersForAccountAdminId(accountAdminId: string) {
    const r = await this.uow
      .createQuery<AccountUser>(null, 'GetAccountUsersForAccountAdmin')
      .withParameters({ accountAdminId })
      .execute();
    return r;
  }

  async getApprovalTrees(accountId: string) {
    // let at: ApprovalTree;
    // at.approvalTreeAdminGroups.forEach(x => x.accountAdminGroup.accountAdminGroupMaps.forEach(y => y.))

    return this.uow
      .createQuery(ApprovalTree)
      .where({ accountId: accountId })
      .expand([
        'approvalTreeAdminGroups.accountAdminGroup',
        'approvalTreeAdminGroups.approvalTreeUserGroups.programUserGroup',
        'approvalTreeAdminGroups.approvalTreeAdminGroupLogs',
      ])
      .execute();
  }

  async getApprovalTreesByAccountAdmin(accountAdminId: string) {
    const r = await this.uow
      .createQuery(ApprovalTreeAdminGroup)
      .where({
        'accountAdminGroup.accountAdminGroupMaps': {
          any: { accountAdminId: accountAdminId },
        },
      })
      .expand(['approvalTree'])
      .execute();

    return r.map((x) => x.approvalTree);
  }

  async getProgramUserGroupMaps(programUserGroupId: string) {
    const q = this.uow
      .createQuery(ProgramUserGroupMap)
      .where({
        programUserGroupId: programUserGroupId,
      })
      .expand(['accountUser.proximityUser']);

    return q.execute();
  }

  async getProgramUserGroupUsers(programUserGroupId: string) {
    const r = await this.getProgramUserGroupMaps(programUserGroupId);
    return r.map((r) => r.accountUser);
  }

  // Longer version of above.
  // async getProgramUserGroupUsers(programUserGroupId: string) {
  //   const q = this.uow.createQuery(AccountUser).where({
  //     programUserGroupMaps: {
  //       any: { programUserGroupId: programUserGroupId }
  //     }
  //   })
  //   .expand([
  //     'programUserGroupMaps.accountUser'
  //   ]);

  //   return q.execute();
  // }

  async getAccountAdminGroupAdmins(
    accountAdminGroupId: string,
    useCached = true
  ) {
    const q = this.uow
      .createQuery(AccountAdminGroupMap)
      .where({ accountAdminGroupId })
      .expand(['accountAdmin.proximityUser']);
    const r = useCached ? await q.executeCached() : await q.execute();
    return r.map((x) => x.accountAdmin);
  }

  async getAccountBlanketPurchaseOrderById(
    accountBlanketPurchaseOrderId: string
  ) {
    const r = await this.uow
      .createQuery(AccountBlanketPurchaseOrder)
      .where({ id: accountBlanketPurchaseOrderId })
      .expand(['accountBlanketPurchaseOrderLogs'])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountBlanketPurchaseOrders(accountId: string) {
    return this.uow
      .createQuery(AccountBlanketPurchaseOrder)
      .where({ accountId: accountId })
      .expand(['accountBlanketPurchaseOrderLogs'])
      .execute();
  }

  async getAccountBlanketPurchaseOrderLogs(
    accountBlanketPurchaseOrderId: string
  ) {
    return this.uow
      .createQuery(AccountBlanketPurchaseOrderLog)
      .where({ accountBlanketPurchaseOrderId: accountBlanketPurchaseOrderId })
      .execute();
  }

  async getShippingAccountAddresses(
    accountId: string,
    accountUserId?: string,
    canShipHome?: boolean
  ) {
    let q = this.uow
      .createQuery(AccountAddress)
      .where({
        accountId: accountId,
        isShippingAddress: true,
        isPersonalAddress: false,
      })
      .expand([
        'account',
        'shippingUserGroupAddressMaps.shippingUserGroup.shippingUserGroupMaps.accountUser.proximityUser',
      ]);
    if (accountUserId) {
      q = q.where({
        shippingUserGroupAddressMaps: {
          any: {
            'shippingUserGroup.shippingUserGroupMaps': {
              any: { accountUserId: accountUserId },
            },
          },
        },
      });
    }
    const addresses = await q.execute();

    if (accountUserId && canShipHome) {
      const r2 = await this.createQuery(AccountUser)
        .where({ id: accountUserId })
        .expand(['shippingAccountAddress'])
        .execute();
      const homeAddress = r2[0].shippingAccountAddress;
      if (homeAddress) {
        addresses.push(homeAddress);
      }
    }
    return addresses;
  }

  async getBillingAccountAddresses(accountId: string) {
    return this.uow
      .createQuery(AccountAddress)
      .expand(['account'])
      .where({
        accountId: accountId,
        isBillingAddress: true,
        isPersonalAddress: false,
      })
      .execute();
  }

  // async getAccountAddresses(accountId: string, isBilling: boolean, isShipping: boolean, isPersonal: boolean ) {
  //   const r = await this.uow.createQuery(AccountAddress)
  //     .where({
  //       accountId: accountId,
  //       isBillingAddress: isBilling,
  //       isShippingAddress: isShipping,
  //       isPersonalAddress: isPersonal
  //      })
  //     .execute();
  //   return r;
  // }

  async getAccountAddress(accountAddressId: string) {
    const r = await this.uow
      .createQuery(AccountAddress)
      .expand(['account'])
      .where({ id: accountAddressId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getProgramProductCategoryTags(programId: string) {
    return this.uow
      .createQuery(ProgramProductCategoryTag)
      .where({ programId: programId })
      .expand(['programProductCategoryTagMaps.programProductCategoryTag'])
      .execute();
  }

  async getProgramProductTypeTagsForAccount(accountId: string) {
    return this.uow
      .createQuery(ProgramProductTypeTag)
      .where({
        accountId: accountId,
      })
      .expand([
        'programProductTypeTagMaps.pricedProductType',
        'programProductCategoryTagMaps.programProductCategoryTag',
      ])
      .execute();
  }

  async getBudgetProductTypeTags(accountId: string) {
    return this.uow
      .createQuery(BudgetProductTypeTag)
      .where({
        accountId: accountId,
      })
      .expand(['budgetProductTypeTagMaps'])
      .execute();
  }

  async getBudgetProductTypeTagMapsForTag(budgetProductTypeTagId: string) {
    return this.uow
      .createQuery(BudgetProductTypeTagMap)
      .where({ budgetProductTypeTagId: budgetProductTypeTagId })
      .expand([
        'pricedProductType.productType.manufacturer',
        'pricedProductType.productType.supplier',
      ])
      .execute();
  }

  async getProgramProductTypeTagMapsForTag(programProductTypeTagId: string) {
    return this.uow
      .createQuery(ProgramProductTypeTagMap)
      .where({ programProductTypeTagId: programProductTypeTagId })
      .expand([
        'pricedProductType.productType.manufacturer',
        'pricedProductType.productType.supplier',
      ])
      .execute();
  }

  async getPricedProductTypesForTag(programProductTypeTagId: string) {
    return this.uow
      .createQuery(PricedProductType)
      .where({
        programProductTypeTagMaps: {
          any: { programProductTypeTagId: programProductTypeTagId },
        },
      })
      .expand(['productType.manufacturer', 'productType.supplier'])
      .execute();
  }

  createPricedProductTypesQuery(
    accountId: string,
    programProductTypeTagId: string
  ) {
    return this.uow
      .createQuery(PricedProductType)
      .where({
        accountId: accountId,
        not: {
          programProductTypeTagMaps: {
            any: { programProductTypeTagId: programProductTypeTagId },
          },
        },
      })
      .expand([
        'programProductTypeTagMaps',
        'productType.manufacturer',
        'productType.supplier',
      ]);
  }

  createPricedProductTypesForProgramQuery(programId: string) {
    return this.uow
      .createQuery(PricedProductType)
      .where({
        productTypeConfigs: {
          any: { programProductTypeConfigs: { any: { programId: programId } } },
        },
      })
      .expand(['productType.manufacturer', 'productType.supplier']);
  }

  createPricedProductTypesWithConfigByAccountQuery(accountId: string) {
    const q = this.uow
      .createQuery<PricedProductType>(
        null,
        'PricedProductTypesWithConfigByAccount'
      )
      .withParameters({ accountId });
    return q;
  }

  createProgramsQuery(accountId: string) {
    return this.uow.createQuery(Program).where({ accountId: accountId });
  }

  async getPrograms(accountId: string) {
    return this.uow
      .createQuery(Program)
      .where({ accountId: accountId })
      .execute();
  }

  getAccountImages(...accountImageIds: string[]) {
    const q = this.uow
      .createQuery(AccountImage)
      .where({ id: { in: accountImageIds } });
    return q.execute();
  }

  async getProgramById(programId: string) {
    const r = await this.uow
      .createQuery<Program>(null, 'ProgramById') 
      .withParameters({ programId })
      .executeCached();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getProgramByIdFull(programId: string) {
    const r = await this.uow
      .createQuery<Program>(null, 'ProgramByIdFull')
      .withParameters({ programId })
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  /** Get program with accountBlanketPurchaseOrder */
  async getProgramWithPo(programId: string) {
    const r = await this.uow
      .createQuery(Program)
      .where({ id: programId })
      .expand('accountBlanketPurchaseOrder.accountBlanketPurchaseOrderLogs')
      .executeCached();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  /** Get ProgramUserGroups with accountBlanketPurchaseOrder for the given user */
  async getProgramUserGroupsWithPo(accountUserId: string) {
    const r = await this.uow
      .createQuery(ProgramUserGroupMap)
      .where({ accountUserId: accountUserId })
      .expand([
        'programUserGroup.accountBlanketPurchaseOrder.accountBlanketPurchaseOrderLogs',
      ])
      .executeCached();

    return r.map((x) => x.programUserGroup);
  }

  /** Get ShippingUserGroups with accountBlanketPurchaseOrder for the given user */
  async getShippingUserGroupsWithPo(accountUserId: string) {
    const r = await this.uow
      .createQuery(ShippingUserGroupMap)
      .where({ accountUserId: accountUserId })
      .expand([
        'shippingUserGroup.accountBlanketPurchaseOrder.accountBlanketPurchaseOrderLogs',
      ])
      .executeCached();

    return r.map((x) => x.shippingUserGroup);
  }


  async getProgramsForAccountUser(accountUserId: string) {
    const userPugs = await this.getProgramUserGroupsForUser(accountUserId);
    const r = await this.getProgramsForProgramUserGroups(
      userPugs.map((x) => x.id)
    );
    const progs = r.filter((prog) => {
      // all pugs
      const progPugs = prog.approvalTree.approvalTreeUserGroups.map(
        (x) => x.programUserGroup
      );
      // restrict pugs to just those for the current user.
      const pugs = progPugs.filter((x) => userPugs.find((y) => y.id == x.id));
      //include prog is pugs.length > 0
      const allowedPugs = pugs.filter((pug) => {
        const exclusions = pug.programUserGroupExclusions.filter(
          (x) => x.programId == prog.id && x.accountUserId == accountUserId
        );
        return exclusions.length == 0;
      });
      return allowedPugs.length > 0;
    });
    return progs;
  }

  async getProgramUserGroupsForUser(accountUserId: string) {
    const r = await this.uow
      .createQuery(ProgramUserGroupMap)
      .where({ accountUserId: accountUserId })
      .expand([
        'programUserGroup',
        'programUserGroup.programUserGroupExclusions',
      ])
      .executeCached();

    return r.map((x) => x.programUserGroup);
  }

  async getProgramsForProgramUserGroups(programUserGroupIds: string[]) {
    return (
      this.uow
        .createQuery(Program)
        .where({
          'approvalTree.approvalTreeUserGroups': {
            any: { programUserGroupId: { in: programUserGroupIds } },
          },
        })
        .expand([
          'approvalTree.approvalTreeUserGroups.programUserGroup.programUserGroupMaps.accountUser',
          'programAllowances.programAllowanceUserGroupMaps',
        ])
        .executeCached()  // TODO: reveiw why this doesn't work - some issue with 'any' in the query.
        // .execute()
    );
  }

  async getApprovalTreeAdminGroupForApprovalTreeAndUser(
    approvalTreeId: string,
    accountUserId: string
  ) {
    const r = await this.uow
      .createQuery<ApprovalTreeAdminGroup>(
        null,
        'ApprovalTreeAdminGroupForApprovalTreeAndUser'
      )
      .withParameters({ approvalTreeId, accountUserId })
      .execute();
    UtilFns.assertArrayOZeroOrOne(r);
    return _.first(r);
  }

  // Not currently used ( or tested )
  // async getAccountAdminIdsForApprovalTreeAndUser(
  //   approvalTreeId: string,
  //   accountUserId: string
  // ) {
  //   const r = await this.uow
  //     .createQuery(ApprovalTreeUserGroup)
  //     .where({
  //       approvalTreeId: approvalTreeId,
  //       'programUserGroup.programUserGroupMaps': {
  //         any: { accountUserid: accountUserId },
  //       },
  //     })
  //     .expand([
  //       'approvalTreeAdminGroup.accountAdminGroup.accountAdminGroupMaps',
  //     ])
  //     .execute();
  //   const adminGroupMaps = _.flatMap(
  //     r,
  //     (x) => x.approvalTreeAdminGroup.accountAdminGroup.accountAdminGroupMaps
  //   );
  //   return adminGroupMaps.map((x) => x.accountAdminId);
  // }

  /** Non-thumbnail images only */
  async getProductTypeImages(...productTypeIds: string[]) {
    // ensure unique list
    productTypeIds = [...new Set(productTypeIds)];
    // split into smaller chunks so query string doesn't get too long
    const chunks = _.chunk(productTypeIds, 30);
    // perform queries
    const promises = chunks.map((ids) =>
      this.uow
        .createQuery(ProductTypeImage)
        .where({
          productTypeId: { in: ids },
          'supplierImage.isThumbnail': false,
        })
        .orderBy('isPrimary', true)
        .expand('supplierImage')
        .execute()
    );
    const resultChunks = await Promise.all(promises);
    const results = _.flatten(resultChunks);
    return results;
  }

  /** Non-thumbnail images only */
  async getAddonImages(...addonIds: string[]) {
    return this.uow
      .createQuery(AddonImage)
      .where({
        addonId: { in: addonIds },
        'supplierImage.isThumbnail': false,
      })
      .orderBy('isPrimary', true)
      .expand('supplierImage')
      .execute();
  }

  // TODO - implement on server
  // async getPrimaryImage(productTypeId: string) {
  //   const images = await this.getProductTypeImages(productTypeId);
  //   if (images.length == 0) return null;
  //   const image = images.find(x => x.isPrimary) ?? images[0];
  //   return image;
  // }

  // No longer used
  // async getProgramByAccountUserSummaries(accountUserId: string) {
  //   const q = this.uow.createQuery(null, 'GetProgramSummariesByUser')
  //     .withParameters({ accountUserId });
  //     return q.execute();
  // }

  // Note: Now called directly on the server - same query but with AsSplitQuery.
  // async getProgramProductTypeConfigs(programId: string) {
  //   const r = await this.uow.createQuery(ProgramProductTypeConfig)
  //     .where({ programId })
  //     .expand([
  //       'productTypeConfig.productTypeConfigProducts.product',
  //       'productTypeConfig.pricedProductType.pricedProductTypeFeatureChoices',
  //       'productTypeConfig.pricedProductType.productType.supplier',
  //       'productTypeConfig.pricedProductType.productType.manufacturer',
  //       'productTypeConfig.pricedProductType.productType.productTypeFeatures.feature.featureChoices',
  //       'productTypeConfig.pricedProductType.programProductTypeTagMaps.programProductTypeTag'
  //     ])
  //     .execute();
  //   return r;
  // }

  async getProgramProductTypeConfigsByProgram(programId: string) {
    // NOTE - feature choices and add-ons were remove from server-side query.
    // They are retrieved on demand using the getProgramProductTypeConfig method below.
    const q = this.uow
      .createQuery<ProgramProductTypeConfig>(
        null,
        'ProgramProductTypeConfigsByProgram'
      )
      .withParameters({ programId });
    return q.execute();
  }

  /** Retrive ProductTypeConfig with feature choices and addons */
  async getProductTypeConfig(productTypeConfigId: string) {
    const q = this.uow.createQuery(ProductTypeConfig)
      .where({ id: productTypeConfigId })
      .expand([
        'productTypeConfigProducts.product.productFeatureChoices',
        'productTypeConfigAddons.pricedAddon.addon',
        'pricedProductType.pricedProductTypeFeatureChoices',
        'pricedProductType.productType.productTypeFeatures.feature.featureChoices',
      ]);
    return q.execute();
  }

  async getProgramIssuanceUserLogsByProgramAndUser(
    programId: string,
    accountUserId: string
  ) {
    const q = this.uow
      .createQuery<ProgramIssuanceUserLog>(
        null,
        'ProgramIssuanceUserLogsByProgramAndUser'
      )
      .withParameters({ programId, accountUserId });
    return q.executeCached();
  }

  async getProgramIssuanceUserLogsByProgramIssuanceId(programIssuanceId: string) {
    const q = this.uow
      .createQuery(ProgramIssuanceUserLog)
      .where({
        programIssuanceId
      });
    const r = q.execute();
    return r
  }

  async getAccountIssuanceUserLogsByAccountAndUser(
    accountId: string,
    accountUserId: string
  ) {
    const q = this.uow
      .createQuery<AccountIssuanceUserLog>(
        null,
        'AccountIssuanceUserLogsByAccountAndUser'
      )
      .withParameters({ accountId, accountUserId });
    return q.executeCached();
  }

  async getAccountIssuanceUserLogsByAccountIssuanceId(accountIssuanceId: string) {
    const q = this.uow
      .createQuery(AccountIssuanceUserLog)
      .where({
        accountIssuanceId
      });
    return q.execute();
  }

  async getProgramAllowanceUserLogsByProgramAndUser(
    programId: string,
    accountUserId: string
  ) {
    const q = this.uow
      .createQuery<ProgramAllowanceUserLog>(
        null,
        'ProgramAllowanceUserLogsByProgramAndUser'
      )
      .withParameters({ programId, accountUserId });
    return q.executeCached();
  }

  async getProductTypeConfigById(productTypeConfigId: string) {
    const r = await this.uow
      .createQuery(ProductTypeConfig)
      .where({ id: productTypeConfigId })
      .expand([
        'productTypeConfigProducts.product.productFeatureChoices',
        'productTypeConfigAddons.pricedAddon.addon',
        'pricedProductType.pricedProductTypeFeatureChoices',
        'pricedProductType.productType.supplier',
        'pricedProductType.productType.manufacturer',
        'pricedProductType.productType.productTypeFeatures.feature.featureChoices',
        'returnPolicy',
        'returnPolicy.returnCreditType',
      ])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getAccountIssuances(accountId: string) {
    return this.uow
      .createQuery(AccountIssuance)
      .where({ accountId })
      .expand([
        'accountIssuanceUserGroupMaps',
        'programProductTypeTag',
        'programAccountIssuanceMaps.program',
      ])
      .execute();
  }

  async getProgramIssuances(programId: string) {
    return this.uow
      .createQuery(ProgramIssuance)
      .where({ programId })
      .expand(['programIssuanceUserGroupMaps', 'programProductTypeTag'])
      .execute();
  }

  async getProgramAllowances(programId: string) {
    return this.uow
      .createQuery(ProgramAllowance)
      .where({ programId })
      .expand([
        'programAllowanceUserGroupMaps',
        'programAllowanceAddonExceptions',
        'programAllowanceFeatureExceptions',
      ])
      .execute();
  }

  async getProgramViews(programId: string) {
    return this.uow
      .createQuery(ProgramView)
      .where({ programId })
      .expand(['programViewProductTypeConfigs.productTypeConfig'])
      .execute();
  }

  async getRapidOrders(programId: string) {
    return this.uow
      .createQuery(ProgramRapidTemplate)
      .where({ programId })
      .expand(['programRapidTemplateProductTypeConfigs.productTypeConfig'])
      .execute();
  }

  async getAccountBudgets(accountId: string) {
    return this.uow
      .createQuery(AccountBudget)
      .where({ accountId })
      .expand(['budgetProductTypeTag'])
      .execute();
  }

  async getProgramUserGroupBudgets(programUserGroupId: string) {
    return this.uow
      .createQuery(ProgramUserGroupBudget)
      .where({ programUserGroupId: programUserGroupId })
      .expand(['budgetProductTypeTag'])
      .execute();
  }

  async getProgramBudgets(programId: string) {
    return this.uow
      .createQuery(ProgramBudget)
      .where({ programId })
      .expand(['budgetProductTypeTag'])
      .execute();
  }

  async isProgramProductTypeTagUsed(programProductTypeTagId: string) {
    const r = await this.uow
      .createQuery(ProgramIssuance)
      .where({ programProductTypeTagId: programProductTypeTagId })
      .take(1)
      .execute();
    return r.length > 1;
  }

  async isBudgetProductTypeTagUsed(budgetProductTypeTagId: string) {
    const p1 = this.uow
      .createQuery(ProgramBudget)
      .where({ budgetProductTypeTagId: budgetProductTypeTagId })
      .take(1)
      .execute();
    // const r = await p1;
    const p2 = this.uow
      .createQuery(ProgramUserGroupBudget)
      .where({ budgetProductTypeTagId: budgetProductTypeTagId })
      .take(1)
      .execute();
    const p3 = this.uow
      .createQuery(AccountBudget)
      .where({ budgetProductTypeTagId: budgetProductTypeTagId })
      .take(1)
      .execute();
    const results = await Promise.all([p1, p2, p3]);
    return results.some((r) => r.length > 0);
  }

  async getJobOrderById(jobOrderId: string) {
    const r = await this.uow
      .createQuery(JobOrder)
      .where({ id: jobOrderId })
      .expand([
        'account',
        'accountUser',
        // 'program.programAllowances.programAllowanceUserGroupMaps.programUserGroup',
        'accountUser.proximityUser',
        'jobOrderDetails',
        'jobOrderDetails.jobOrderDetailAddons',
        'jobOrderDetails.productTypeConfigProduct.productTypeConfig.pricedProductType.productType.manufacturer',
        'jobOrderDetails.productTypeConfigProduct.productTypeConfig.pricedProductType.pricedProductTypeFeatureChoices',
        'jobOrderDetails.productTypeConfigProduct.productTypeConfig.pricedProductType.programProductTypeTagMaps.programProductTypeTag',
        'jobOrderDetails.productTypeConfigProduct.productTypeConfig.productTypeConfigAddons.pricedAddon',
        'jobOrderDetails.productTypeConfigProduct.product.productFeatureChoices.featureChoice.feature',
      ])
      .executeCached();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getJobOrderHistById(jobOrderId: string) {
    const r = await this.uow
      .createQuery(JobOrder)
      .where({ id: jobOrderId })
      .expand([
        'jobOrderHistDetails',
        'jobOrderHistDetails.jobOrderHistDetailFeatures.featureChoice.feature',
        'jobOrderHistDetails.jobOrderHistDetailAddons.addon',
        'jobOrderHistDetails.product.productType.manufacturer',
        'jobOrderHistDetails.product.productType.manufacturer.supplier',
        'jobOrderHistDetails.product.productFeatureChoices.featureChoice.feature',
        'program',
        'account',
        'shippingAccountAddress',
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser',
        'jobOrderOveragePayments'
      ])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  createJobOrdersQuery() {
    const r = this.uow
      .createQuery(JobOrder)
      .expand([
        'program',
        'account',
        'jobOrderStatus',
        'shippingAccountAddress',
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser'
      ])
    return r;
  }

  createJobOrdersByStatusQuery(joStatusId: number) {
    const r = this.uow
      .createQuery(JobOrder)
      .where({ jobOrderStatusId: joStatusId })
      .expand([
        'program',
        'account',
        'jobOrderStatus',
        'shippingAccountAddress',
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser'
      ])
    return r;
  }

  async getJobOrderHistExtById(jobOrderId: string) {
    const r = await this.uow
      .createQuery(JobOrder)
      .where({ id: jobOrderId })
      .expand([
        'jobOrderHistDetails.jobOrderHistDetailFeatures.featureChoice.feature',
        'jobOrderHistDetails.jobOrderHistDetailAddons.addon',
        'jobOrderHistDetails.product.productType.manufacturer.supplier',
        'jobOrderHistDetails.product.productFeatureChoices.featureChoice.feature',
        'program',
        'account',
        'shippingAccountAddress', 
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser',
        'returnRequests',
        'invoices.invoiceDetails.returnRequestDetails.returnReason',
        'invoices.invoiceDetails.jobOrderHistDetail.returnPolicy',
        'invoices.invoiceDetails.jobOrderHistDetail.jobOrderHistDetailFeatures.featureChoice.feature',
        'invoices.invoiceDetails.jobOrderHistDetail.jobOrderHistDetailAddons.addon',
        'invoices.invoiceDetails.jobOrderHistDetail.product.productType.manufacturer.supplier',
        'invoices.invoiceDetails.jobOrderHistDetail.product.productFeatureChoices.featureChoice.feature'
      ])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getReturnRequest(returnRequestId: string) {
    const r = await this.uow
      .createQuery(ReturnRequest)
      .where({
        id: returnRequestId,
      })
      .expand([
        'returnRequestDetails'
      ])
      .execute();
    return _.first(r);
  }

  async getReturnRequestDetailsForJobOrder(jobOrderId: string) {
    const r = await this.uow
      .createQuery(ReturnRequestDetail)
      .where({
        'returnRequest.jobOrderId': jobOrderId,
      })
      .expand([
        'returnRequest'
      ])
      .execute();
    return r;
  }


  async getOpenReturnRequestsForJobOrder(jobOrderId: string) {
    const r = await this.uow
      .createQuery(ReturnRequest)
      .where({
        jobOrderId: jobOrderId,
        returnRequestStatusId: ReturnRequestStatusEnum.Setup
      })
      .execute();
    return _.first(r);
  }

  async getInvoicesByJobOrder(jobOrderId: string) {
    const r = await this.uow
      .createQuery(JobOrder)
      .where({ id: jobOrderId })
      .expand([
        'program',
        'shippingAccountAddress',
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser',
        'jobOrderOveragePayments',
        'invoices',
        'invoices.invoiceDetails',
        'invoices.invoiceDetails.jobOrderHistDetail',
        'invoices.invoiceDetails.jobOrderHistDetail.jobOrderHistDetailFeatures.featureChoice.feature',
        'invoices.invoiceDetails.jobOrderHistDetail.jobOrderHistDetailAddons.addon',
        'invoices.invoiceDetails.jobOrderHistDetail.product.productType.manufacturer',
        'invoices.invoiceDetails.jobOrderHistDetail.product.productType.manufacturer.supplier',
        'invoices.invoiceDetails.jobOrderHistDetail.product.productFeatureChoices.featureChoice.feature',
        'program',
        'account',
        'shippingAccountAddress',
        'accountUser.proximityUser',
        'approvedByAccountAdmin.proximityUser',
        'jobOrderOveragePayments',
      ])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getReturnReasons(supplierId: string) {
    const r = await this.uow
      .createQuery(ReturnReason)
      .where({ supplierId: supplierId })
      .orderBy('name')
      .execute();
    return r;
  }

  async getJobOrderHistSummaries(whereObject: any) {
    const r = await this.uow
      .createQuery(JobOrder, 'JoHistSummaries')
      .where(whereObject)
      .orderBy('displayId')
      .execute();
    return r.map((x) => mapObject(x) as unknown as JoHistSummary);
  }

  async getJobOrderHistTotalsBySupplier(whereObject: any) {
    const r = await this.uow
      .createQuery(JobOrderHistDetail, 'JoHistTotalsBySupplier')
      .where(whereObject)
      .execute();
    return r[0]['totals'].map((x) => mapObject(x)) as JoHistTotalsBySupplier[];
  }

  async getSupplierSubmissions(afterDate: Date) {
    const r = await this.uow
      .createQuery(SupplierSubmission)
      .where({ submissionTs: { gt: afterDate } })
      .expand('supplier')
      .execute();

    return r;
  }

  async getSuppliers() {
    const r = await this.uow.createQuery(Supplier).execute();
    return r;
  }

  // Removed - no longer valid
  // getSuppliersWithSubmisions(accountId: string) {
  //   return this.uow.createQuery(Supplier, 'Suppliers')
  //     .where({ jobOrderSubmissions: { any: { 'jobOrder.accountId': accountId } }})
  //     .execute();
  // }

  async getJobOrdersByAccountAdminId(accountAdminId: string) {
    return this.uow
      .createQuery(JobOrder)
      .where({
        'program.accountAdminGroup.accountAdminGroupMaps': {
          any: { accountAdminId: accountAdminId },
        },

      })
      .expand([
        'accountUser.proximityUser',
        'program',
        'accountAdminGroup.accountAdminGroupMaps',
      ])
      .execute();
  }

  async getJobOrdersByUser(accountUserId: string) {
    return this.uow
      .createQuery(JobOrder)
      .where({
        accountUserId: accountUserId,
      })
      .expand(['program', 'jobOrderDetails', 'approvedByAccountAdmin'])
      .execute();
  }

  async getJobOrdersPlacedByUser(accountUserId: string) {
    return this.uow
      .createQuery(JobOrder)
      .where({
        accountUserId: accountUserId,
        jobOrderStatusId: JobOrderStatusEnum.Placed, // TODO: may need to include closed JobOrders
      })
      .expand([
        'program',
        'jobOrderHistDetails.product.productType.manufacturer',
      ])
      .execute();
  }

  async getJobOrderBudgetLogsForAccount(
    accountId: string,
    fiscalStartDate: Date
  ) {
    const q = this.uow
      .createQuery<JobOrderBudgetLog>(null, 'JobOrderBudgetLogsForAccount')
      .withParameters({ accountId, fiscalStartDate });
    return q.execute();
  }

  async getJobOrderBudgetLogsForProgramUserGroup(
    programUserGroupId: string,
    fiscalStartDate: Date
  ) {
    const q = this.uow
      .createQuery<JobOrderBudgetLog>(
        null,
        'JobOrderBudgetLogsForProgramUserGroup'
      )
      .withParameters({ programUserGroupId, fiscalStartDate });
    return q.execute();
  }

  async getJobOrderBudgetLogsForProgram(
    programId: string,
    fiscalStartDate: Date
  ) {
    const q = this.uow
      .createQuery<JobOrderBudgetLog>(null, 'JobOrderBudgetLogsForProgram')
      .withParameters({ programId, fiscalStartDate });
    return q.execute();
  }

  async getJobOrderDetailById(jobOrderDetailId: string) {
    const r = await this.uow
      .createQuery(JobOrderDetail)
      .where({ id: jobOrderDetailId })
      .expand(['jobOrder', 'jobOrderDetailAddons'])
      .execute();
    UtilFns.assertArrayOfOne(r);
    return r[0];
  }

  async getJobOrderOveragePayments(jobOrderId: string) {
    return this.uow
      .createQuery(JobOrderOveragePayment)
      .where({ jobOrderId: jobOrderId })
      .executeCached();
  }

  async getJobOrderNotes(jobOrderId: string) {
    return this.uow
      .createQuery(JobOrderNote)
      .where({ jobOrderId: jobOrderId })
      .orderBy('noteTs', true)
      .expand(['proximityUser'])
      .execute();
  }

  createProductTypeConfigsQuery(
    accountId: string,
    supplierId: string | null,
    shouldIncludePricing: boolean
  ) {
    return SharedQueries.createProductTypeConfigsQuery(
      this.uow,
      accountId,
      supplierId,
      shouldIncludePricing
    );
  }
  /* 
    async getJobOrderHistDetailsByJobOrderId(jobOrderId: string) {
      return this.uow
        .createQuery(JobOrderHistDetail)
        .where({ jobOrderId: jobOrderId })
        .orderBy('product.description')
        .expand([
          'product.productType.manufacturer',
          'product.productType.manufacturer.supplier',
          'jobOrderHistDetailFeatures.featureChoice',
          'product',
        ])
        .execute();
    } */

  async getProximityRights() {
    return this.uow.getAllOrQuery(ProximityRight);
  }

  async getProximityUserRights(userId: string) {
    return this.uow
      .createQuery(ProximityUserRight)
      .where({ proximityUserId: userId })
      .execute();
  }

  getAddons(supplierId: string) {
    const r = this.uow
      .createQuery(Addon)
      .where({ supplierId })
      .orderBy('nameAndLocation')
      .execute();
    return r;
  }

  async getNarrativeUserGroupDtos(programId: string, treeId: string) {
    const q = this.uow
      .createQuery<NarrativeUserGroupDto>(null, 'GetNarrativeUserGroupDtos')
      .withParameters({ programId, treeId });
    return q.execute();
  }

  async getNotificationEventAccountMaps(accountId: string) {
    return this.uow.createQuery(NotificationEventAccountMap)
      .where({ accountId }).execute();
  }

  /** gets all of the notificationTemplates for a given account along with all of the 
   * 'global templates */
  async getNotificationTemplates(accountId: string) {
    const q = this.uow.createQuery(NotificationTemplate)
      .where({
        or: [
          { accountId: accountId },
          { accountId: null }
        ]
      });
    return q.execute();
  }

  async getInAppNotifications(proximityUserId: string, newOnly: boolean = false) {
    const where = { proximityUserId: proximityUserId };
    const whereNew = newOnly ? { wasRead: false } : {}
    const r = this.uow.createQuery(InAppNotification)
      .where({ ...where, ...whereNew })
      .expand([
        'notificationTemplate.notificationEvent'
      ])
      .execute();

    return r;
  }

  async getInAppNotificationsForMany(proximityUserIds: string[]) {

    const r = this.uow.createQuery(InAppNotification)
      .where({
        proximityUserId: { in: proximityUserIds }
      })
      .expand([
        'notificationTemplate.notificationEvent',
        'proximityUser'
      ])
      .execute();

    return r;
  }

  // all but inApp
  // async getNotificationSubmissionsForUnsent(proximityUserId: string ) {
  //   const r = this.uow.createQuery(NotificationSubmission)
  //     .where({ 
  //       proximityUserId: proximityUserId,
  //       submissionTs: null
  //     })
  //     .expand([
  //       'notificationTemplate.notificationEvent'
  //     ])
  //   .execute();
  //   return r;
  // }

  async getSupplierReturnRequestDetailStatuses(returnRequestId: string) {
    const r = await this.uow
      .createQuery(null, 'GetSupplierReturnRequestDetailStatuses')
      .withParameters({ returnRequestId })
      .execute();
    return r;
  }


}
